import { useCallback, useContext } from "react";
import { EstadoVenda, PartialEntity, tVenda } from "../../interfaces";
import { OffcanvasNovoDado } from "./OffcanvasNovoDado";
import { OffcanvasEditarDado } from "./OffcanvasEditarDado.tsx";
import { OffcanvasDeletarDado } from "./OffcanvasDeletarDado.tsx";
import FormVenda, { tNovaVenda } from "../forms/FormVenda";
import useVenda from "../../hooks/useVenda";
import utils from "../../utils";
import { ContextAlerta } from "../../contexts/ContextAlert";
import { ContextUser } from "../../contexts/ContextUser";

export type IOffcanvasVendaProps = {
    mostrarForm: boolean;
    onTerminou?: () => void;
    onCancel?: () => void;
} & (
    | { tipo: "criar"; dados?: tVenda }
    | { tipo: "editar"; dados: null | tVenda }
    | { tipo: "deletar"; dados: null | tVenda }
);

const FORM = "form-venda";

export default function OffcanvasVenda(props: IOffcanvasVendaProps) {
    //CONTEXTOS
    const { setConfirmar } = useContext(ContextAlerta);
    const { usuario } = useContext(ContextUser);

    //HOOKS
    const { criar, editar, deletar, estaCriando, estaEditando, estaDeletando, cancelar } = useVenda();

    //VARAIVEIS
    const { onTerminou, onCancel, dados, tipo } = props;

    const confirmaInfoVenda = useCallback(
        async (infoVenda: tNovaVenda, params: { multiplasVendas: boolean }): Promise<boolean> => {
            try {
                if (infoVenda.estadoVenda === "VERIFICADA" && dados?.estadoVenda !== "VERIFICADA") {
                    const confirmou = await setConfirmar(
                        "A venda será marcada como verificada e somente poderá ser editada por administradores."
                    );
                    if (!confirmou) return false;
                }

                if (params.multiplasVendas) {
                    const confirmou = await setConfirmar(
                        <>
                            <p>Você optou por criar múltiplas vendas. Exemplo:</p>
                            <p className="mb-1">
                                Ao inserir uma venda de <strong>R$ 3000</strong> com <strong>duas</strong> datas boleto{" "}
                                <strong>12/11/2024</strong> e<strong>12/12/2024</strong>, serão criadas duas vendas como
                                a seguir:
                            </p>
                            <ol>
                                <li>
                                    Venda de <strong>R$3000</strong> na data <strong>12/11/2024</strong>
                                </li>
                                <li>
                                    Venda de <strong>R$3000</strong> na data <strong>12/12/2024</strong>
                                </li>
                            </ol>
                        </>
                    );
                    if (!confirmou) return false;
                }

                return true;
            } catch (err) {
                return false;
            }
        },
        [setConfirmar, dados]
    );

    //EVENTS
    const handleOnCancel = useCallback(() => {
        cancelar();
        if (onCancel) onCancel();
    }, [onCancel, cancelar]);

    const handleOnCriar = useCallback(
        async (venda: tNovaVenda, params: { multiplasVendas: boolean }) => {
            try {
                const confirmou = await confirmaInfoVenda(venda, params);
                if (!confirmou) throw new Error("Nada foi realizado!");

                await criar([venda], params);
                if (onTerminou) onTerminou();
            } catch (err) {
                throw err;
            }
        },
        [criar, onTerminou, confirmaInfoVenda]
    );

    const handleOnEditar = useCallback(
        async (venda: tNovaVenda & PartialEntity<tVenda, "id">, params: { multiplasVendas: boolean }) => {
            try {
                const confirmou = await confirmaInfoVenda(venda, params);
                if (!confirmou) throw new Error("Nada foi realizado!");

                await editar(venda);
                if (onTerminou) onTerminou();
            } catch (err) {
                throw err;
            }
        },
        [editar, onTerminou, confirmaInfoVenda]
    );

    const handleOnDeletar = useCallback(
        async (id: string) => {
            try {
                await deletar(id, { vendedorId: usuario?.vendedorUser?.id });
                if (onTerminou) onTerminou();
            } catch (err) {
                throw err;
            }
        },
        [deletar, onTerminou, usuario]
    );

    return props.tipo === "criar" ? (
        <OffcanvasNovoDado.Root show={props.mostrarForm}>
            <OffcanvasNovoDado.Header>
                <h4>Nova Venda</h4>
            </OffcanvasNovoDado.Header>
            <OffcanvasNovoDado.Body
                cancelButtonProps={{ onClick: handleOnCancel }}
                acceptButtonProps={{ carregando: estaCriando, type: "submit", form: FORM }}
            >
                <FormVenda
                    options={{ aceitarVendasMultiplas: true }}
                    formId={FORM}
                    valoresIniciais={
                        props.dados
                            ? utils.retornaNovaVendaDeVenda(props.dados)
                            : {
                                  vendedoresId: [],
                                  contratoId: null,
                                  formaParcelamento: 1,
                                  vendasProduto: [],
                                  vendasServico: [],
                                  clienteId: "",
                                  estadoVenda: EstadoVenda.CRIADA,
                                  dataFaturamento: utils.retornaDataAtual({ segundos: 0, minutos: 0, hora: 0 }),
                                  dataVenda: utils.retornaDataAtual({ segundos: 0, minutos: 0, hora: 0 }),
                                  codigoNota: null,
                                  datasBoleto: [utils.retornaDataAtual({})],
                                  formaPagamentoId: "",
                                  numOportunidadeCRM: "",
                                  numPedidoDistribuidor: "",
                                  comissaoPrimeiraParcela: false,
                                  vendaRenovacao: false,
                              }
                    }
                    onSubmit={handleOnCriar}
                />
            </OffcanvasNovoDado.Body>
        </OffcanvasNovoDado.Root>
    ) : tipo === "editar" ? (
        <OffcanvasEditarDado.Root show={props.mostrarForm}>
            <OffcanvasEditarDado.Header>
                <h4>Editar Venda</h4>
            </OffcanvasEditarDado.Header>
            <OffcanvasEditarDado.Body
                cancelButtonProps={{ onClick: handleOnCancel }}
                acceptButtonProps={{ form: FORM, carregando: estaEditando, type: "submit" }}
            >
                {dados && (
                    <FormVenda
                        formId={FORM}
                        valoresIniciais={utils.retornaNovaVendaDeVenda(dados)}
                        onSubmit={(venda, params) => handleOnEditar({ id: dados.id, ...venda }, params)}
                    />
                )}
            </OffcanvasEditarDado.Body>
        </OffcanvasEditarDado.Root>
    ) : tipo === "deletar" ? (
        <OffcanvasDeletarDado.Root show={props.mostrarForm}>
            <OffcanvasDeletarDado.Header>
                <h4>Deletar Venda</h4>
            </OffcanvasDeletarDado.Header>
            {dados && (
                <OffcanvasDeletarDado.Body
                    acceptButtonProps={{ onClick: () => handleOnDeletar(dados.id), carregando: estaDeletando }}
                    cancelButtonProps={{ onClick: handleOnCancel }}
                >
                    <p>
                        Você está prestes a deletar permanentemente do sistema a venda com o cliente{" "}
                        <strong>{dados.cliente.name}</strong> e estado{" "}
                        <strong>{utils.returnTextoEstadoVenda(dados.estadoVenda)}</strong>. Clique no botão{" "}
                        <strong className="text-danger">Deletar</strong> logo abaixo para prosseguir.
                    </p>
                </OffcanvasDeletarDado.Body>
            )}
        </OffcanvasDeletarDado.Root>
    ) : undefined;
}
