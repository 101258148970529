import { Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Formik, FormikHelpers } from "formik";
import { useCallback, useContext } from "react";
import * as yup from "yup";
import utils from "../../utils";
import { ContextAlerta } from "../../contexts/ContextAlert";
import { PartialEntity, tServico, tTipoFaturamento, tVendaServico } from "../../interfaces";
import SelectServico, { ISelectServicoProps } from "../selects/SelectServico";
import FormGroupInput, { FormGroupInputType } from "../formGroups/FormGroupInput";
import FormGroupGeneric from "../formGroups/FormGroupGeneric";
import FormGroupSelect from "../formGroups/FormGroupSelect";

export const INFO_MARKUP_LIQUIDO =
    "Valor de comissão listado no site do distribuidor referente à comissão a ser paga para a revenda.";

//TYPES
export type tNovaVendaServico = PartialEntity<
    tVendaServico,
    | "servicoId"
    | "cotacao"
    | "markup"
    | "tipoFaturamento"
    | "dedutiveisDistribuidor"
    | "impostoNota"
    | "qntdHorasComercial"
    | "qntdHorasEspecial"
    | "qntdHorasNaoComercial"
>;

export interface IFormVendaServicoProps extends ISelectServicoProps {
    servicos: tServico[];
    formId?: string;
    onSubmit: (vendaServico: tNovaVendaServico) => void | Promise<void>;
    valoresIniciais: tNovaVendaServico;
}

//FORM
export default function FormVendaServico({ formId, onSubmit, valoresIniciais, servicos }: IFormVendaServicoProps) {
    //CONTEXTOS
    const { setPerigo, setAviso } = useContext(ContextAlerta);

    //EVENTOS
    const onSubmitFormik = useCallback(
        async (vendaServico: tNovaVendaServico, helpers: FormikHelpers<tNovaVendaServico>) => {
            try {
                await onSubmit(vendaServico);
                helpers.setSubmitting(false);
                helpers.resetForm();
            } catch (err) {
                if (utils.blErroCancelamento(err)) return;
                setPerigo(utils.retornaMensagemErro(err));
                console.error(err);
            }
        },
        [onSubmit, setPerigo]
    );

    return (
        <Formik
            validateOnChange={false}
            validationSchema={yupEsquemaVendaServico}
            onSubmit={onSubmitFormik}
            initialValues={valoresIniciais}
        >
            {({ handleSubmit, values, errors, setValues }) => {
                const servico = servicos.find(({ id }) => values.servicoId === id);

                const valorCustoHora = servico?.valorCustoHora ?? 0;
                const tipoMoeda = servico?.tipoMoeda ?? "BRL";

                const cotacaoDesabilitada = tipoMoeda === "BRL";
                const custoTotal =
                    values.qntdHorasComercial * valorCustoHora +
                    values.qntdHorasNaoComercial * valorCustoHora * 1.5 +
                    values.qntdHorasEspecial * valorCustoHora * 2;

                const valorMarkup = (values.markup * custoTotal) / 100;
                const valorVenda = custoTotal + valorMarkup;

                const valorMarkupLiquido = valorMarkup - (values.dedutiveisDistribuidor ?? 0);

                const valorNota =
                    values.tipoFaturamento === "DIRETO"
                        ? (values.impostoNota * valorMarkupLiquido) / 100
                        : (values.impostoNota * valorVenda) / 100;

                const valorImpostos = 0 + valorNota;
                const valorMargem = valorMarkupLiquido - valorImpostos;

                const atualizaMarkupPorValorVenda = (valor: number) => {
                    if (custoTotal === 0) return setAviso("O custo não pode ser 0.");
                    const novoValorMarkup = valor - custoTotal;
                    const novoMarkup = (novoValorMarkup * 100) / custoTotal;
                    setValues({ ...values, markup: novoMarkup });
                };
                const findPorcentMarkup = (valor: number) => {
                    if (custoTotal === 0) setAviso("O custo não pode ser 0.");
                    return custoTotal !== 0 ? (valor * 100) / custoTotal : 0;
                };
                const findPorcentImpNota = (valor: number) => {
                    switch (values.tipoFaturamento) {
                        case "DIRETO":
                            if (valorMarkupLiquido === 0) setAviso("O markup líquido não pode ser 0.");
                            return valorMarkupLiquido !== 0 ? (valor * 100) / valorMarkupLiquido : 0;
                        default:
                            if (valorVenda === 0 && values.tipoFaturamento === "REVENDA")
                                setAviso("O valor da venda não pode ser 0.");
                            return valorVenda !== 0 ? (valor * 100) / valorVenda : 0;
                    }
                };

                return (
                    <Form id={formId} onSubmit={handleSubmit}>
                        <Row>
                            <Col sm="12" className="p-0">
                                <Row className="p-2 pt-4 pb-4 border-bottom">
                                    <Col sm="12">
                                        <h6>Serviço e Faturamento</h6>
                                    </Col>
                                    <FormGroupGeneric label="Serviço" error={errors.servicoId}>
                                        <SelectServico
                                            idSelecionado={values.servicoId}
                                            setIdSelecionado={(id) => setValues({ ...values, servicoId: id as string })}
                                        />
                                    </FormGroupGeneric>
                                    <FormGroupSelect
                                        label="Tipo de faturamento"
                                        value={values.tipoFaturamento}
                                        setValue={(tipoFaturamento) => setValues({ ...values, tipoFaturamento })}
                                        options={utils.retornaArrayTipoFaturamento()}
                                        optionTextFn={utils.retornaTextoTipoFaturamento}
                                        error={errors.tipoFaturamento}
                                    />
                                </Row>
                            </Col>

                            <Col sm="12" className="p-0">
                                <Row className="p-2 bg-light pt-4 pb-4 border-bottom row-gap-2">
                                    <Col sm="12">
                                        <h6>
                                            Valores da venda (
                                            {utils.retornaTextoTipoFaturamento(values.tipoFaturamento)})
                                        </h6>
                                    </Col>

                                    <FormGroupInput
                                        sm="7"
                                        label="Custo hora"
                                        disabled
                                        type={FormGroupInputType.MONETARY}
                                        tipoMoeda={tipoMoeda}
                                        value={valorCustoHora}
                                        setValue={() => {}}
                                    />
                                    <FormGroupInput
                                        sm="5"
                                        label="Cotação"
                                        disabled={cotacaoDesabilitada}
                                        type={FormGroupInputType.MONETARY}
                                        tipoMoeda="BRL"
                                        value={values.cotacao}
                                        setValue={(cotacao) => setValues({ ...values, cotacao })}
                                        error={errors.cotacao}
                                    />

                                    <FormGroupInput
                                        sm="12"
                                        label="Qntd. hora comercial"
                                        type={FormGroupInputType.NUMBER}
                                        min={0}
                                        value={values.qntdHorasComercial}
                                        setValue={(qntdHorasComercial) => setValues({ ...values, qntdHorasComercial })}
                                        error={errors.qntdHorasComercial}
                                    />
                                    <FormGroupInput
                                        sm="12"
                                        label="Qntd. hora não comercial (1.5x)"
                                        type={FormGroupInputType.NUMBER}
                                        min={0}
                                        value={values.qntdHorasNaoComercial}
                                        setValue={(qntdHorasNaoComercial) =>
                                            setValues({ ...values, qntdHorasNaoComercial })
                                        }
                                        error={errors.qntdHorasNaoComercial}
                                    />
                                    <FormGroupInput
                                        sm="12"
                                        label="Qntd. hora especial (2x)"
                                        type={FormGroupInputType.NUMBER}
                                        min={0}
                                        value={values.qntdHorasEspecial}
                                        setValue={(qntdHorasEspecial) => setValues({ ...values, qntdHorasEspecial })}
                                        error={errors.qntdHorasEspecial}
                                    />

                                    <Col sm="12" className="text-secondary-dark">
                                        <small>Custo: {utils.retornaValorMonetario(custoTotal, tipoMoeda)}</small>
                                    </Col>

                                    <FormGroupInput
                                        label="Markup Bruto %"
                                        sm="6"
                                        type={FormGroupInputType.PORCENT}
                                        value={values.markup}
                                        setValue={(markup) => setValues({ ...values, markup })}
                                        error={errors.markup}
                                    />
                                    <FormGroupInput
                                        label="Markup Bruto"
                                        sm="6"
                                        type={FormGroupInputType.MONETARY}
                                        tipoMoeda={tipoMoeda}
                                        value={valorMarkup}
                                        setValue={(valor) => setValues({ ...values, markup: findPorcentMarkup(valor) })}
                                    />

                                    <FormGroupInput
                                        label="Venda (unitário)"
                                        sm="12"
                                        type={FormGroupInputType.MONETARY}
                                        tipoMoeda={tipoMoeda}
                                        value={valorVenda}
                                        setValue={(valorVenda) => atualizaMarkupPorValorVenda(valorVenda)}
                                    />
                                </Row>
                            </Col>

                            {values.tipoFaturamento === "DIRETO" && (
                                <Col sm="12" className="p-0 border-bottom">
                                    <Row className="p-2 pt-4 pb-4 row-gap-2">
                                        <Col sm="12">
                                            <h6>Dedutíveis e Impostos de Fornecimento</h6>
                                        </Col>

                                        <FormGroupInput
                                            label="Dedutíveis distribuidor"
                                            type={FormGroupInputType.MONETARY}
                                            sm="6"
                                            tipoMoeda={tipoMoeda}
                                            value={values.dedutiveisDistribuidor ?? 0}
                                            setValue={(dedutiveisDistribuidor) =>
                                                setValues({ ...values, dedutiveisDistribuidor })
                                            }
                                            error={errors.dedutiveisDistribuidor}
                                        />
                                        <FormGroupInput
                                            label={
                                                <>
                                                    Markup Líquido
                                                    <OverlayTrigger overlay={<Tooltip>{INFO_MARKUP_LIQUIDO}</Tooltip>}>
                                                        <i className="bi bi-question-circle-fill text-primary ms-1" />
                                                    </OverlayTrigger>
                                                </>
                                            }
                                            type={FormGroupInputType.MONETARY}
                                            sm="6"
                                            tipoMoeda={tipoMoeda}
                                            value={valorMarkupLiquido}
                                            setValue={(markupLiquido) =>
                                                setValues({
                                                    ...values,
                                                    dedutiveisDistribuidor: valorMarkup - markupLiquido,
                                                })
                                            }
                                        />
                                    </Row>
                                </Col>
                            )}

                            <Col sm="12" className="p-0">
                                <Row className="p-2 pt-4 pb-4">
                                    <Col sm="12">
                                        <h6>Tributos Referentes à Nota Fiscal da Revenda</h6>
                                    </Col>

                                    <FormGroupInput
                                        label="Imposto Nota %"
                                        type={FormGroupInputType.PORCENT}
                                        sm="6"
                                        value={values.impostoNota}
                                        setValue={(impostoNota) => setValues({ ...values, impostoNota })}
                                        error={errors.impostoNota}
                                    />
                                    <FormGroupInput
                                        label="Imposto Nota"
                                        type={FormGroupInputType.MONETARY}
                                        tipoMoeda={tipoMoeda}
                                        sm="6"
                                        value={valorNota}
                                        setValue={(valor) =>
                                            setValues({ ...values, impostoNota: findPorcentImpNota(valor) })
                                        }
                                    />

                                    <Col sm="12" className="text-secondary-dark mt-1">
                                        <small>Impostos: {utils.retornaValorMonetario(valorImpostos, tipoMoeda)}</small>
                                    </Col>
                                    <Col sm="12" className="text-secondary-dark">
                                        <small>Margem: {utils.retornaValorMonetario(valorMargem, tipoMoeda)}</small>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
}

export const yupEsquemaVendaServico: yup.ObjectSchema<tNovaVendaServico> = yup.object({
    servicoId: yup.string().required("Selecione um serviço."),
    dedutiveisDistribuidor: yup.number().required("Este campo é obrigatório."),
    impostoNota: yup.number().required("Este campo é obrigatório."),
    qntdHorasComercial: yup.number().required("Este campo é obrigatório."),
    qntdHorasEspecial: yup.number().required("Este campo é obrigatório."),
    qntdHorasNaoComercial: yup.number().required("Este campo é obrigatório."),
    markup: yup.number().required("Este campo é necessário."),
    cotacao: yup.number().required("Este campo é necessário.").min(0.000001, "O valor da cotação não pode ser zero."),
    tipoFaturamento: yup
        .mixed<tTipoFaturamento>()
        .oneOf(utils.retornaArrayTipoFaturamento())
        .required("Este campo é necessário."),
});
