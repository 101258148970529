import { useCallback, useContext, useEffect, useState } from "react";
import { LayoutTabela } from "../layouts";
import { tCliente } from "../interfaces";
import {
    BotaoAdicionarDado,
    TabelaCliente,
    OffcanvasCliente,
    FiltroSegmentos,
    ModalImportarClientes,
} from "../components";
import { ContextUser } from "../contexts/ContextUser";
import useCliente, { LIMITE_POR_BUSCA_CLIENTE } from "../hooks/useCliente";
import useFiltros from "../hooks/useFiltros";

export const QUERY_LIMIT_CLIENTE = 20;
export default function Clientes() {
    //HOOKS
    const { buscar, estaBuscando, cancelar, buscaComErro, importar } = useCliente();
    const { segmentoIdSelecioando } = useFiltros();

    //CONTEXTOS
    const { podeAdicionarCliente } = useContext(ContextUser);

    //ESTADOS
    const [clientes, setClientes] = useState<tCliente[]>([]);

    const [paginaAtual, setPaginaAtual] = useState(0);
    const [proximaPagina, setProximaPagina] = useState(1);
    const [campoPesquisa, setCamposPesquisa] = useState<string | undefined>(undefined);

    const [mostrarModalImport, setMostrarModalImport] = useState(false);
    const [mostrarOffcanvasAdd, setMostrarOffcanvasAdd] = useState(false);
    const [clienteEditando, setClienteEditando] = useState<tCliente | null>(null);
    const [clienteDeletando, setClienteDeletando] = useState<tCliente | null>(null);

    //EVENTOS
    const buscarClientes = useCallback(
        async (pagina?: number) => {
            try {
                const novosClientes = await buscar({
                    limit: LIMITE_POR_BUSCA_CLIENTE,
                    page: pagina,
                    where: campoPesquisa ? { campoPesquisa } : { segmentoId: segmentoIdSelecioando },
                });
                if (pagina === undefined) {
                    setClientes(novosClientes);
                    setPaginaAtual(0);
                    setProximaPagina(1);
                } else setClientes((atuais) => [...atuais, ...novosClientes]);
                return novosClientes.length; // Retorna pro InfiniteScroll
            } catch (err) {
                throw err; // Necessário para avisar que houve um erro ao InfiniteScroll
            }
        },
        [buscar, segmentoIdSelecioando, campoPesquisa]
    );

    useEffect(() => {
        buscarClientes().catch((err) => console.error(err));
        return () => cancelar();
    }, [cancelar, buscarClientes]);

    const handleOnClickImportClientes = useCallback(() => setMostrarModalImport(true), []);
    const handleOnClickNovoCliente = useCallback(() => setMostrarOffcanvasAdd(true), []);
    const handleOnClickEditCliente = useCallback((cliente: tCliente) => setClienteEditando(cliente), []);
    const handleOnClickDeleteCliente = useCallback((cliente: tCliente) => setClienteDeletando(cliente), []);

    return (
        <>
            <LayoutTabela.Root>
                <LayoutTabela.Header>
                    <LayoutTabela.HeaderLeft>
                        <FiltroSegmentos notActive={!!campoPesquisa}/>
                    </LayoutTabela.HeaderLeft>
                    <LayoutTabela.HeaderRight>
                        {podeAdicionarCliente({}) && (
                            <BotaoAdicionarDado
                                texto="Cliente"
                                onClickAdd={handleOnClickNovoCliente}
                                onClickImport={handleOnClickImportClientes}
                            />
                        )}
                    </LayoutTabela.HeaderRight>
                </LayoutTabela.Header>
                <LayoutTabela.Body
                    buscando={estaBuscando || buscaComErro}
                    paginaAtual={paginaAtual}
                    proximaPagina={proximaPagina}
                    setPaginaAtual={setPaginaAtual}
                    setProximaPagina={setProximaPagina}
                    buscar={buscarClientes}
                >
                    <TabelaCliente
                        clientes={clientes}
                        onEdit={handleOnClickEditCliente}
                        onDelete={handleOnClickDeleteCliente}
                        buscar={setCamposPesquisa}
                    />
                </LayoutTabela.Body>
            </LayoutTabela.Root>

            <ModalImportarClientes
                onImport={importar}
                onFinishImport={() => buscarClientes().catch((err) => console.error(err))}
                show={mostrarModalImport}
                onHide={() => setMostrarModalImport(false)}
            />

            <OffcanvasCliente
                tipo="criar"
                mostrarForm={mostrarOffcanvasAdd}
                onCancel={() => {
                    setMostrarOffcanvasAdd(false);
                    cancelar();
                }}
                onTerminou={() => buscarClientes().catch((err) => console.error(err))}
            />

            <OffcanvasCliente
                tipo="editar"
                mostrarForm={clienteEditando !== null}
                dados={clienteEditando}
                onTerminou={() => {
                    setClienteEditando(null);
                    buscarClientes().catch((err) => console.error(err));
                }}
                onCancel={() => {
                    setClienteEditando(null);
                    cancelar();
                }}
            />

            <OffcanvasCliente
                tipo="deletar"
                mostrarForm={clienteDeletando !== null}
                dados={clienteDeletando}
                onTerminou={() => {
                    setClienteDeletando(null);
                    buscarClientes().catch((err) => console.error(err));
                }}
                onCancel={() => {
                    setClienteDeletando(null);
                    cancelar();
                }}
            />
        </>
    );
}
