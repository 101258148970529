import { ListGroup } from "react-bootstrap";
import FiltroSelect from "./FiltroSelect";
import { useCallback, useContext, useEffect, useState } from "react";
import { tVendedor } from "../../interfaces";
import useVendedor from "../../hooks/useVendedor";
import { ContextUser } from "../../contexts/ContextUser";
import useFiltros, { FilterKey } from "../../hooks/useFiltros";

const NENHUM_FILTRO = "Todos os vendedores";

export type FiltroVendedoresProps = { notActive?: boolean };
export default function FiltroVendedores(props: FiltroVendedoresProps) {
    //CONTEXTOS
    const { podeVisualizarVendedor, usuario } = useContext(ContextUser);

    //HOOKS
    const { buscar, estaBuscando, buscaComErro, cancelar } = useVendedor();
    const { vendedorIdSelecionado, setFilter, removeFilter } = useFiltros();

    //ESTADOS
    const [vendedores, setVendedores] = useState<tVendedor[]>([]);
    const [pesquisa, setPesquisa] = useState<string | undefined>(undefined);

    //VARIAVEIS
    const possivelIdVendedorAtual = usuario?.vendedorUser?.id;
    const podeVisualizarTodos = podeVisualizarVendedor({});
    const podeVisualizarPossivelAtual = podeVisualizarVendedor({ id: possivelIdVendedorAtual });

    const vendedorSelecionado = vendedores.find(({ id }) => id === vendedorIdSelecionado);

    const vendedoresBuscados =
        pesquisa === undefined
            ? vendedores
            : vendedores.filter((vendedor) => vendedor.user.name.toLowerCase().includes(pesquisa.toLowerCase()));

    const retornaTextoFiltro = useCallback(() => {
        if (vendedorSelecionado === undefined) return NENHUM_FILTRO;
        else return vendedorSelecionado.user.name;
    }, [vendedorSelecionado]);

    //EVENTOS
    const handleOnSelecionarVendedor = useCallback(
        (vendedor: tVendedor | null) => {
            if (!vendedor) return removeFilter(FilterKey.VENDEDOR);
            setFilter(FilterKey.VENDEDOR, vendedor.id);
        },
        [removeFilter, setFilter]
    );

    const buscarVendedores = useCallback(async () => {
        try {
            let vendedores: tVendedor[] = [];

            if (podeVisualizarTodos) {
                vendedores = await buscar();
            } else if (podeVisualizarPossivelAtual) {
                vendedores = await buscar({ where: { id: possivelIdVendedorAtual } });
            }

            setVendedores(vendedores);
        } catch (err) {
            console.error(err);
        }
    }, [buscar, podeVisualizarTodos, podeVisualizarPossivelAtual, possivelIdVendedorAtual]);

    useEffect(() => {
        buscarVendedores();
        return () => cancelar();
    }, [buscarVendedores, cancelar]);

    //Pre-seleciona o único vendedor que o usuário possa ler
    useEffect(() => {
        if (!podeVisualizarTodos && podeVisualizarPossivelAtual)
            handleOnSelecionarVendedor(vendedores.find(({ id }) => id === possivelIdVendedorAtual) ?? null);
    }, [
        vendedores,
        podeVisualizarPossivelAtual,
        podeVisualizarTodos,
        possivelIdVendedorAtual,
        handleOnSelecionarVendedor,
    ]);

    const handleOnOpenFiltros = useCallback(() => {
        if (buscaComErro) buscarVendedores();
    }, [buscaComErro, buscarVendedores]);

    return (
        <FiltroSelect
            texto={retornaTextoFiltro()}
            onSearch={setPesquisa}
            carregando={buscaComErro || estaBuscando}
            onOpen={handleOnOpenFiltros}
            titulo="Vendedor"
            active={!!vendedorIdSelecionado && !props.notActive}
        >
            <ListGroup className="small">
                {podeVisualizarVendedor({}) && (
                    <ListGroup.Item
                        className={`my-item-filtro ${vendedorSelecionado === undefined ? "bg-light" : ""}`}
                        onClick={() => handleOnSelecionarVendedor(null)}
                    >
                        {NENHUM_FILTRO}
                    </ListGroup.Item>
                )}
                {vendedoresBuscados.map((vendedor) => (
                    <ListGroup.Item
                        className={`my-item-filtro ${vendedorSelecionado?.id === vendedor.id ? "bg-light" : ""}`}
                        key={vendedor.id}
                        onClick={() => handleOnSelecionarVendedor(vendedor)}
                    >
                        {vendedor.user.name}
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </FiltroSelect>
    );
}
