import { ListGroup } from "react-bootstrap";
import FiltroSelect from "./FiltroSelect";
import { useCallback, useEffect, useState } from "react";
import { tSegmento } from "../../interfaces";
import useSegmento from "../../hooks/useSegmento";
import useFiltros, { FilterKey } from "../../hooks/useFiltros";

const NENHUM_FILTRO = "Todos os segmentos";

export type FiltroSegmentosProps = { notActive?: boolean };
export default function FiltroSegmentos(props: FiltroSegmentosProps) {
    //HOOKS
    const { buscar, buscaComErro, cancelar, estaBuscando } = useSegmento();
    const { segmentoIdSelecioando, setFilter, removeFilter } = useFiltros();

    //ESTADOS
    const [segmentos, setSegmentos] = useState<tSegmento[]>([]);
    const [pesquisa, setPesquisa] = useState<string | undefined>(undefined);

    //VARIAVEIS
    const segmentoSelecionado = segmentos.find(({ id }) => id === segmentoIdSelecioando);
    const segmentosBuscados =
        pesquisa === undefined
            ? segmentos
            : segmentos.filter((segmento) => segmento.name.toLowerCase().includes(pesquisa.toLowerCase()));

    const retornaTextoFiltro = useCallback(() => {
        if (segmentoSelecionado === undefined) return NENHUM_FILTRO;
        else return segmentoSelecionado.name;
    }, [segmentoSelecionado]);

    //EVENTOS
    const buscaSegmentos = useCallback(async () => {
        try {
            const segmentos = await buscar();
            setSegmentos(segmentos);
        } catch (err) {
            console.error(err);
        }
    }, [buscar]);

    useEffect(() => {
        buscaSegmentos();
        return () => cancelar();
    }, [buscaSegmentos, cancelar]);

    const handleOnOpenFiltros = useCallback(() => {
        if (!buscaComErro) buscaSegmentos();
    }, [buscaComErro, buscaSegmentos]);

    const handleOnSelecionarSegmento = useCallback(
        (segmento: tSegmento | null) => {
            if (!segmento) return removeFilter(FilterKey.SEGMENTO);
            setFilter(FilterKey.SEGMENTO, segmento?.id);
        },
        [setFilter, removeFilter]
    );

    return (
        <FiltroSelect
            texto={retornaTextoFiltro()}
            onSearch={setPesquisa}
            carregando={estaBuscando || buscaComErro}
            onOpen={handleOnOpenFiltros}
            titulo="Segmento"
            active={!!segmentoIdSelecioando && !props.notActive}
        >
            <ListGroup className="small">
                <ListGroup.Item
                    className={`my-item-filtro ${segmentoIdSelecioando === null ? "bg-light" : ""}`}
                    onClick={() => handleOnSelecionarSegmento(null)}
                >
                    {NENHUM_FILTRO}
                </ListGroup.Item>
                {segmentosBuscados.map((segmento) => (
                    <ListGroup.Item
                        className={`my-item-filtro ${segmentoIdSelecioando === segmento.id ? "bg-light" : ""}`}
                        key={segmento.id}
                        onClick={() => handleOnSelecionarSegmento(segmento)}
                    >
                        {segmento.name}
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </FiltroSelect>
    );
}
