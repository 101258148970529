import { Form, ProgressBar, Stack } from "react-bootstrap";
import { useCallback, useState } from "react";
import utils from "../../utils";
import { tDashRankVendedorPorFaturamento, DashboardMetric } from "../../interfaces";
import "./styles.css";

export default function CardTopVendedores(props: {
    metrica: DashboardMetric;
    dados: tDashRankVendedorPorFaturamento[];
}) {
    //ESTADOS
    const [busca, setBusca] = useState("");
    const [vendedorOnOver, setVendedorOnOver] = useState<string | null>(null);

    //VARIAVEIS
    const dadosFiltrados = props.dados.filter(({ vendedor }) =>
        vendedor.user.name.toLowerCase().includes(busca.toLowerCase())
    );

    //EVENTOS
    const handleOnBusca = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setBusca(event.target.value);
    }, []);

    return (
        <div className="my-card-top-vendedores d-flex shadow rounded-4 text-white">
            <Stack className="d-flex w-100 gap-2 overflow-auto p-3">
                <h6>Rank Vendedores por {props.metrica}</h6>
                <div className="d-flex justify-content-end w-100">
                    <div className="d-flex mb-3 w-50">
                        <Form.Control
                            placeholder="Buscar vendedor"
                            className="bg-transparent text-white rounded-3 my-placeholder-light"
                            size="sm"
                            onChange={handleOnBusca}
                            value={busca}
                        />
                        <div className="d-flex h-100 align-items-center ps-2 pe-2">
                            <i className="bi bi-search" />
                        </div>
                    </div>
                </div>

                {dadosFiltrados.map((dado) => (
                    <div
                        key={dado.vendedor.id}
                        className="my-div-card-rank-root rounded bg-dark bg-opacity-25 position-relative"
                        role="button"
                        onMouseEnter={() => setVendedorOnOver(dado.vendedor.id)}
                        onMouseLeave={() => setVendedorOnOver(null)}
                    >
                        <div className="my-div-card-rank-icon align-items-center fs-4 justify-content-center">
                            {dado.posicao === 1 ? (
                                <span>🥇</span>
                            ) : dado.posicao === 2 ? (
                                <span>🥈</span>
                            ) : dado.posicao === 3 ? (
                                <span>🥉</span>
                            ) : (
                                <span className="fs-5">{dado.posicao}</span>
                            )}
                        </div>
                        <div className="my-div-card-rank-nome ms-2">
                            <Stack className="w-100 h-100 justify-content-center d-flex">
                                <span>{dado.vendedor.user.name}</span>
                                <small className="text-truncate">{dado.vendedor.user.email}</small>
                            </Stack>
                        </div>
                        <div className="my-div-card-rank-valor">
                            <Stack className="w-100 h-100 justify-content-center d-flex align-items-end pe-3">
                                <div
                                    className={`ps-4 pe-4 pt-3 rounded mb-1 bg-opacity-75 bg-${
                                        dado.posicao === 1
                                            ? "ouro"
                                            : dado.posicao === 2
                                            ? "prata"
                                            : dado.posicao === 3
                                            ? "bronze"
                                            : "primary"
                                    }`}
                                ></div>
                                <span>
                                    {props.metrica === DashboardMetric.VISITA
                                        ? dado.valorAcumulado
                                        : utils.retornaValorMonetario(dado.valorAcumulado, "BRL")}
                                </span>
                            </Stack>
                        </div>
                        {props.metrica !== DashboardMetric.VISITA && (
                            <ProgressBar
                                now={(dado.valorAcumuladoVendaNova * 100) / dado.valorAcumulado}
                                variant="success"
                                label={
                                    vendedorOnOver === dado.vendedor.id ? (
                                        <DivVendaNovaVsVendaRenovacao dado={dado} />
                                    ) : undefined
                                }
                                className={`my-div-card-top-vendedores-venda-renovacao ${
                                    vendedorOnOver === dado.vendedor.id ? "show-more" : ""
                                } rounded-0 bg-danger  position-absolute w-100 bottom-0 start-0`}
                            />
                        )}
                    </div>
                ))}
            </Stack>
        </div>
    );
}

//Funções auxiliares
function DivVendaNovaVsVendaRenovacao({ dado }: { dado: tDashRankVendedorPorFaturamento }) {
    const porcentagemNovaVenda =
        dado.valorAcumulado !== 0 ? (dado.valorAcumuladoVendaNova * 100) / dado.valorAcumulado : 0;
    const porcentagemVendaRenovacao =
        dado.valorAcumulado !== 0 ? (dado.valorAcumuladoVendaRenovacao * 100) / dado.valorAcumulado : 0;

    return (
        <div className="position-absolute w-100 d-flex justify-content-center">
            <table className="w-25">
                <thead>
                    <tr>
                        <th className="text-start pe-2">Novas vendas</th>
                        <th className="text-end">Renovações</th>
                    </tr>
                </thead>
                <tbody className="fw-bold">
                    <tr>
                        <td className="text-start pe-2">
                            {utils.retornaValorMonetario(dado.valorAcumuladoVendaNova, "BRL")}
                        </td>
                        <td className="text-end">
                            {utils.retornaValorMonetario(dado.valorAcumuladoVendaRenovacao, "BRL")}
                        </td>
                    </tr>
                    <tr>
                        <td className="text-start pe-2">{porcentagemNovaVenda.toFixed(2)}%</td>
                        <td className="text-end">{porcentagemVendaRenovacao.toFixed(2)}%</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
