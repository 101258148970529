import { ListGroup } from "react-bootstrap";
import FiltroSelect from "./FiltroSelect";
import { useCallback, useState } from "react";
import { EstadoVenda } from "../../interfaces";
import utils from "../../utils";
import useFiltros, { FilterKey } from "../../hooks/useFiltros";

const NENHUM_FILTRO = "Todos os estados";

export type FiltroEstadosVendasProps = { notActive?: boolean };
export default function FiltroEstadosVenda(props: FiltroEstadosVendasProps) {
    //HOOKS
    const { setFilter, removeFilter, estadoVendaSelecionado } = useFiltros();

    //ESTADOS
    const [pesquisa, setPesquisa] = useState<string | undefined>(undefined);

    //VARIAVEIS
    const estados = utils.returnEstadosVenda();
    const estadosBuscados =
        pesquisa === undefined
            ? estados
            : estados.filter((estado) =>
                  utils.returnTextoEstadoVenda(estado).toLowerCase().includes(pesquisa.toLowerCase())
              );

    const retornaTextoFiltro = useCallback(() => {
        if (!estadoVendaSelecionado) return NENHUM_FILTRO;
        else return utils.returnTextoEstadoVenda(estadoVendaSelecionado);
    }, [estadoVendaSelecionado]);

    //EVENTOS
    const handleOnSelecionarSegmento = useCallback(
        (estado: EstadoVenda | null) => {
            if (estado === null) return removeFilter(FilterKey.ESTADO_VENDA);
            setFilter(FilterKey.ESTADO_VENDA, estado);
        },
        [setFilter, removeFilter]
    );

    return (
        <FiltroSelect
            texto={retornaTextoFiltro()}
            onSearch={setPesquisa}
            titulo="Estado Venda"
            active={!!estadoVendaSelecionado && !props.notActive}
        >
            <ListGroup className="small">
                <ListGroup.Item
                    className={`my-item-filtro ${estadoVendaSelecionado === null ? "bg-light" : ""}`}
                    onClick={() => handleOnSelecionarSegmento(null)}
                >
                    {NENHUM_FILTRO}
                </ListGroup.Item>
                {estadosBuscados.map((estado) => (
                    <ListGroup.Item
                        className={`my-item-filtro ${estadoVendaSelecionado === estado ? "bg-light" : ""}`}
                        key={estado}
                        onClick={() => handleOnSelecionarSegmento(estado)}
                    >
                        <div className="d-flex align-items-center justify-content-between gap-2">
                            {utils.returnTextoEstadoVenda(estado)}{" "}
                            {estado === EstadoVenda.CRIADA ? (
                                <div className="p-2 border rounded-circle" />
                            ) : estado === EstadoVenda.AGUARDANDO_VERIFICACAO ? (
                                <div className="p-2 bg-warning border border-warning rounded-circle" />
                            ) : (
                                <div className="p-2 bg-success border border-success rounded-circle" />
                            )}
                        </div>
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </FiltroSelect>
    );
}
