import { Stack } from "react-bootstrap";
import CardDashboard from "./CardDashboard";
import { MetaDashboardCardProps } from "./types";
import { DashboardMetric } from "../../interfaces";
import utils from "../../utils";

export default function MetaDashboardCard(props: MetaDashboardCardProps) {
    const porcentMetaAtingida = props.metaAtual !== 0 ? (props.metaAtingidaAtual * 100) / props.metaAtual : null;
    const porcentMetaAtingidaAtualVsAnterior =
        ((props.metaAtingidaAtual - props.metaAtingidaAnterior) * 100) / props.metaAtingidaAnterior;

    const textMetaAtingidaAtual =
        props.metrica === DashboardMetric.VISITA
            ? props.metaAtingidaAtual
            : utils.retornaValorMonetario(props.metaAtingidaAtual);
    const textMetaAtual =
        props.metrica === DashboardMetric.VISITA ? props.metaAtual : utils.retornaValorMonetario(props.metaAtual, "BRL");

    return (
        <CardDashboard
            label={"Meta de " + props.metrica}
            valor={
                <Stack className="flex gap-1 py-2">
                    <span className="lh-1">
                        {porcentMetaAtingida === null ? "--,--" : porcentMetaAtingida.toFixed(2)} %
                    </span>
                    <span className="fs-6 lh-1">
                        {textMetaAtingidaAtual}/{textMetaAtual}
                    </span>
                </Stack>
            }
            icon="crosshair2"
            color="orange"
            porcent={{
                valor: porcentMetaAtingidaAtualVsAnterior,
                miniLabel: "vs. ano anterior",
            }}
        />
    );
}
