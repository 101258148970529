import { ListGroup } from "react-bootstrap";
import FiltroSelect from "./FiltroSelect";
import { useCallback, useEffect, useState } from "react";
import { tTipoProdutoServico } from "../../interfaces";
import useTipoProdutoServico from "../../hooks/useTipoProdutoServico";
import useFiltros, { FilterKey } from "../../hooks/useFiltros";

const NENHUM_FILTRO = "Todos os tipos";

export type FiltroTipoProdServProps = { notActive?: boolean };
export default function FiltroTiposProdServ(props: FiltroTipoProdServProps) {
    //HOOKS
    const { buscar, buscaComErro, cancelar, estaBuscando } = useTipoProdutoServico();
    const { tipoProdServIdSelecionado, setFilter, removeFilter } = useFiltros();

    //ESTADOS
    const [tiposProdServ, setTiposProdServ] = useState<tTipoProdutoServico[]>([]);
    const [pesquisa, setPesquisa] = useState<string | undefined>(undefined);

    //VARIAVEIS
    const tipoProdServSelecionado = tiposProdServ.find(({ id }) => id === tipoProdServIdSelecionado);
    const tiposProdServBuscados =
        pesquisa === undefined
            ? tiposProdServ
            : tiposProdServ.filter((tipo) => tipo.name.toLowerCase().includes(pesquisa.toLowerCase()));

    const retornaTextoFiltro = useCallback(() => {
        if (tipoProdServSelecionado === undefined) return NENHUM_FILTRO;
        else return tipoProdServSelecionado.name;
    }, [tipoProdServSelecionado]);

    //EVENTOS
    const buscaTiposProdServ = useCallback(async () => {
        try {
            const tipos = await buscar();
            setTiposProdServ(tipos);
        } catch (err) {
            console.error(err);
        }
    }, [buscar]);

    useEffect(() => {
        buscaTiposProdServ();
        return () => cancelar();
    }, [buscaTiposProdServ, cancelar]);

    const handleOnOpenFiltros = useCallback(() => {
        if (buscaComErro) buscaTiposProdServ();
    }, [buscaComErro, buscaTiposProdServ]);

    const handleOnSelecionarTipoProdServ = useCallback(
        (tipoProdServ: tTipoProdutoServico | null) => {
            if (!tipoProdServ) return removeFilter(FilterKey.TIPO_PROD_SERV);
            setFilter(FilterKey.TIPO_PROD_SERV, tipoProdServ.id);
        },
        [removeFilter, setFilter]
    );

    return (
        <FiltroSelect
            texto={retornaTextoFiltro()}
            onSearch={setPesquisa}
            carregando={estaBuscando || buscaComErro}
            onOpen={handleOnOpenFiltros}
            titulo="Tipo"
            active={!!tipoProdServIdSelecionado && !props.notActive}
        >
            <ListGroup className="small">
                <ListGroup.Item
                    className={`my-item-filtro ${tipoProdServSelecionado === undefined ? "bg-light" : ""}`}
                    onClick={() => handleOnSelecionarTipoProdServ(null)}
                >
                    {NENHUM_FILTRO}
                </ListGroup.Item>
                {tiposProdServBuscados.map((tipo) => (
                    <ListGroup.Item
                        className={`my-item-filtro ${tipoProdServSelecionado?.id === tipo.id ? "bg-light" : ""}`}
                        key={tipo.id}
                        onClick={() => handleOnSelecionarTipoProdServ(tipo)}
                    >
                        {tipo.name}
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </FiltroSelect>
    );
}
