import { useCallback, useContext, useState } from "react";
import { PartialEntity, tVisita } from "../interfaces";
import { ContextAlerta } from "../contexts/ContextAlert";
import api, { tQueryParamsTotalPagar, tQueryParamsVisita, tQueryParamsVisitaRelatorio } from "../services/api";
import utils from "../utils";
import { tNovaVisita } from "../components/forms/FormVisita";
import { tVendedorGastoTotal } from "../components/dropdowns/DropdownGastoTotal";

let abortController: AbortController | undefined;
export const LIMITE_POR_BUSCA_VISITAS = 50;

export default function useVisita() {
    //CONTEXTOS
    const { setPerigo, setSucesso } = useContext(ContextAlerta);

    //ESTADOS
    const [arrBuscando, setArrBuscando] = useState<true[]>([]);
    const [arrBuscandoPorId, setArrBuscandoPorId] = useState<true[]>([]);
    const [arrBuscandoRelatorios, setArrBuscandoRelatorios] = useState<true[]>([]);
    const [buscaRelatorioComErro, setBuscaRelatorioComErro] = useState(false);
    const [arrBuscandoTotalPagar, setArrBuscandoTotalPagar] = useState<true[]>([]);
    const [estaCriando, setEstaCriando] = useState(false);
    const [estaDeletando, setEstaDeletando] = useState(false);
    const [estaEditando, setEstaEditando] = useState(false);
    const [buscaComErro, setBuscaComErro] = useState(false);
    const [buscaTotalPagarComErro, setBuscaTotalPagarComErro] = useState(false);

    const buscar = useCallback(
        async (params?: tQueryParamsVisita): Promise<tVisita[]> => {
            abortController = new AbortController();
            const signal = abortController.signal;

            return new Promise(async (resolve, reject) => {
                try {
                    setArrBuscando((current) => [...current, true]);
                    const res = await api.listaVisitas(params, signal);
                    setBuscaComErro(false);
                    resolve(res.data);
                } catch (err) {
                    if (utils.blErroCancelamento(err)) return;
                    setPerigo(utils.retornaMensagemErro(err));
                    setBuscaComErro(true);
                    reject(err);
                } finally {
                    setArrBuscando((current) => current.slice(1));
                }
            });
        },
        [setPerigo]
    );

    const buscarPorId = useCallback(
        async (id: string): Promise<tVisita> => {
            abortController = new AbortController();
            const signal = abortController.signal;

            return new Promise(async (resolve, reject) => {
                try {
                    setArrBuscandoPorId((current) => [...current, true]);
                    const res = await api.getVisita(id, signal);
                    setBuscaComErro(false);
                    resolve(res.data);
                } catch (err) {
                    if (utils.blErroCancelamento(err)) return;
                    setPerigo(utils.retornaMensagemErro(err));
                    setBuscaComErro(true);
                    reject(err);
                } finally {
                    setArrBuscandoPorId((current) => current.slice(1));
                }
            });
        },
        [setPerigo]
    );

    const buscarTotalGastosPagar = useCallback(
        async (params: tQueryParamsTotalPagar): Promise<tVendedorGastoTotal[]> => {
            abortController = new AbortController();
            const signal = abortController.signal;

            return new Promise(async (resolve, reject) => {
                try {
                    setArrBuscandoTotalPagar((current) => [...current, true]);
                    const res = await api.totalVisitaPagar(params, signal);
                    setBuscaTotalPagarComErro(false);
                    resolve(res.data);
                } catch (err) {
                    if (utils.blErroCancelamento(err)) return;
                    setPerigo(utils.retornaMensagemErro(err));
                    setBuscaTotalPagarComErro(true);
                    reject(err);
                } finally {
                    setArrBuscandoTotalPagar((current) => current.slice(1));
                }
            });
        },
        [setPerigo]
    );

    const buscarRelatorios = useCallback(
        async (params?: tQueryParamsVisitaRelatorio): Promise<Blob> => {
            abortController = new AbortController();
            const signal = abortController.signal;

            return new Promise(async (resolve, reject) => {
                try {
                    setArrBuscandoRelatorios((current) => [...current, true]);
                    const res = await api.buscarRelatoriosVisita(params, signal);
                    setBuscaRelatorioComErro(false);
                    resolve(res.data);
                } catch (err) {
                    if (utils.blErroCancelamento(err)) return;
                    setPerigo(utils.retornaMensagemErro(err));
                    setBuscaRelatorioComErro(true);
                    reject(err);
                } finally {
                    setArrBuscandoRelatorios((current) => current.slice(1));
                }
            });
        },
        [setPerigo]
    );

    const criar = useCallback(
        async (visitas: tNovaVisita[]): Promise<void> => {
            abortController = new AbortController();
            const signal = abortController.signal;

            return new Promise(async (resolve, reject) => {
                try {
                    setEstaCriando(true);
                    await api.insertVisitas(visitas, signal);
                    setSucesso(visitas.length > 1 ? "Visitas adicionadas!" : "Visita adicionada!");
                    resolve();
                } catch (err) {
                    if (utils.blErroCancelamento(err)) return;
                    setPerigo(utils.retornaMensagemErro(err));
                    reject(err);
                } finally {
                    setEstaCriando(false);
                }
            });
        },
        [setPerigo, setSucesso]
    );

    const editar = useCallback(
        async (visita: tNovaVisita & PartialEntity<tVisita, "id">): Promise<void> => {
            abortController = new AbortController();
            const signal = abortController.signal;

            return new Promise(async (resolve, reject) => {
                try {
                    setEstaEditando(true);
                    await api.editarVisita(visita, signal);
                    setSucesso("Visita editada!");
                    resolve();
                } catch (err) {
                    if (utils.blErroCancelamento(err)) return;
                    setPerigo(utils.retornaMensagemErro(err));
                    reject(err);
                } finally {
                    setEstaEditando(false);
                }
            });
        },
        [setPerigo, setSucesso]
    );

    const editarVarias = useCallback(
        async (visitasId: string[], visitaPaga: boolean) => {
            abortController = new AbortController();
            const signal = abortController.signal;

            return new Promise<void>(async (resolve, reject) => {
                try {
                    setEstaEditando(true);
                    await api.editarVariasVisitas(visitasId, visitaPaga, signal);
                    setSucesso("Visitas pagas!");
                    resolve();
                } catch (err) {
                    if (utils.blErroCancelamento(err)) return;
                    setPerigo(utils.retornaMensagemErro(err));
                    reject(err);
                } finally {
                    setEstaEditando(false);
                }
            });
        },
        [setSucesso, setPerigo]
    );

    const deletar = useCallback(
        async (id: string, params?: { vendedorId?: string }): Promise<void> => {
            abortController = new AbortController();
            const signal = abortController.signal;

            return new Promise(async (resolve, reject) => {
                try {
                    setEstaDeletando(true);
                    await api.deletarVisita(id, params, signal);
                    setSucesso("Visita deletada!");
                    resolve();
                } catch (err) {
                    if (utils.blErroCancelamento(err)) return;
                    setPerigo(utils.retornaMensagemErro(err));
                    reject(err);
                } finally {
                    setEstaDeletando(false);
                }
            });
        },
        [setPerigo, setSucesso]
    );

    const cancelar = useCallback(() => abortController?.abort(), []);

    return {
        estaBuscando: arrBuscando.length > 0,
        estaBuscandoPorId: arrBuscandoPorId.length > 0,
        estaBuscandoRelatorios: arrBuscandoRelatorios.length > 0,
        estaBuscandoTotalPagar: arrBuscandoTotalPagar.length > 0,
        buscaRelatorioComErro,
        estaCriando,
        estaDeletando,
        estaEditando,
        buscaComErro,
        buscaTotalPagarComErro,
        buscar,
        buscarPorId,
        buscarRelatorios,
        buscarTotalGastosPagar,
        criar,
        editar,
        editarVarias,
        deletar,
        cancelar,
    };
}
