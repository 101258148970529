import { useCallback, useContext, useEffect, useState } from "react";
import {
    BotaoAdicionarDado,
    FiltroAnos,
    FiltroEstadosVenda,
    FiltroMes,
    FiltroVendedores,
    OffcanvasVenda,
    TabelaVenda,
} from "../components";
import { LayoutTabela } from "../layouts";
import { EstadoVenda, tVenda } from "../interfaces";
import { ContextUser } from "../contexts/ContextUser";
import useVenda, { LIMITE_POR_BUSCA_VENDAS } from "../hooks/useVenda";
import { ContextAlerta } from "../contexts/ContextAlert";
import { tNovaVenda } from "../components/forms/FormVenda";
import utils from "../utils";
import useFiltros from "../hooks/useFiltros";

export default function Vendas() {
    //HOOKS
    const { buscar, estaBuscando, cancelar, buscaComErro, editar } = useVenda();
    const { vendedorIdSelecionado, dataInicialSelecionada, dataFinalSelecionada, estadoVendaSelecionado } =
        useFiltros();

    //CONTEXTOS
    const { carregandoUsuario, podeAdicionarVenda, usuario, podeVisualizarVenda } = useContext(ContextUser);
    const { setProcessando } = useContext(ContextAlerta);

    //ESTADOS
    const [vendas, setVendas] = useState<tVenda[]>([]);
    const [paginaAtual, setPaginaAtual] = useState(0);
    const [proximaPagina, setProximaPagina] = useState(1);
    const [campoPesquisa, setCampoPesquisa] = useState<string | undefined>(undefined);

    const [vendaEditando, setVendaEditando] = useState<tVenda | null>(null);
    const [vendaDeletando, setVendaDeletando] = useState<tVenda | null>(null);
    const [vendaDuplicando, setVendaDuplicando] = useState<tVenda | undefined>(undefined);
    const [mostrarOffcanvasAdicionar, setMostrarOffcanvasAdicionar] = useState(false);

    //EVENTOS
    const buscarVendas = useCallback(
        async (pagina?: number) => {
            try {
                const whereComPesquisa = {
                    campoPesquisa,
                    vendedorId: podeVisualizarVenda({}) ? undefined : vendedorIdSelecionado,
                };
                const where = {
                    vendedorId: vendedorIdSelecionado,
                    estadoVenda: estadoVendaSelecionado,
                    dataInicio: dataInicialSelecionada,
                    dataFim: dataFinalSelecionada,
                    campoPesquisa,
                };
                if (!podeVisualizarVenda(where) || !podeVisualizarVenda(whereComPesquisa)) return 0;

                const novasVendas = await buscar({
                    limit: LIMITE_POR_BUSCA_VENDAS,
                    page: pagina,
                    where: campoPesquisa ? whereComPesquisa : where,
                    sortBy: "dataVenda",
                    sortType: "asc",
                });
                if (pagina === undefined) {
                    setVendas(novasVendas);
                    setPaginaAtual(0);
                    setProximaPagina(1);
                } else setVendas((atuais) => [...atuais, ...novasVendas]);
                return novasVendas.length; // Retorna pro InfiniteScroll
            } catch (err) {
                throw err; // Necessário para avisar que houve um erro ao InfiniteScroll
            }
        },
        [
            buscar,
            vendedorIdSelecionado,
            dataInicialSelecionada,
            dataFinalSelecionada,
            estadoVendaSelecionado,
            podeVisualizarVenda,
            campoPesquisa,
        ]
    );

    useEffect(() => {
        buscarVendas().catch((err) => console.error(err));
        return () => cancelar();
    }, [cancelar, buscarVendas]);

    const handleOnClickAdd = useCallback(() => setMostrarOffcanvasAdicionar(true), []);
    const handleOnClickEdit = useCallback((venda: tVenda) => setVendaEditando(venda), []);
    const handleOnClickDelete = useCallback((venda: tVenda) => setVendaDeletando(venda), []);

    const handleOnDuplicate = useCallback((venda: tVenda) => setVendaDuplicando(venda), []);
    const handleOnMudarEstado = useCallback(
        async (venda: tVenda, novoEstado: EstadoVenda) => {
            try {
                setProcessando(true);
                const novaVenda: tNovaVenda = utils.retornaNovaVendaDeVenda({ ...venda, estadoVenda: novoEstado });
                await editar({ id: venda.id, ...novaVenda });

                buscarVendas().catch((err) => console.error(err));
            } catch (err) {
                console.error(err);
            } finally {
                setProcessando(false);
            }
        },
        [editar, buscarVendas, setProcessando]
    );

    return (
        <>
            <LayoutTabela.Root>
                <LayoutTabela.Header>
                    <LayoutTabela.HeaderLeft>
                        <FiltroAnos notActive={!!campoPesquisa}/>
                        <FiltroMes notActive={!!campoPesquisa}/>
                        <FiltroEstadosVenda notActive={!!campoPesquisa}/>
                        <FiltroVendedores notActive={!!campoPesquisa}/>
                    </LayoutTabela.HeaderLeft>
                    <LayoutTabela.HeaderRight>
                        {podeAdicionarVenda({ vendedoresId: [usuario?.vendedorUser?.id ?? ""] }) && (
                            <BotaoAdicionarDado texto="Venda" onClickAdd={handleOnClickAdd} />
                        )}
                    </LayoutTabela.HeaderRight>
                </LayoutTabela.Header>

                <LayoutTabela.Body
                    buscando={estaBuscando || carregandoUsuario || buscaComErro}
                    paginaAtual={paginaAtual}
                    proximaPagina={proximaPagina}
                    setPaginaAtual={setPaginaAtual}
                    setProximaPagina={setProximaPagina}
                    buscar={buscarVendas}
                >
                    <TabelaVenda
                        vendas={vendas}
                        onEdit={handleOnClickEdit}
                        onDelete={handleOnClickDelete}
                        onDuplicate={handleOnDuplicate}
                        onChangeState={handleOnMudarEstado}
                        buscar={setCampoPesquisa}
                    />
                </LayoutTabela.Body>
            </LayoutTabela.Root>

            <OffcanvasVenda
                tipo="criar"
                dados={vendaDuplicando}
                mostrarForm={mostrarOffcanvasAdicionar || !!vendaDuplicando}
                onCancel={() => {
                    setMostrarOffcanvasAdicionar(false);
                    setVendaDuplicando(undefined);
                    cancelar();
                }}
                onTerminou={() => {
                    buscarVendas().catch((err) => console.error(err));
                    setVendaDuplicando(undefined);
                }}
            />

            <OffcanvasVenda
                tipo="editar"
                mostrarForm={vendaEditando !== null}
                onCancel={() => {
                    setVendaEditando(null);
                    cancelar();
                }}
                onTerminou={() => {
                    setVendaEditando(null);
                    buscarVendas().catch((err) => console.error(err));
                }}
                dados={vendaEditando}
            />

            <OffcanvasVenda
                tipo="deletar"
                mostrarForm={vendaDeletando !== null}
                onCancel={() => {
                    setVendaDeletando(null);
                    cancelar();
                }}
                onTerminou={() => {
                    setVendaDeletando(null);
                    buscarVendas().catch((err) => console.error(err));
                }}
                dados={vendaDeletando}
            />
        </>
    );
}
