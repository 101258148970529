import { tVisita } from "../../interfaces";
import { Tabela } from "./Tabela";
import React, { useCallback, useContext, useState } from "react";
import { ContextUser } from "../../contexts/ContextUser";
import { ContextAlerta } from "../../contexts/ContextAlert";
import ModalAuditoriaVisita from "../modals/ModalAuditoriaVisita";
import SpanVisitaGasto from "../span/SpanVisitaGasto";
import SpanVisitaKilometragem from "../span/SpanVisitaKilometragem";
import "./styles.css";
import utils from "../../utils";
import { Col, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { v4 } from "uuid";
import InputSearch from "../inputs/InputSearch";
import DropdownGastoTotal, { DropdownGastoTotalProps } from "../dropdowns/DropdownGastoTotal";
import DropdownRelatoriosVisitas from "../dropdowns/DropdownRelatoriosVisitas";

interface ITabelaVisitaProps {
    visitas: tVisita[];
    onEdit: (visita: tVisita) => void;
    onDelete: (visita: tVisita) => void;
    buscar?: (value: string | undefined) => void;
    onChangePaga: (visita: tVisita, value: boolean) => void;
    propsDropdownGastoTotal: DropdownGastoTotalProps;
}

export default function TabelaVisita({ onEdit, onDelete, buscar, onChangePaga, ...props }: ITabelaVisitaProps) {
    //CONTEXTOS
    const { podeEditarVisita, podeDeletarVisita, podeVisualizarAuditoria } = useContext(ContextUser);
    const { setAviso } = useContext(ContextAlerta);

    //ESTADOS
    const [pesquisa, setPesquisa] = useState<undefined | string>(undefined);
    const [visitaAuditando, setVisitaAuditando] = useState<tVisita | null>(null);

    //EVENTOS
    const handleOnClickOpenAuditoria = useCallback((visita: tVisita) => setVisitaAuditando(visita), []);
    const handleOnHideAuditoria = useCallback(() => setVisitaAuditando(null), []);

    const handleOnChangePesquisa = useCallback(
        async (novaPesquisa?: string) => {
            try {
                setPesquisa(novaPesquisa);
                if (buscar) await buscar(novaPesquisa);
            } catch (err) {
                console.error(err);
            }
        },
        [buscar]
    );

    const handleOnEdit = useCallback(
        (visita: tVisita) => {
            if (!podeEditarVisita({ ...visita })) return setAviso("Você não possui permissão para realizar esta ação.");
            return onEdit(visita);
        },
        [onEdit, podeEditarVisita, setAviso]
    );
    const handleOnDelete = useCallback(
        (visita: tVisita) => {
            if (!podeDeletarVisita({ ...visita }))
                return setAviso("Você não possui permissão para realizar esta ação.");
            return onDelete(visita);
        },
        [onDelete, podeDeletarVisita, setAviso]
    );
    const handleOnAuditoria = useCallback(
        (visita: tVisita) => {
            if (!podeVisualizarAuditoria({ tabela: "VISITA" }))
                return setAviso("Você não possui permissão para realizar esta ação.");
            return handleOnClickOpenAuditoria(visita);
        },
        [podeVisualizarAuditoria, handleOnClickOpenAuditoria, setAviso]
    );
    const handleOnCustom = useCallback(
        (visita: tVisita) => {
            if (visita.visitaPaga) return onChangePaga(visita, false);
            return onChangePaga(visita, true);
        },
        [onChangePaga]
    );

    return (
        <>
            {buscar && (
                <Tabela.ComponentTop>
                    <Col sm="6" md="5" xl="3">
                        <InputSearch
                            placeholder="Nome cliente, nome vendedor..."
                            pesquisa={pesquisa}
                            onChange={handleOnChangePesquisa}
                        />
                    </Col>
                    <Col sm="auto">
                        <DropdownGastoTotal {...props.propsDropdownGastoTotal} />
                    </Col>
                    <Col sm="auto">
                        <DropdownRelatoriosVisitas />
                    </Col>
                </Tabela.ComponentTop>
            )}
            <Tabela.Table>
                <Tabela.Header>
                    <tr>
                        <th>Data da Visita</th>
                        <th>Vendedor</th>
                        <th>Cliente</th>
                        <th>Visita Remota</th>
                        <th>Carro da empresa</th>
                        <th>Comentários</th>
                        <th>Kilometragem</th>
                        <th>Gastos e notas</th>
                    </tr>
                </Tabela.Header>
                <Tabela.Body
                    dados={props.visitas}
                    campoUnico="id"
                    onEdit={handleOnEdit}
                    onDelete={handleOnDelete}
                    onClickCustomButton={podeEditarVisita({ visitaPaga: true }) ? handleOnCustom : undefined}
                    customButton={useCustomButton}
                    disabledCustom={(visita: tVisita) => visita.visitaPaga}
                    onClickAuditoria={handleOnAuditoria}
                >
                    {(visita: tVisita) => (
                        <>
                            <td className="position-relative">
                                <OverlayTrigger
                                    placement="bottom-start"
                                    overlay={<Tooltip>{visita.visitaPaga ? "Pago" : "Não pago"}</Tooltip>}
                                >
                                    <div
                                        className={`d-flex position-absolute h-100 start-0 top-0 p-1 pe-0 bg-${
                                            visita.visitaPaga ? "success" : "warning"
                                        }`}
                                    />
                                </OverlayTrigger>
                                {visita.dataVisita.toLocaleDateString("pt-Br", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                })}
                            </td>
                            <td>{visita.vendedor.user.name}</td>
                            <td>{visita.cliente.name}</td>
                            <td>{visita.remoto ? "Sim" : "Não"}</td>
                            <td>{utils.isNil(visita.carroEmpresa) ? "----" : visita.carroEmpresa ? "Sim" : "Não"}</td>
                            <td>
                                <ElementoComentario comentarios={visita.comentarios} />
                            </td>
                            <td>
                                <SpanVisitaKilometragem visita={visita} />
                            </td>
                            <td>
                                <SpanVisitaGasto visita={visita} />
                            </td>
                        </>
                    )}
                </Tabela.Body>
            </Tabela.Table>

            <ModalAuditoriaVisita
                visita={visitaAuditando}
                show={visitaAuditando !== null}
                onHide={handleOnHideAuditoria}
            />
        </>
    );
}

//TODO: Isto pode virar um span como os outros que pode ser reutilizável
export function ElementoComentario(props: { comentarios?: string | null }) {
    return utils.isNil(props.comentarios) || (props.comentarios as string).trim() === "" ? (
        <span className="pe-none">--- Nenhum comentário ---</span>
    ) : (
        <OverlayTrigger
            flip
            placement="bottom"
            trigger={["click", "focus"]}
            rootClose
            overlay={
                <Popover className="shadow">
                    <Popover.Body className="p-2">
                        {(props.comentarios as string).split("\n").map((texto?: string) =>
                            !texto ? (
                                <br key={v4()} />
                            ) : (
                                <React.Fragment key={v4()}>
                                    <span>{texto}</span>
                                    <br />
                                </React.Fragment>
                            )
                        )}
                    </Popover.Body>
                </Popover>
            }
        >
            <span className="text-info" role="button">
                <i className="bi bi-file-earmark-text-fill me-2" />
                Clique para visualizar
            </span>
        </OverlayTrigger>
    );
}

//Funções auxiliares
function useCustomButton(props: tVisita) {
    const tooltip = props.visitaPaga ? "Marcar como não paga" : "Marcar como paga";
    const children = props.visitaPaga ? (
        <i className="bi bi-slash-circle-fill text-danger" />
    ) : (
        <i className="bi bi-check-circle-fill text-success" />
    );
    return { tooltip, children };
}
