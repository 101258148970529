import { useCallback, useContext, useEffect, useState } from "react";
import { LayoutTabela } from "../layouts";
import { PartialEntity, tUser, tVendedor } from "../interfaces";
import { BotaoAdicionarDado, TabelaVendedor, OffcanvasVendedor } from "../components";
import { ContextUser } from "../contexts/ContextUser";
import useVendedor, { LIMITE_POR_BUSCA_VENDEDORES } from "../hooks/useVendedor";

export default function Vendedores() {
    //HOOKS
    const { buscar, estaBuscando, cancelar, buscaComErro } = useVendedor();

    //CONTEXTOS
    const { podeAdicionarVendedor } = useContext(ContextUser);

    //ESTADOS
    const [vendedores, setVendedores] = useState<tVendedor[]>([]);

    const [paginaAtual, setPaginaAtual] = useState(0);
    const [proximaPagina, setProximaPagina] = useState(1);
    const [campoPesquisa, setCampoPesquisa] = useState<string | undefined>(undefined);

    const [mostrarForm, setMostrarForm] = useState(false);
    const [vendedorDeletando, setVendedorDeletando] = useState<tVendedor | null>(null);
    const [vendedorEditando, setVendedorEditando] = useState<
        (tVendedor & PartialEntity<tUser, "email" | "name">) | null
    >(null);

    //EVENTOS
    const buscarVendedores = useCallback(
        async (pagina?: number) => {
            try {
                const novosVendedores = await buscar({
                    limit: LIMITE_POR_BUSCA_VENDEDORES,
                    page: pagina,
                    where: { campoPesquisa },
                });
                if (pagina === undefined) {
                    setVendedores(novosVendedores);
                    setPaginaAtual(0);
                    setProximaPagina(1);
                } else setVendedores((atuais) => [...atuais, ...novosVendedores]);
                return novosVendedores.length; // Retorna pro InfiniteScroll
            } catch (err) {
                throw err; // Necessário para avisar que houve um erro ao InfiniteScroll
            }
        },
        [buscar, campoPesquisa]
    );

    useEffect(() => {
        buscarVendedores().catch((err) => console.error(err));
        return () => cancelar();
    }, [cancelar, buscarVendedores]);

    const handleOnClickNovoVendedor = useCallback(() => setMostrarForm(true), []);

    const handleOnClickDeleteVendedor = useCallback((vend: tVendedor) => setVendedorDeletando(vend), []);

    const handleOnClickEditVendedor = useCallback(
        (vendedor: tVendedor) =>
            setVendedorEditando({ ...vendedor, name: vendedor.user.name, email: vendedor.user.email }),
        []
    );

    return (
        <>
            <LayoutTabela.Root>
                <LayoutTabela.Header>
                    <LayoutTabela.HeaderLeft />
                    <LayoutTabela.HeaderRight>
                        {podeAdicionarVendedor({}) && (
                            <BotaoAdicionarDado texto="Vendedor" onClickAdd={handleOnClickNovoVendedor} />
                        )}
                    </LayoutTabela.HeaderRight>
                </LayoutTabela.Header>
                <LayoutTabela.Body
                    buscando={estaBuscando || buscaComErro}
                    buscar={buscarVendedores}
                    paginaAtual={paginaAtual}
                    proximaPagina={proximaPagina}
                    setPaginaAtual={setPaginaAtual}
                    setProximaPagina={setProximaPagina}
                >
                    <TabelaVendedor
                        vendedores={vendedores}
                        onEdit={handleOnClickEditVendedor}
                        onDelete={handleOnClickDeleteVendedor}
                        buscar={setCampoPesquisa}
                    />
                </LayoutTabela.Body>
            </LayoutTabela.Root>

            <OffcanvasVendedor
                tipo="criar"
                mostrarForm={mostrarForm}
                onCancel={() => {
                    setMostrarForm(false);
                    cancelar();
                }}
                onTerminou={() => buscarVendedores().catch((err) => console.error(err))}
            />

            <OffcanvasVendedor
                tipo="editar"
                mostrarForm={vendedorEditando !== null}
                onCancel={() => {
                    setVendedorEditando(null);
                    cancelar();
                }}
                onTerminou={() => {
                    setVendedorEditando(null);
                    buscarVendedores().catch((err) => console.error(err));
                }}
                dados={vendedorEditando}
            />

            <OffcanvasVendedor
                tipo="deletar"
                mostrarForm={vendedorDeletando !== null}
                onCancel={() => {
                    setVendedorDeletando(null);
                    cancelar();
                }}
                onTerminou={() => {
                    setVendedorDeletando(null);
                    buscarVendedores().catch((err) => console.error(err));
                }}
                dados={vendedorDeletando}
            />
        </>
    );
}
