import { ListGroup, Overlay, Stack } from "react-bootstrap";
import FiltroSelect from "./FiltroSelect";
import { useCallback, useRef, useState } from "react";
import Botao from "../botoes/Botao";
import "./styles.css";
import useFiltros, { FilterKey } from "../../hooks/useFiltros";

export type tPossivelMes = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
export type tMesesSelecionados = { inicio?: tPossivelMes; fim?: tPossivelMes };

export type FiltroMesProps = { notActive?: boolean };
export default function FiltroMes(props: FiltroMesProps) {
    //HOOKS
    const { mesInicialSelecionado, mesFinalSelecionado, setFilter } = useFiltros();

    //ESTADOS
    const [mostrarOpcoes, setMostrarOpcoes] = useState(false);

    const divRef = useRef<HTMLAnchorElement>(null);

    //VARIAVEIS
    const todosOsMesesSelecionados = mesInicialSelecionado === 0 && mesFinalSelecionado === 11;

    const retornaTextoFiltro = useCallback((): string => {
        if (mesInicialSelecionado === undefined || mesFinalSelecionado === undefined) return "--- undefined ---";
        const auxData = new Date();

        auxData.setMonth(mesInicialSelecionado);
        const mesInicial = auxData.toLocaleDateString("pt-Br", { month: "short" });

        auxData.setMonth(mesFinalSelecionado);
        const mesFinal = auxData.toLocaleDateString("pt-Br", { month: "short" });

        return todosOsMesesSelecionados ? "Todos os meses" : `De ${mesInicial} até ${mesFinal}`;
    }, [mesInicialSelecionado, mesFinalSelecionado, todosOsMesesSelecionados]);

    //EVENTOS
    const handleOnChangeMeses = useCallback(
        (mesesSelecionados: tMesesSelecionados) => {
            setFilter(FilterKey.MES_INICIAL, mesesSelecionados?.inicio?.toString() ?? "0");
            setFilter(FilterKey.MES_FINAL, mesesSelecionados?.fim?.toString() ?? "11");
        },
        [setFilter]
    );

    const handleOnMostrarOpcoes = useCallback(() => setMostrarOpcoes(true), []);
    const handleOnFecharOpcoes = useCallback(() => setMostrarOpcoes(false), []);

    return (
        <>
            <FiltroSelect
                titulo="Mês"
                texto={retornaTextoFiltro()}
                active={(mesFinalSelecionado !== 11 || mesInicialSelecionado !== 0) && !props.notActive}
            >
                <ListGroup className="small">
                    <ListGroup.Item
                        className={`my-item-filtro ${todosOsMesesSelecionados ? "bg-light" : ""}`}
                        onClick={() => handleOnChangeMeses({ inicio: 0, fim: 11 })}
                    >
                        Todos os meses
                    </ListGroup.Item>
                    <ListGroup.Item
                        onClick={handleOnMostrarOpcoes}
                        ref={divRef}
                        className={`my-item-filtro position-relative ${!todosOsMesesSelecionados ? "bg-light" : ""}`}
                    >
                        <div className="d-flex align-items-center w-100">
                            <span>{todosOsMesesSelecionados ? "Selecionar meses" : retornaTextoFiltro()}</span>
                            <i className="bi bi-caret-right-fill position-absolute end-0 me-2 text-info" />
                        </div>
                    </ListGroup.Item>
                </ListGroup>
            </FiltroSelect>
            <Overlay
                show={mostrarOpcoes}
                placement="right-end"
                flip
                target={divRef.current}
                rootClose
                onHide={handleOnFecharOpcoes}
            >
                {({
                    placement: _placement,
                    arrowProps: _arrowProps,
                    show: _show,
                    popper: _popper,
                    hasDoneInitialMeasure: _hasDoneInitialMeasure,
                    ...overlayProps
                }) => (
                    <div {...overlayProps}>
                        <FiltroOpcoes setMesesSelecionados={handleOnChangeMeses} onHide={handleOnFecharOpcoes} />
                    </div>
                )}
            </Overlay>
        </>
    );
}

function FiltroOpcoes({
    setMesesSelecionados,
    onHide,
}: {
    setMesesSelecionados: (valor: tMesesSelecionados) => void;
    onHide: () => void;
}) {
    //ESTADOS
    const [inicio, setInicio] = useState<tPossivelMes | null>(null);
    const [sobreIndex, setSobreIndex] = useState<number | null>(null);

    //EVENTOS
    const handleOnClickMes = useCallback(
        (e: React.MouseEvent, valor: tPossivelMes) => {
            e.stopPropagation();
            if (inicio === null || valor < inicio) setInicio(valor);
            else {
                setMesesSelecionados({ inicio, fim: valor });
                setInicio(null);
                setSobreIndex(null);
                onHide();
            }
        },
        [inicio, setMesesSelecionados, onHide]
    );

    const handleOnOverMes = useCallback(
        (index: number) => {
            if (inicio !== null) setSobreIndex(index);
        },
        [inicio]
    );

    return (
        <Stack
            className="my-filtro-mes-opcoes d-flex align-items-center gap-2 bg-white ms-3 p-1 rounded shadow"
            onClick={(e) => e.stopPropagation()}
        >
            {["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dec"].map((mes, index) => {
                const acharIntervalo = inicio !== null && sobreIndex !== null;
                const botaoNoIntervaloMaior =
                    acharIntervalo && sobreIndex > inicio && index > inicio && index <= sobreIndex;
                return (
                    <Botao
                        className={`my-mes-button d-flex justify-content-center ${
                            index === inicio ? "my-mes-inicio" : botaoNoIntervaloMaior ? "my-mes-interval" : ""
                        }`}
                        key={mes}
                        onClick={(e) => handleOnClickMes(e, index as tPossivelMes)}
                        onMouseOver={() => handleOnOverMes(index)}
                    >
                        {mes}
                    </Botao>
                );
            })}
        </Stack>
    );
}
