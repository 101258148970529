import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import { Formik, FormikHelpers } from "formik";
import { useCallback, useContext, useEffect, useState } from "react";
import * as yup from "yup";
import utils from "../../utils";
import { ContextAlerta } from "../../contexts/ContextAlert";
import { v4 } from "uuid";
import { PartialEntity, TipoGasto, tVisita } from "../../interfaces";
import SelectVendedor from "../selects/SelectVendedor";
import SelectCliente from "../selects/SelectCliente";
import BotaoFechar from "../botoes/BotaoFechar";
import BotaoLabel from "../botoes/BotaoLabel";
import { OffcanvasNovoDado } from "../offcanvas/OffcanvasNovoDado";
import FormVisitaGasto, { tNovoGasto } from "./FormVisitaGasto";
import FormGroupInput, { FormGroupInputType } from "../formGroups/FormGroupInput";
import { FormGroupBoolean, FormGroupGeneric } from "..";

//TYPES
export type tNovaVisita = PartialEntity<
    tVisita,
    | "dataVisita"
    | "vendedorId"
    | "carroEmpresa"
    | "kmInicial"
    | "kmFinal"
    | "clienteId"
    | "comentarios"
    | "remoto"
    | "visitaPaga"
> & {
    gastos: tNovoGasto[];
};

const FORM_GASTO = "FORM_GASTO";

export interface IFormVisitaProps {
    formId?: string;
    onSubmit: (visita: tNovaVisita) => void | Promise<void>;
    valoresIniciais: tNovaVisita;
}

//FORM
export default function FormVisita(props: IFormVisitaProps) {
    //CONTEXTOS
    const { setPerigo } = useContext(ContextAlerta);

    //ESTADOS
    const [gastos, setGastos] = useState<tNovoGasto[]>([]);
    const [showOffcanvasAddGasto, setShowOffcanvasAddGasto] = useState(false);
    const [idxGastoEditando, setIdxGastoEditando] = useState<number | null>(null);

    //VARIAVEIS
    const { onSubmit, valoresIniciais, formId } = props;

    //EVENTOS
    useEffect(() => {
        if (gastos.length > 0) return;
        setGastos(valoresIniciais.gastos);
    }, [valoresIniciais, gastos]);

    const onSubmitFormik = useCallback(
        async (visita: tNovaVisita, helpers: FormikHelpers<tNovaVisita>) => {
            try {
                await onSubmit({ ...visita, gastos });
                helpers.setSubmitting(false);
                helpers.resetForm();
            } catch (err) {
                if (utils.blErroCancelamento(err)) return;
                setPerigo(utils.retornaMensagemErro(err));
                console.error(err);
            }
        },
        [onSubmit, setPerigo, gastos]
    );

    const handleOnClickAddGasto = useCallback(() => setShowOffcanvasAddGasto(true), []);
    const handleOnClickCancelAddGasto = useCallback(() => setShowOffcanvasAddGasto(false), []);
    const handleOnClickEditGasto = useCallback((index: number) => setIdxGastoEditando(index), []);
    const handleOnClickDeleteGasto = useCallback((index: number) => {
        setGastos((current) => current.filter((_, idx) => idx !== index));
    }, []);

    const handleOnSubmitGastos = useCallback(
        (gasto: tNovoGasto) => {
            if (idxGastoEditando === null) {
                setGastos((current) => [...current, gasto]);
                return setShowOffcanvasAddGasto(false);
            }

            setGastos((current) => current.map((g, idx) => (idx === idxGastoEditando ? gasto : g)));
            setIdxGastoEditando(null);
        },
        [idxGastoEditando]
    );

    return (
        <>
            <Formik
                validateOnChange={false}
                validationSchema={yupEsquemaVisita}
                onSubmit={onSubmitFormik}
                initialValues={valoresIniciais}
            >
                {({ handleSubmit, values, errors, setValues }) => {
                    return (
                        <Form id={formId} onSubmit={handleSubmit}>
                            <Row className="gap-3">
                                <FormGroupGeneric label="Vendedor" error={errors.vendedorId}>
                                    <SelectVendedor
                                        idSelecionado={values.vendedorId}
                                        setIdSelecionado={(id) => setValues({ ...values, vendedorId: id as string })}
                                    />
                                </FormGroupGeneric>

                                <FormGroupGeneric label="Cliente" error={errors.clienteId}>
                                    <SelectCliente
                                        setIdSelecionado={(id) => setValues({ ...values, clienteId: id as string })}
                                        idSelecionado={values.clienteId}
                                    />
                                </FormGroupGeneric>

                                <Col sm="12" className="border-top pt-2">
                                    <Row>
                                        <Col sm="12" className="mb-1">
                                            <h6>Visita remota ou presencial?</h6>
                                        </Col>
                                        <FormGroupBoolean
                                            label="Remota"
                                            value={values.remoto}
                                            setValue={(remoto) => setValues({ ...values, remoto })}
                                            error={errors.remoto}
                                        />
                                    </Row>
                                </Col>

                                {!values.remoto && (
                                    <>
                                        <Col sm="12" className="border-top pt-2">
                                            <Row>
                                                <Col sm="12" className="mb-1">
                                                    <h6>Carro</h6>
                                                </Col>
                                                <FormGroupBoolean
                                                    label="Usou o carro da empresa?"
                                                    value={values.carroEmpresa ?? false}
                                                    setValue={(carroEmpresa) => setValues({ ...values, carroEmpresa })}
                                                    error={errors.carroEmpresa}
                                                />
                                                <FormGroupInput
                                                    label="Km Inicial"
                                                    type={FormGroupInputType.NUMBER}
                                                    sm="6"
                                                    required
                                                    min={0}
                                                    value={values.kmInicial ?? 0}
                                                    setValue={(kmInicial) => setValues({ ...values, kmInicial })}
                                                    error={errors.kmInicial}
                                                />
                                                <FormGroupInput
                                                    label="Km Final"
                                                    type={FormGroupInputType.NUMBER}
                                                    sm="6"
                                                    required
                                                    min={0}
                                                    value={values.kmFinal ?? 0}
                                                    setValue={(kmFinal) => setValues({ ...values, kmFinal })}
                                                    error={errors.kmFinal}
                                                />
                                            </Row>
                                        </Col>

                                        <Col sm="12" className="border-top pt-2">
                                            <Row>
                                                <Col sm="12" className="mb-1">
                                                    <h6>Gastos</h6>
                                                </Col>
                                                <Form.Control isInvalid={!!errors.gastos} className="d-none" />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.gastos as string}
                                                </Form.Control.Feedback>
                                                <Col sm="12">
                                                    <ListaVendaGastos
                                                        gastos={gastos}
                                                        onClickAdd={handleOnClickAddGasto}
                                                        onClickDelete={handleOnClickDeleteGasto}
                                                        onClickEdit={handleOnClickEditGasto}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </>
                                )}

                                <FormGroupInput
                                    label="Data da visita"
                                    required
                                    type={FormGroupInputType.DATE}
                                    value={values.dataVisita}
                                    setValue={(dataVisita) => setValues({ ...values, dataVisita })}
                                    error={errors.dataVisita as string}
                                />
                                <FormGroupInput
                                    label="Comentários"
                                    type={FormGroupInputType.TEXT_AREA}
                                    rows={4}
                                    max={256}
                                    value={values.comentarios ?? ""}
                                    setValue={(comentarios) => setValues({ ...values, comentarios })}
                                    error={errors.comentarios}
                                />
                            </Row>
                        </Form>
                    );
                }}
            </Formik>

            <OffcanvasNovoDado.Root show={showOffcanvasAddGasto}>
                <OffcanvasNovoDado.Header>
                    <h4>Adicionar Gasto à Visita</h4>
                </OffcanvasNovoDado.Header>
                <OffcanvasNovoDado.Body
                    cancelButtonProps={{ onClick: handleOnClickCancelAddGasto }}
                    acceptButtonProps={{ type: "submit", form: FORM_GASTO }}
                >
                    <FormVisitaGasto
                        onSubmit={handleOnSubmitGastos}
                        valoresIniciais={{ tipoGasto: TipoGasto.ALIMENTACAO, nota: "", valor: 0 }}
                        formId={FORM_GASTO}
                    />
                </OffcanvasNovoDado.Body>
            </OffcanvasNovoDado.Root>

            <OffcanvasNovoDado.Root show={idxGastoEditando !== null}>
                <OffcanvasNovoDado.Header>
                    <h4>Editar Gasto da Visita</h4>
                </OffcanvasNovoDado.Header>
                <OffcanvasNovoDado.Body
                    cancelButtonProps={{ onClick: () => setIdxGastoEditando(null) }}
                    acceptButtonProps={{ type: "submit", form: FORM_GASTO, children: "Editar" }}
                >
                    {idxGastoEditando !== null && (
                        <FormVisitaGasto
                            onSubmit={handleOnSubmitGastos}
                            valoresIniciais={gastos[idxGastoEditando]}
                            formId={FORM_GASTO}
                        />
                    )}
                </OffcanvasNovoDado.Body>
            </OffcanvasNovoDado.Root>
        </>
    );
}

//FUNCOES AUXILIARES
function ListaVendaGastos(props: {
    gastos: tNovoGasto[];
    onClickAdd: () => void;
    onClickEdit: (index: number) => void;
    onClickDelete: (index: number) => void;
}) {
    const totalGasto = props.gastos.reduce((acc, gasto) => (acc += gasto.valor), 0);

    return (
        <Row className="gap-2">
            {props.gastos.map((gasto, index) => {
                return (
                    <Col sm="12" key={v4()}>
                        <Stack className="d-flex w-100 position-relative border rounded shadow-sm p-2">
                            <div className="position-absolute end-0 top-0 mt-1 me-1 z-1">
                                <BotaoFechar onClick={() => props.onClickDelete(index)} />
                            </div>
                            <BotaoLabel
                                variant="info"
                                texto={utils.returnTextTipoGasto(gasto.tipoGasto)}
                                onClick={() => props.onClickEdit(index)}
                            />
                            <div className="d-flex w-100 justify-content-between">
                                <small>Valor</small>
                                <small>{utils.retornaValorMonetario(gasto.valor, "BRL")}</small>
                            </div>
                        </Stack>
                    </Col>
                );
            })}
            <Col sm="12" className="text-secondary-dark">
                <small>Gastos totais: {utils.retornaValorMonetario(totalGasto, "BRL")}</small>
            </Col>
            <Col sm="12" className="text-end">
                <Button className="rounded-pill" size="sm" onClick={props.onClickAdd}>
                    Adicionar
                </Button>
            </Col>
        </Row>
    );
}

export const yupEsquemaVisita: yup.ObjectSchema<tNovaVisita> = yup.object({
    vendedorId: yup.string().required("Este campo é necessário."),
    clienteId: yup.string().required("Este campo é necessário."),
    dataVisita: yup.date().required("Este campo é necessário."),
    carroEmpresa: yup.boolean().required("Este campo é necessário").nullable(),
    kmInicial: yup.number().required("Este campo é necessário").nullable(),
    kmFinal: yup.number().required("Este campo é necessário").nullable(),
    gastos: yup.array().required("Este campo é necessário."),
    comentarios: yup.string().required("Este campo é necessário.").nullable(),
    remoto: yup.boolean().required("Este campo é necessário."),
    visitaPaga: yup.boolean().required("Este campo é necessário."),
});
