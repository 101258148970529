import { DashboardMetric } from "../../interfaces";
import EvolucaoDashboard from "./EvolucaoDashboard";
import { EvolucaoTotalDashboardProps } from "./types";

export default function EvolucaoTotalDashboard(props: EvolucaoTotalDashboardProps) {
    return (
        <EvolucaoDashboard
            currentColor="blue"
            monetario={props.metrica !== DashboardMetric.VISITA}
            title={"Evolução de " + props.metrica}
            dados={{
                anoAtual: props.anoAtual.map(({ valor, mesAno }) => ({
                    valor,
                    mesAno,
                })),
                anoAnterior: props.anoAnterior.map(({ valor, mesAno }) => ({
                    valor,
                    mesAno,
                })),
            }}
        />
    );
}
