import { Col, Form, Row } from "react-bootstrap";
import { Formik, FormikHelpers } from "formik";
import { useCallback, useContext, useState } from "react";
import * as yup from "yup";
import utils from "../../utils";
import { ContextAlerta } from "../../contexts/ContextAlert";
import { PartialEntity, tMeta, tTipoMeta, tValorTipoMeta } from "../../interfaces";
import FormGroupInput, { FormGroupInputType } from "../formGroups/FormGroupInput";
import FormGroupBoolean from "../formGroups/FormGroupBoolean";

//TYPES
export type tNovoTipoMeta = PartialEntity<tValorTipoMeta, "tipoMeta" | "valor">;
export type tNovaMeta = PartialEntity<tMeta, "dataInicioMeta" | "dataFimMeta" | "vendedorId"> & {
    tiposMeta: tNovoTipoMeta[];
};

export interface IFormMetaProps {
    formId?: string;
    onSubmit: (metas: tNovaMeta[]) => void | Promise<void>;
    valoresIniciais: tNovaMeta;
    options?: { aceitarMetasMultiplas?: boolean };
}

//FORM
export default function FormMeta(props: IFormMetaProps) {
    //CONTEXTOS
    const { setPerigo, setAviso, setConfirmar } = useContext(ContextAlerta);

    //STATES
    const [multiplasMetas, setMultiplasMetas] = useState(false);

    //VARIAVEIS
    const { onSubmit, valoresIniciais, formId } = props;

    //EVENTOS
    const onSubmitFormik = useCallback(
        async (meta: tNovaMeta, helpers: FormikHelpers<tNovaMeta>) => {
            let metasToSubmit = [meta];
            if (multiplasMetas) {
                const mesInicial = meta.dataInicioMeta.getMonth();
                const mesFinal = meta.dataFimMeta.getMonth();
                const anoInicial = meta.dataInicioMeta.getFullYear();
                const anoFinal = meta.dataFimMeta.getFullYear();

                if (anoInicial !== anoFinal) return setAviso("O período das metas deve ser no mesmo ano.");
                if (mesInicial === mesFinal)
                    return setAviso("Você precisa atribuir um período maior do que um único mês.");

                const novosPeriodos: { dataInicial: Date; dataFinal: Date }[] = [];
                for (let mes = mesInicial; mes <= mesFinal; mes++) {
                    let dataInicial = new Date(anoInicial, mes, 1, 0, 0, 0, 0);

                    const mesDataFinal = mes === 11 ? 1 : mes + 1;
                    const anoDataFinal = mes === 11 ? anoInicial + 1 : anoInicial;
                    let dataFinal = new Date(anoDataFinal, mesDataFinal, 1, 23, 59, 59, 999);
                    dataFinal.setDate(dataFinal.getDate() - 1);

                    if (mes === mesInicial) dataInicial = meta.dataInicioMeta;
                    if (mes === mesFinal) dataFinal = meta.dataFimMeta;

                    novosPeriodos.push({ dataInicial, dataFinal });
                }

                metasToSubmit = novosPeriodos.map(({ dataInicial, dataFinal }) => ({
                    ...meta,
                    dataInicioMeta: dataInicial,
                    dataFimMeta: dataFinal,
                }));
            }

            if (metasToSubmit.length > 1) {
                const confirma = await setConfirmar(<ConfirmarMultiplasMetas metas={metasToSubmit} />);
                if (!confirma) return;
            }

            try {
                await onSubmit(metasToSubmit);
                helpers.setSubmitting(false);
                helpers.resetForm();
            } catch (err) {
                if (utils.blErroCancelamento(err)) return;
                setPerigo(utils.retornaMensagemErro(err));
                console.error(err);
            }
        },
        [onSubmit, setPerigo, multiplasMetas, setAviso, setConfirmar]
    );

    return (
        <>
            <Formik
                validateOnChange={false}
                validationSchema={yupEsquemaMeta}
                onSubmit={onSubmitFormik}
                initialValues={valoresIniciais}
            >
                {({ handleSubmit, values, errors, setValues }) => {
                    const errorMetaPorTipo = (tipo: tTipoMeta) =>
                        (errors?.tiposMeta as unknown as tNovoTipoMeta[])?.find((tm) => tm.tipoMeta === tipo)?.valor;
                    const valorMetaPorTipo = (tipo: tTipoMeta) =>
                        values.tiposMeta.find((tm) => tm.tipoMeta === tipo)?.valor;
                    const setValuesMetaPorTipo = (tipo: tTipoMeta, novoValor: number) => {
                        const tipoMeta = valorMetaPorTipo(tipo);
                        if (!tipoMeta) {
                            const novoTipoMeta: tNovoTipoMeta = { tipoMeta: tipo, valor: novoValor };
                            return setValues({ ...values, tiposMeta: [...values.tiposMeta, novoTipoMeta] });
                        }

                        const tiposMetaAtualizado: tNovoTipoMeta[] = values.tiposMeta.map((tm) => {
                            if (tm.tipoMeta !== tipo) return tm;
                            else return { ...tm, valor: novoValor };
                        });
                        return setValues({ ...values, tiposMeta: tiposMetaAtualizado });
                    };
                    return (
                        <Form id={formId} onSubmit={handleSubmit}>
                            <Row className="row-gap-1">
                                <Col sm="12">
                                    <h6 className="mb-0">Tipos de metas</h6>
                                </Col>
                                {utils.retornaArrayTipoMeta().map((tipoMeta) => (
                                    <FormGroupInput
                                        key={tipoMeta}
                                        label={"Meta de " + utils.retornaTextoTipoMeta(tipoMeta)}
                                        type={
                                            tipoMeta === "VISITA"
                                                ? FormGroupInputType.NUMBER
                                                : FormGroupInputType.MONETARY
                                        }
                                        tipoMoeda="BRL"
                                        value={valorMetaPorTipo(tipoMeta) ?? 0}
                                        setValue={(valor) => setValuesMetaPorTipo(tipoMeta, valor)}
                                        error={errorMetaPorTipo(tipoMeta) as unknown as string}
                                    />
                                ))}

                                <Col sm="12" className="border-top pt-2 mt-2">
                                    <Row className="row-gap-1">
                                        <Col sm="12">
                                            <h6>Período da meta</h6>
                                        </Col>

                                        <FormGroupInput
                                            label="Data inicial"
                                            type={FormGroupInputType.DATE}
                                            value={values.dataInicioMeta}
                                            setValue={(dataInicioMeta) => setValues({ ...values, dataInicioMeta })}
                                            error={errors.dataInicioMeta as string}
                                        />
                                        <FormGroupInput
                                            label="Data final"
                                            useLastTime
                                            type={FormGroupInputType.DATE}
                                            value={values.dataFimMeta}
                                            setValue={(dataFimMeta) => setValues({ ...values, dataFimMeta })}
                                            error={errors.dataFimMeta as string}
                                        />

                                        {props.options?.aceitarMetasMultiplas && (
                                            <FormGroupBoolean
                                                label="Criar múltiplas metas por meses no período?"
                                                value={multiplasMetas}
                                                setValue={setMultiplasMetas}
                                            />
                                        )}
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
}

export function ConfirmarMultiplasMetas(props: { metas: tNovaMeta[] }) {
    return (
        <p>
            <span>Os valores de metas selecionados serão replicados para os seguintes períodos:</span>
            <ul className="mt-2">
                {props.metas.map((meta) => (
                    <li className="font-monospace">
                        {utils.retornaDataDMA(meta.dataInicioMeta)} - {utils.retornaDataDMA(meta.dataFimMeta)}
                    </li>
                ))}
            </ul>
        </p>
    );
}

export const yupEsquemaMeta: yup.ObjectSchema<tNovaMeta> = yup.object({
    vendedorId: yup.string().required("Este campo é necessário."),
    dataInicioMeta: yup.date().required("Este campo é necessário."),
    dataFimMeta: yup.date().required("Este campo é necessário."),
    tiposMeta: yup
        .array(
            yup.object({
                tipoMeta: yup
                    .mixed<tTipoMeta>()
                    .oneOf(["FATURAMENTO", "MARGEM", "MARKUP_BRUTO", "MARKUP_LIQUIDO", "VALOR_VENDA", "VISITA"])
                    .required("Este campo é encessário."),
                valor: yup.number().required("Este campo é necessário."),
            })
        )
        .required("Este campo é necessário."),
});
