import CardDashboard from "./CardDashboard";
import { TotalTicketDashboardCardProps } from "./types";
import utils from "../../utils";
import { DashboardMetric } from "../../interfaces";

export default function TotalTicketDashboardCard(props: TotalTicketDashboardCardProps) {
    const textTicketTotal = utils.retornaValorMonetario(props.valoTotalAtual, "BRL");
    const porcentTicketAtualVsAnterior =
        ((props.valoTotalAtual - props.valorTotalAnterior) * 100) / props.valorTotalAnterior;

    return (
        <CardDashboard
            disabledText={
                props.metrica === DashboardMetric.VISITA
                    ? `'${DashboardMetric.VISITA}' não é uma métrica válida para este card`
                    : undefined
            }
            label={`Ticket Médio (${props.metrica})`}
            valor={textTicketTotal}
            icon="graph-up"
            color="green"
            porcent={{
                valor: porcentTicketAtualVsAnterior,
                miniLabel: "vs. ano anterior",
            }}
        />
    );
}
