import { useCallback, useContext, useEffect, useState } from "react";
import { BotaoAdicionarDado, FiltroTiposProdServ, OffcanvasServico, TabelaServico } from "../components";
import { LayoutTabela } from "../layouts";
import { tServico } from "../interfaces";
import { ContextUser } from "../contexts/ContextUser";
import useServico, { LIMITE_POR_BUSCA_SERVICOS } from "../hooks/useServico";
import useFiltros from "../hooks/useFiltros";

export default function Servicos() {
    //HOOKS
    const { buscar, estaBuscando, cancelar, buscaComErro } = useServico();
    const { tipoProdServIdSelecionado } = useFiltros();

    //CONTEXTOS
    const { podeAdicionarServico } = useContext(ContextUser);

    //ESTADOS
    const [servicos, setServicos] = useState<tServico[]>([]);

    const [paginaAtual, setPaginaAtual] = useState(0);
    const [proximaPagina, setProximaPagina] = useState(1);
    const [campoPesquisa, setCampoPesquisa] = useState<string | undefined>(undefined);

    const [servicoEditando, setServicoEditando] = useState<tServico | null>(null);
    const [servicoDeletando, setServicoDeletando] = useState<tServico | null>(null);
    const [mostrarAdicionar, setMostrarAdicionar] = useState(false);

    //EVENTOS
    const buscarServicos = useCallback(
        async (pagina?: number) => {
            try {
                const novosServicos = await buscar({
                    limit: LIMITE_POR_BUSCA_SERVICOS,
                    page: pagina,
                    where: campoPesquisa ? { campoPesquisa } : { tipoProdutoServicoId: tipoProdServIdSelecionado },
                });
                if (pagina === undefined) {
                    setServicos(novosServicos);
                    setPaginaAtual(0);
                    setProximaPagina(1);
                } else setServicos((atuais) => [...atuais, ...novosServicos]);
                return novosServicos.length; // Retorna pro InfiniteScroll
            } catch (err) {
                throw err; // Necessário para avisar que houve um erro ao InfiniteScroll
            }
        },
        [buscar, tipoProdServIdSelecionado, campoPesquisa]
    );

    useEffect(() => {
        buscarServicos().catch((err) => console.error(err));
        return () => cancelar();
    }, [cancelar, buscarServicos, tipoProdServIdSelecionado]);

    const handleOnClickAdd = useCallback(() => setMostrarAdicionar(true), []);
    const handleOnClickEdit = useCallback((servico: tServico) => setServicoEditando(servico), []);
    const handleOnClickDelete = useCallback((servico: tServico) => setServicoDeletando(servico), []);

    return (
        <>
            <LayoutTabela.Root>
                <LayoutTabela.Header>
                    <LayoutTabela.HeaderLeft>
                        <FiltroTiposProdServ notActive={!!campoPesquisa} />
                    </LayoutTabela.HeaderLeft>
                    <LayoutTabela.HeaderRight>
                        {podeAdicionarServico({}) && (
                            <BotaoAdicionarDado texto="Serviço" onClickAdd={handleOnClickAdd} />
                        )}
                    </LayoutTabela.HeaderRight>
                </LayoutTabela.Header>
                <LayoutTabela.Body
                    buscando={estaBuscando || buscaComErro}
                    paginaAtual={paginaAtual}
                    proximaPagina={proximaPagina}
                    setPaginaAtual={setPaginaAtual}
                    setProximaPagina={setProximaPagina}
                    buscar={buscarServicos}
                >
                    <TabelaServico
                        servicos={servicos}
                        onEdit={handleOnClickEdit}
                        onDelete={handleOnClickDelete}
                        buscar={setCampoPesquisa}
                    />
                </LayoutTabela.Body>
            </LayoutTabela.Root>

            <OffcanvasServico
                tipo="criar"
                mostrarForm={mostrarAdicionar}
                onCancel={() => {
                    setMostrarAdicionar(false);
                    cancelar();
                }}
                onTerminou={() => buscarServicos().catch((err) => console.error(err))}
            />
            <OffcanvasServico
                tipo="editar"
                mostrarForm={servicoEditando !== null}
                onCancel={() => {
                    setServicoEditando(null);
                    cancelar();
                }}
                onTerminou={() => {
                    setServicoEditando(null);
                    buscarServicos().catch((err) => console.error(err));
                }}
                dados={servicoEditando}
            />
            <OffcanvasServico
                tipo="deletar"
                mostrarForm={servicoDeletando !== null}
                onCancel={() => {
                    setServicoDeletando(null);
                    cancelar();
                }}
                onTerminou={() => {
                    setServicoDeletando(null);
                    buscarServicos().catch((err) => console.error(err));
                }}
                dados={servicoDeletando}
            />
        </>
    );
}
