import { ListGroup } from "react-bootstrap";
import FiltroSelect from "./FiltroSelect";
import { useState } from "react";
import useFiltros, { FilterKey } from "../../hooks/useFiltros";

export type FiltroAnosProps = { notActive?: boolean };
export default function FiltroAnos(props: FiltroAnosProps) {
    //HOOKS
    const { anoSelecionado, setFilter } = useFiltros();

    //ESTADOS
    const [pesquisa, setPesquisa] = useState<string | undefined>(undefined);

    //VARIAVEIS
    const anosPesquisados =
        pesquisa === undefined
            ? filtros
            : filtros.filter((filtro) => filtro.toLowerCase().includes(pesquisa.toLowerCase()));

    return (
        <FiltroSelect
            titulo="Ano"
            texto={anoSelecionado ?? undefined}
            active={!!anoSelecionado && !props.notActive}
            onSearch={setPesquisa}
        >
            <ListGroup className="small">
                {anosPesquisados.map((filtro) => (
                    <ListGroup.Item
                        className={`text-center my-item-filtro ${anoSelecionado === filtro ? "bg-light" : ""}`}
                        key={filtro}
                        onClick={() => setFilter(FilterKey.ANO, filtro)}
                    >
                        {filtro}
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </FiltroSelect>
    );
}

const dataAtual = new Date();
const ANO_INICIAL = 2024;
const ANO_ATUAL = dataAtual.getFullYear();
const filtros = new Array(ANO_ATUAL - ANO_INICIAL + 2).fill(0).map((_, index) => (ANO_INICIAL + index).toString());
