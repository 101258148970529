import { Col, Form, Row } from "react-bootstrap";
import { Formik, FormikHelpers } from "formik";
import { useCallback, useContext } from "react";
import { PartialEntity, tServico, tTipoMoeda } from "../../interfaces";
import * as yup from "yup";
import utils from "../../utils";
import { ContextAlerta } from "../../contexts/ContextAlert";
import SelectTipoProdutoServico from "../selects/SelectTipoProdutoServico";
import FormGroupInput, { FormGroupInputType } from "../formGroups/FormGroupInput";
import FormGroupSelect from "../formGroups/FormGroupSelect";
import FormGroupGeneric from "../formGroups/FormGroupGeneric";

//TYPE
export type tNovoServico = PartialEntity<
    tServico,
    "name" | "observacoes" | "tipoMoeda" | "tipoProdutoServicoId" | "valorCustoHora"
>;

interface IFormServicoProps {
    formId?: string;
    onSubmit: (servico: tNovoServico) => void | Promise<void>;
    valoresIniciais: tNovoServico;
}

//FORM
export default function FormServico(props: IFormServicoProps) {
    //CONTEXTOS
    const { setPerigo } = useContext(ContextAlerta);

    //VARIAVEIS
    const { onSubmit, valoresIniciais, formId } = props;

    //EVENTOS
    const onSubmitFormik = useCallback(
        async (servico: tNovoServico, helpers: FormikHelpers<tNovoServico>) => {
            try {
                await onSubmit(servico);
                helpers.setSubmitting(false);
                helpers.resetForm();
            } catch (err) {
                if (utils.blErroCancelamento(err)) return;
                setPerigo(utils.retornaMensagemErro(err));
                console.error(err);
            }
        },
        [onSubmit, setPerigo]
    );

    return (
        <Formik
            validateOnChange={false}
            validationSchema={yupEsquemaServico}
            onSubmit={onSubmitFormik}
            initialValues={valoresIniciais}
        >
            {({ handleSubmit, values, errors, setValues }) => {
                return (
                    <Form id={formId} onSubmit={handleSubmit}>
                        <Row className="gap-3">
                            <FormGroupInput
                                label="Nome do serviço"
                                type={FormGroupInputType.TEXT}
                                value={values.name}
                                setValue={(name) => setValues({ ...values, name })}
                                error={errors.name}
                            />

                            <Col sm="12">
                                <Row>
                                    <FormGroupSelect
                                        label="Moeda"
                                        value={values.tipoMoeda}
                                        setValue={(tipoMoeda) => setValues({ ...values, tipoMoeda })}
                                        options={utils.retornaArrayTipoMoeda()}
                                        optionTextFn={utils.retornaTextoTipoMoeda}
                                        error={errors.tipoMoeda}
                                    />
                                    <FormGroupInput
                                        label="Custo por hora"
                                        sm="7"
                                        type={FormGroupInputType.MONETARY}
                                        tipoMoeda={values.tipoMoeda}
                                        value={values.valorCustoHora}
                                        setValue={(valorCustoHora) => setValues({ ...values, valorCustoHora })}
                                        error={errors.valorCustoHora}
                                    />
                                </Row>
                            </Col>

                            <FormGroupInput
                                label="Observações"
                                type={FormGroupInputType.TEXT_AREA}
                                rows={4}
                                max={256}
                                value={values.observacoes}
                                setValue={(observacoes) => setValues({ ...values, observacoes })}
                                error={errors.observacoes}
                            />
                            <FormGroupGeneric label="Tipo de Produto/Serviço" error={errors.tipoProdutoServicoId}>
                                <SelectTipoProdutoServico
                                    idSelecionado={values.tipoProdutoServicoId}
                                    setIdSelecionado={(id) => setValues({ ...values, tipoProdutoServicoId: id ?? "" })}
                                />
                            </FormGroupGeneric>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
}

export const yupEsquemaServico: yup.ObjectSchema<tNovoServico> = yup.object({
    name: yup.string().required("Campo necessário.").max(256, "Deve ter no máximo 256 carecteres."),
    valorCustoHora: yup
        .number()
        .defined()
        .required("Campo necessário.")
        .min(0.01, "O valor mínimo deve ser maior que 0.01"),
    tipoMoeda: yup.mixed<tTipoMoeda>().oneOf(["BRL", "USD"]).required("Campo necessário"),
    observacoes: yup.string().required("Adicione uma observação."),
    tipoProdutoServicoId: yup.string().required("Selecione um tipo."),
});
