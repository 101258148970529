import { Col, Row } from "react-bootstrap";
import LayoutDashboard from "../layouts/LayoutDashboard";
import {
    CardRankCategoriasPorVenda,
    CardTiposProdServ,
    CardTopClientes,
    CardTopVendedores,
    FiltroAnos,
    FiltroVendedores,
    FiltroEstadosVenda,
    DropdownMetricaDashboard,
} from "../components";
import { useEffect, useState } from "react";
import { DashboardMetric } from "../interfaces";
import FiltroMes from "../components/filtros/FiltroMes";
import useDashboard, { tDadosDashboard } from "../hooks/useDashboard";
import utils from "../utils";
import useFiltros from "../hooks/useFiltros";
import MetaDashboardCard from "../components/cards/MetaDashboardCard";
import TotalValueDashboardCard from "../components/cards/TotalValueDashboardCard";
import TotalTicketDashboardCard from "../components/cards/TotalTicketDashboardCard";
import MargemDashboardCard from "../components/cards/MargemDashboardCard";
import EvolucaoTotalDashboard from "../components/cards/EvolucaoTotalDashboard";
import EvolucaoTicketDashboard from "../components/cards/EvolucaoTicketDashboard";

export default function Dashboard() {
    //HOOKS
    const { vendedorIdSelecionado, dataInicialSelecionada, dataFinalSelecionada, estadoVendaSelecionado } = useFiltros();
    const { buscaComErro, buscar, estaBuscando, cancelar } = useDashboard();

    //ESTADOS
    const [dados, setDados] = useState<null | tDadosDashboard>(null);
    const [metricaAtual, setMetricaAtual] = useState<DashboardMetric>(DashboardMetric.FATURAMENTO);

    //EVENTOS
    useEffect(() => {
        if (!dataInicialSelecionada || !dataFinalSelecionada) return;

        buscar({
            dataInicio: dataInicialSelecionada,
            dataFim: dataFinalSelecionada,
            estadoVenda: estadoVendaSelecionado,
            vendedorId: vendedorIdSelecionado,
            metrica: utils.returnDashboardMetricKey(metricaAtual),
        })
            .then((dados) => setDados(dados))
            .catch((err) => console.error(err));

        return () => cancelar();
    }, [
        buscar,
        cancelar,
        dataInicialSelecionada,
        dataFinalSelecionada,
        vendedorIdSelecionado,
        estadoVendaSelecionado,
        metricaAtual,
    ]);

    return (
        <>
            <LayoutDashboard.Root>
                <LayoutDashboard.Header>
                    <>
                        <FiltroAnos />
                        <FiltroMes />
                        <FiltroEstadosVenda />
                        <FiltroVendedores />
                    </>
                </LayoutDashboard.Header>
                <LayoutDashboard.BodyRoot>
                    <Col sm="12" className="mb-3">
                        <DropdownMetricaDashboard metricaAtual={metricaAtual} setMetricaAtual={setMetricaAtual} />
                    </Col>
                    <LayoutDashboard.Body carregando={estaBuscando || buscaComErro}>
                        {dados && (
                            <Col md="12">
                                <Row>
                                    <Col md="6" lg="3" className="mb-3">
                                        <TotalValueDashboardCard
                                            metrica={metricaAtual}
                                            valoTotalAtual={dados.valoresTotais.valorTotalAtual}
                                            valorTotalAnterior={dados.valoresTotais.valorTotalAnterior}
                                        />
                                    </Col>
                                    <Col md="6" lg="3" className="mb-3">
                                        <TotalTicketDashboardCard
                                            metrica={metricaAtual}
                                            valoTotalAtual={dados.valoresTotais.avgValorTotalAtual}
                                            valorTotalAnterior={dados.valoresTotais.avgValorTotalAnterior}
                                        />
                                    </Col>
                                    <Col md="6" lg="3" className="mb-3">
                                        <MetaDashboardCard
                                            metrica={metricaAtual}
                                            metaAtual={dados.meta.valorMetaAtual}
                                            metaAtingidaAtual={dados.meta.valorMetaAtingidaAtual}
                                            metaAtingidaAnterior={dados.meta.valorMetaAtingidaAnterior}
                                        />
                                    </Col>
                                    <Col md="6" lg="3" className="mb-3">
                                        <MargemDashboardCard
                                            metrica={metricaAtual}
                                            margemContribuicaoAtual={dados.margemContribuicao.margemContribuicaoAtual}
                                            margemContribuicaoAnterior={
                                                dados.margemContribuicao.margemContribuicaoAnterior
                                            }
                                        />
                                    </Col>

                                    <Col md="12" lg="5" className="mb-3">
                                        <EvolucaoTotalDashboard
                                            metrica={metricaAtual}
                                            anoAtual={dados.evolucoes.evolucaoAnoAtual}
                                            anoAnterior={dados.evolucoes.evolucaoAnoAnterior}
                                        />
                                    </Col>

                                    <Col md="12" lg="5" className="mb-3">
                                        <EvolucaoTicketDashboard
                                            metrica={metricaAtual}
                                            anoAtual={dados.evolucoes.evolucaoAnoAtual}
                                            anoAnterior={dados.evolucoes.evolucaoAnoAnterior}
                                        />
                                    </Col>

                                    <Col md="12" lg="2" className="mb-3">
                                        <CardTiposProdServ
                                            metrica={metricaAtual}
                                            dados={dados.vendasPorTipoProdServ}
                                            disabledText={
                                                metricaAtual === DashboardMetric.VISITA
                                                    ? `'${DashboardMetric.VISITA}' não é uma métrica válida para este card`
                                                    : undefined
                                            }
                                        />
                                    </Col>

                                    <Col md="12" lg="4" className="mb-3">
                                        <CardRankCategoriasPorVenda
                                            metrica={metricaAtual}
                                            dados={dados.rankCategorias}
                                            disabledText={
                                                metricaAtual === DashboardMetric.VISITA
                                                    ? `'${DashboardMetric.VISITA}' não é uma métrica válida para este card`
                                                    : undefined
                                            }
                                        />
                                    </Col>

                                    <Col md="12" lg="4" className="mb-3">
                                        <CardTopClientes metrica={metricaAtual} dados={dados.rankClientes} />
                                    </Col>

                                    {!vendedorIdSelecionado && (
                                        <Col md="12" lg="4" className="mb-3">
                                            <CardTopVendedores metrica={metricaAtual} dados={dados.rankVendedores} />
                                        </Col>
                                    )}
                                </Row>
                            </Col>
                        )}
                    </LayoutDashboard.Body>
                </LayoutDashboard.BodyRoot>
            </LayoutDashboard.Root>
        </>
    );
}
