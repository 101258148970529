import { useCallback, useContext, useEffect, useState } from "react";
import {
    BotaoAdicionarDado,
    FiltroAnos,
    FiltroMes,
    FiltroVendedores,
    FiltroVisitaPaga,
    OffcanvasVisita,
    TabelaVisita,
} from "../components";
import { LayoutTabela } from "../layouts";
import { tVisita } from "../interfaces";
import { ContextUser } from "../contexts/ContextUser";
import utils from "../utils";
import useVisita, { LIMITE_POR_BUSCA_VISITAS } from "../hooks/useVisita";
import useFiltros from "../hooks/useFiltros";
import { ContextAlerta } from "../contexts/ContextAlert";

export default function Visitas() {
    //HOOKS
    const { buscar, estaBuscando, cancelar, buscaComErro, editar, editarVarias } = useVisita();
    const { vendedorIdSelecionado, dataInicialSelecionada, dataFinalSelecionada, visitaPagaSelecionado } = useFiltros();

    //CONTEXTOS
    const { carregandoUsuario, podeAdicionarVisita, usuario, podeVisualizarVisita } = useContext(ContextUser);
    const { setConfirmar, setProcessando } = useContext(ContextAlerta);

    //ESTADOS
    const [visitas, setVisitas] = useState<tVisita[]>([]);

    const [paginaAtual, setPaginaAtual] = useState(0);
    const [proximaPagina, setProximaPagina] = useState(1);
    const [campoPesquisa, setCampoPesquisa] = useState<string | undefined>(undefined);

    const [visitaEditando, setVisitaEditando] = useState<tVisita | null>(null);
    const [visitaDeletando, setVisitaDeletando] = useState<tVisita | null>(null);
    const [mostrarOffcanvasAdicionar, setMostrarOffcanvasAdicionar] = useState(false);

    //EVENTOS
    const buscarVisita = useCallback(
        async (pagina?: number) => {
            try {
                const whereComPesquisa = {
                    campoPesquisa,
                    vendedorId: podeVisualizarVisita({}) ? undefined : vendedorIdSelecionado,
                };
                const where = {
                    vendedorId: vendedorIdSelecionado,
                    dataInicio: dataInicialSelecionada,
                    dataFim: dataFinalSelecionada,
                    visitaPaga: visitaPagaSelecionado,
                    campoPesquisa,
                };
                if (!podeVisualizarVisita(where) || !podeVisualizarVisita(whereComPesquisa)) return 0;

                const novasVisitas = await buscar({
                    limit: LIMITE_POR_BUSCA_VISITAS,
                    page: pagina,
                    where: campoPesquisa ? whereComPesquisa : where,
                    sortBy: "dataVisita",
                    sortType: "asc",
                });
                if (pagina === undefined) {
                    setVisitas(novasVisitas);
                    setPaginaAtual(0);
                    setProximaPagina(1);
                } else setVisitas((atuais) => [...atuais, ...novasVisitas]);
                return novasVisitas.length; // Retorna pro InfiniteScroll
            } catch (err) {
                throw err; // Necessário para avisar que houve um erro ao InfiniteScroll
            }
        },
        [
            buscar,
            vendedorIdSelecionado,
            dataInicialSelecionada,
            dataFinalSelecionada,
            podeVisualizarVisita,
            campoPesquisa,
            visitaPagaSelecionado,
        ]
    );

    useEffect(() => {
        buscarVisita().catch((err) => console.error(err));
        return () => cancelar();
    }, [cancelar, buscarVisita]);

    const handleOnClickAdd = useCallback(() => setMostrarOffcanvasAdicionar(true), []);
    const handleOnClickEdit = useCallback((visita: tVisita) => setVisitaEditando(visita), []);
    const handleOnClickDelete = useCallback((visita: tVisita) => setVisitaDeletando(visita), []);
    const handleOnChangePaga = useCallback(
        async (visita: tVisita, visitaPaga: boolean) => {
            const confirm = await setConfirmar(
                "Após marcar como paga esta visita, não se poderá reverter este estado."
            );
            if (!confirm) return;

            try {
                setProcessando(true);
                await editar({ id: visita.id, ...utils.retornaNovaVisitaDeVisita(visita), visitaPaga });
                buscarVisita().catch((err) => console.error(err));
            } catch (err) {
                console.error(err);
            } finally {
                setProcessando(false);
            }
        },
        [editar, buscarVisita, setProcessando, setConfirmar]
    );

    return (
        <>
            <LayoutTabela.Root>
                <LayoutTabela.Header>
                    <LayoutTabela.HeaderLeft>
                        <FiltroAnos notActive={!!campoPesquisa}/>
                        <FiltroMes notActive={!!campoPesquisa}/>
                        <FiltroVisitaPaga notActive={!!campoPesquisa}/>
                        <FiltroVendedores />
                    </LayoutTabela.HeaderLeft>
                    <LayoutTabela.HeaderRight>
                        {podeAdicionarVisita({ vendedorId: usuario?.vendedorUser?.id }) && (
                            <BotaoAdicionarDado texto="Visita" onClickAdd={handleOnClickAdd} />
                        )}
                    </LayoutTabela.HeaderRight>
                </LayoutTabela.Header>
                <LayoutTabela.Body
                    buscando={estaBuscando || carregandoUsuario || buscaComErro}
                    paginaAtual={paginaAtual}
                    proximaPagina={proximaPagina}
                    setPaginaAtual={setPaginaAtual}
                    setProximaPagina={setProximaPagina}
                    buscar={buscarVisita}
                >
                    <TabelaVisita
                        visitas={visitas}
                        onEdit={handleOnClickEdit}
                        onDelete={handleOnClickDelete}
                        buscar={setCampoPesquisa}
                        onChangePaga={handleOnChangePaga}
                        propsDropdownGastoTotal={{
                            onEditarVarias: editarVarias,
                            onEditou: () => buscarVisita().catch((err) => console.error(err)),
                        }}
                    />
                </LayoutTabela.Body>
            </LayoutTabela.Root>

            <OffcanvasVisita
                tipo="criar"
                mostrarForm={mostrarOffcanvasAdicionar}
                onCancel={() => {
                    setMostrarOffcanvasAdicionar(false);
                    cancelar();
                }}
                onTerminou={() => buscarVisita().catch((err) => console.error(err))}
            />

            <OffcanvasVisita
                tipo="editar"
                mostrarForm={visitaEditando !== null}
                onCancel={() => {
                    setVisitaEditando(null);
                    cancelar();
                }}
                onTerminou={() => {
                    setVisitaEditando(null);
                    buscarVisita().catch((err) => console.error(err));
                }}
                dados={visitaEditando}
            />

            <OffcanvasVisita
                tipo="deletar"
                mostrarForm={visitaDeletando !== null}
                onCancel={() => {
                    setVisitaDeletando(null);
                    cancelar();
                }}
                onTerminou={() => {
                    setVisitaDeletando(null);
                    buscarVisita().catch((err) => console.error(err));
                }}
                dados={visitaDeletando}
            />
        </>
    );
}
