import { useRef } from "react";
import Dropdown from "./dropdown";
import { DashboardMetric } from "../../interfaces";
import utils from "../../utils";
import Botao from "../botoes/Botao";
import { Stack } from "react-bootstrap";

export interface DropdownMetricaDashboardProps {
    metricaAtual: DashboardMetric;
    setMetricaAtual: React.Dispatch<React.SetStateAction<DashboardMetric>>;
}

export default function DropdownMetricaDashboard({ metricaAtual, setMetricaAtual }: DropdownMetricaDashboardProps) {
    //REFS
    const itemRef = useRef<HTMLDivElement>(null);

    return (
        <Dropdown.Root onOpenMenu={() => console.log(itemRef.current)}>
            <Dropdown.Item ref={itemRef}>
                Métrica atual:
                <span className="text-primary fw-bold ms-1">{metricaAtual}</span>
            </Dropdown.Item>
            <Dropdown.Menu placement="bottom-start" target={itemRef.current} rootClose>
                <Stack className="w-100">
                    {utils.returnDashboardMetrics().map((metrica) => (
                        <Botao key={metrica} onClick={() => setMetricaAtual(metrica)}>
                            {metrica}
                        </Botao>
                    ))}
                </Stack>
            </Dropdown.Menu>
        </Dropdown.Root>
    );
}
