import { DashboardMetric } from "../../interfaces";
import EvolucaoDashboard from "./EvolucaoDashboard";
import { EvolucaoTicketDashboardProps } from "./types";

export default function EvolucaoTicketDashboard(props: EvolucaoTicketDashboardProps) {
    return (
        <EvolucaoDashboard
            monetario={props.metrica !== DashboardMetric.VISITA}
            disabledText={
                props.metrica === DashboardMetric.VISITA
                    ? `'${DashboardMetric.VISITA}' não é uma métrica válida para este card`
                    : undefined
            }
            title={"Evolução de Ticket Médio (" + props.metrica + ")"}
            dados={{
                anoAtual: props.anoAtual.map(({ valorTicketMedio, mesAno }) => ({
                    valor: valorTicketMedio,
                    mesAno,
                })),
                anoAnterior: props.anoAnterior.map(({ valorTicketMedio, mesAno }) => ({
                    valor: valorTicketMedio,
                    mesAno,
                })),
            }}
        />
    );
}
