import { useCallback, useContext, useEffect, useState } from "react";
import { LayoutTabela } from "../layouts";
import { tFornecedor } from "../interfaces";
import { BotaoAdicionarDado, TabelaFornecedor, OffcanvasFornecedor, ModalImportarFornecedores } from "../components";
import { ContextUser } from "../contexts/ContextUser";
import useFornecedor, { LIMITE_POR_BUSCA_FORNECEDOR } from "../hooks/useFornecedor";

export default function Fornecedores() {
    //HOOKS
    const { buscar, estaBuscando, cancelar, buscaComErro, criar } = useFornecedor();

    //CONTEXTOS
    const { podeAdicionarFornecedor } = useContext(ContextUser);

    //ESTADOS
    const [fornecedores, setFornecedores] = useState<tFornecedor[]>([]);
    const [paginaAtual, setPaginaAtual] = useState(0);
    const [proximaPagina, setProximaPagina] = useState(1);
    const [campoPesquisa, setCampoPesquisa] = useState<string | undefined>(undefined);

    const [mostrarModalImport, setMostrarModalImport] = useState(false);
    const [mostrarOffcanvasAdd, setMostrarOffcanvasAdd] = useState(false);
    const [fornecedorEditando, setFornecedorEditando] = useState<tFornecedor | null>(null);
    const [fornecedorDeletando, setFornecedorDeletando] = useState<tFornecedor | null>(null);

    //EVENTOS
    const buscarFornecedores = useCallback(
        async (pagina?: number) => {
            try {
                const novosFornecedores = await buscar({
                    limit: LIMITE_POR_BUSCA_FORNECEDOR,
                    page: pagina,
                    where: { campoPesquisa },
                });
                if (pagina === undefined) {
                    setFornecedores(novosFornecedores);
                    setPaginaAtual(0);
                    setProximaPagina(1);
                } else setFornecedores((atuais) => [...atuais, ...novosFornecedores]);
                return novosFornecedores.length; // Retorna pro InfiniteScroll
            } catch (err) {
                throw err; // Necessário para avisar que houve um erro ao InfiniteScroll
            }
        },
        [buscar, campoPesquisa]
    );

    useEffect(() => {
        buscarFornecedores().catch((err) => console.error(err));
        return () => cancelar();
    }, [cancelar, buscarFornecedores]);

    const handleOnClickImportFornecedores = useCallback(() => setMostrarModalImport(true), []);
    const handleOnClickNovoFornecedor = useCallback(() => setMostrarOffcanvasAdd(true), []);
    const handleOnClickEditFornecedor = useCallback((fornecedor: tFornecedor) => setFornecedorEditando(fornecedor), []);
    const handleOnClickDeleteFornecedor = useCallback((forn: tFornecedor) => setFornecedorDeletando(forn), []);

    return (
        <>
            <LayoutTabela.Root>
                <LayoutTabela.Header>
                    <LayoutTabela.HeaderLeft />
                    <LayoutTabela.HeaderRight>
                        {podeAdicionarFornecedor({}) && (
                            <BotaoAdicionarDado
                                texto="Fornecedor"
                                onClickAdd={handleOnClickNovoFornecedor}
                                onClickImport={handleOnClickImportFornecedores}
                            />
                        )}
                    </LayoutTabela.HeaderRight>
                </LayoutTabela.Header>
                <LayoutTabela.Body
                    buscando={estaBuscando || buscaComErro}
                    paginaAtual={paginaAtual}
                    proximaPagina={proximaPagina}
                    setPaginaAtual={setPaginaAtual}
                    setProximaPagina={setProximaPagina}
                    buscar={buscarFornecedores}
                >
                    <TabelaFornecedor
                        fornecedores={fornecedores}
                        onEdit={handleOnClickEditFornecedor}
                        onDelete={handleOnClickDeleteFornecedor}
                        buscar={setCampoPesquisa}
                    />
                </LayoutTabela.Body>
            </LayoutTabela.Root>

            <ModalImportarFornecedores
                onImport={criar}
                onFinishImport={() => buscarFornecedores().catch((err) => console.error(err))}
                show={mostrarModalImport}
                onHide={() => setMostrarModalImport(false)}
            />

            <OffcanvasFornecedor
                tipo="criar"
                mostrarForm={mostrarOffcanvasAdd}
                onCancel={() => {
                    setMostrarOffcanvasAdd(false);
                    cancelar();
                }}
                onTerminou={() => buscarFornecedores().catch((err) => console.error(err))}
            />

            <OffcanvasFornecedor
                tipo="editar"
                mostrarForm={fornecedorEditando !== null}
                dados={fornecedorEditando}
                onCancel={() => {
                    setFornecedorEditando(null);
                    cancelar();
                }}
                onTerminou={() => {
                    buscarFornecedores().catch((err) => console.error(err));
                    setFornecedorEditando(null);
                }}
            />

            <OffcanvasFornecedor
                tipo="deletar"
                mostrarForm={fornecedorDeletando !== null}
                dados={fornecedorDeletando}
                onCancel={() => {
                    setFornecedorDeletando(null);
                    cancelar();
                }}
                onTerminou={() => {
                    buscarFornecedores().catch((err) => console.error(err));
                    setFornecedorDeletando(null);
                }}
            />
        </>
    );
}
