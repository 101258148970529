import {
    FiltroAnos,
    FiltroComissaoPaga,
    FiltroEstadosVenda,
    FiltroVendedores,
    OffcanvasDistribuicoesComissao,
    TabelaComissoes,
} from "../components";
import { useCallback, useContext, useEffect, useState } from "react";
import { LayoutTabela } from "../layouts";
import { tComissao } from "../interfaces";
import useComissao, { LIMITE_POR_BUSCA_COMISSAO } from "../hooks/useComissao";
import { ContextUser } from "../contexts/ContextUser";
import FiltroMes from "../components/filtros/FiltroMes";
import { ContextAlerta } from "../contexts/ContextAlert";
import { Button } from "react-bootstrap";
import useFiltros from "../hooks/useFiltros";

export default function Comissoes() {
    //HOOKS
    const { buscar, buscaComErro, estaBuscando, cancelar, editar, editarVarias } = useComissao();
    const {
        vendedorIdSelecionado,
        dataInicialSelecionada,
        dataFinalSelecionada,
        estadoVendaSelecionado,
        comissaoPagaSelecionado,
    } = useFiltros();

    //CONTEXTOS
    const { setProcessando, setConfirmar } = useContext(ContextAlerta);
    const { podeVisualizarComissao } = useContext(ContextUser);

    //ESTADOS
    const [comissoes, setComissoes] = useState<tComissao[]>([]);

    const [paginaAtual, setPaginaAtual] = useState(0);
    const [proximaPagina, setProximaPagina] = useState(1);
    const [campoPesquisa, setCamposPesquisa] = useState<string | undefined>(undefined);

    const [showOffcanvasDistribuicoes, setShowOffcanvasDistribuicoes] = useState(false);

    //EVENTOS
    const buscarComissoes = useCallback(
        async (pagina?: number) => {
            const wherePesquisa = {
                vendedorId: podeVisualizarComissao({}) ? undefined : vendedorIdSelecionado,
                campoPesquisa,
            };
            const where = {
                comissaoPaga: comissaoPagaSelecionado,
                vendedorId: vendedorIdSelecionado,
                dataInicio: dataInicialSelecionada,
                dataFim: dataFinalSelecionada,
                estadoVenda: estadoVendaSelecionado,
            };
            if (!podeVisualizarComissao(where) || !podeVisualizarComissao(wherePesquisa)) return 0;

            try {
                const comissoes = await buscar({
                    limit: LIMITE_POR_BUSCA_COMISSAO,
                    page: pagina,
                    where: campoPesquisa ? wherePesquisa : where,
                });
                if (pagina === undefined) {
                    setComissoes(comissoes);
                    setPaginaAtual(0);
                    setProximaPagina(1);
                } else setComissoes((atuais) => [...atuais, ...comissoes]);
                return comissoes.length; // Retorna pro InfiniteScroll
            } catch (err) {
                throw err; // Necessário para avisar que houve um erro ao InfiniteScroll
            }
        },
        [
            buscar,
            vendedorIdSelecionado,
            dataInicialSelecionada,
            dataFinalSelecionada,
            podeVisualizarComissao,
            comissaoPagaSelecionado,
            estadoVendaSelecionado,
            campoPesquisa,
        ]
    );

    useEffect(() => {
        buscarComissoes().catch((err) => console.error(err));
        return () => cancelar();
    }, [cancelar, buscarComissoes]);

    const handleOnEditComissao = useCallback(
        async (comissao: tComissao) => {
            const confirm = await setConfirmar(
                "Após marcar como paga esta comissão, não se poderá reverter este estado."
            );
            if (!confirm) return;

            try {
                setProcessando(true);
                await editar({ id: comissao.id, comissaoPaga: !comissao.comissaoPaga });
                buscarComissoes().catch((err) => console.error(err));
            } catch (err) {
                console.error(err);
            } finally {
                setProcessando(false);
            }
        },
        [editar, buscarComissoes, setProcessando, setConfirmar]
    );

    return (
        <>
            <LayoutTabela.Root>
                <LayoutTabela.Header>
                    <LayoutTabela.HeaderLeft>
                        <FiltroAnos notActive={!!campoPesquisa}/>
                        <FiltroMes notActive={!!campoPesquisa}/>
                        <FiltroComissaoPaga notActive={!!campoPesquisa}/>
                        <FiltroEstadosVenda notActive={!!campoPesquisa}/>
                        <FiltroVendedores notActive={!!campoPesquisa}/>
                    </LayoutTabela.HeaderLeft>
                    <LayoutTabela.HeaderRight>
                        <Button className="rounded-pill" onClick={() => setShowOffcanvasDistribuicoes(true)}>
                            Distribuições
                        </Button>
                    </LayoutTabela.HeaderRight>
                </LayoutTabela.Header>
                <LayoutTabela.Body
                    buscando={estaBuscando || buscaComErro}
                    paginaAtual={paginaAtual}
                    proximaPagina={proximaPagina}
                    setPaginaAtual={setPaginaAtual}
                    setProximaPagina={setProximaPagina}
                    buscar={buscarComissoes}
                >
                    <TabelaComissoes
                        comissoes={comissoes}
                        onEdit={handleOnEditComissao}
                        buscar={setCamposPesquisa}
                        propsDropdownInfoComissao={{
                            onEditarVarias: editarVarias,
                            onEditou: () => buscarComissoes().catch((err) => console.error(err)),
                        }}
                    />
                </LayoutTabela.Body>
            </LayoutTabela.Root>

            <OffcanvasDistribuicoesComissao
                placement="end"
                show={showOffcanvasDistribuicoes}
                onHide={() => setShowOffcanvasDistribuicoes(false)}
            />
        </>
    );
}
