import { ReactNode } from "react";
import { Col, Form } from "react-bootstrap";
import { v4 } from "uuid";

export type FormGroupGenericProps = {
    label?: ReactNode;
    error?: string;
    sm?: string;
    children?: ReactNode;
};

export default function FormGroupGeneric(props: FormGroupGenericProps) {
    const inputId = v4();
    return (
        <Form.Group as={Col} sm={props.sm ?? "12"} className="text-end" data-test="form-group">
            <Form.Label htmlFor={inputId} className="mb-1" data-test="form-label">
                {props.label}
            </Form.Label>
            <div>{props.children}</div>
            <Form.Control id={inputId} className="d-none" isInvalid={!!props.error} />
            <Form.Control.Feedback type="invalid">{props.error}</Form.Control.Feedback>
        </Form.Group>
    );
}
