import { Form, Row } from "react-bootstrap";
import { Formik, FormikHelpers } from "formik";
import { useCallback, useContext } from "react";
import * as yup from "yup";
import utils from "../../utils";
import { ContextAlerta } from "../../contexts/ContextAlert";
import { PartialEntity, tVendedorComissao } from "../../interfaces";
import SelectVendedor, { ISelectVendedorProps } from "../selects/SelectVendedor";
import FormGroupInput, { FormGroupInputType } from "../formGroups/FormGroupInput";
import FormGroupGeneric from "../formGroups/FormGroupGeneric";

//TYPES
export type tNovoVendedorComissao = PartialEntity<tVendedorComissao, "vendedorId" | "comissao">;

export interface IFormVendedorComissaoProps extends ISelectVendedorProps {
    formId?: string;
    onSubmit: (vendedorComissao: tNovoVendedorComissao) => void | Promise<void>;
    valoresIniciais: tNovoVendedorComissao;
}

//FORM
export default function FormVendedorComissao({ formId, onSubmit, valoresIniciais }: IFormVendedorComissaoProps) {
    //CONTEXTOS
    const { setPerigo } = useContext(ContextAlerta);

    //VARIAVEIS
    const onSubmitFormik = useCallback(
        async (vendedorComissao: tNovoVendedorComissao, helpers: FormikHelpers<tNovoVendedorComissao>) => {
            try {
                await onSubmit(vendedorComissao);
                helpers.setSubmitting(false);
                helpers.resetForm();
            } catch (err) {
                if (utils.blErroCancelamento(err)) return;
                setPerigo(utils.retornaMensagemErro(err));
                console.error(err);
            }
        },
        [onSubmit, setPerigo]
    );

    return (
        <Formik
            validateOnChange={false}
            validationSchema={yupEsquemaVendedorComissao}
            onSubmit={onSubmitFormik}
            initialValues={valoresIniciais}
        >
            {({ handleSubmit, values, errors, setValues }) => {
                return (
                    <Form id={formId} onSubmit={handleSubmit}>
                        <Row>
                            <FormGroupGeneric label="Vendedor" error={errors.vendedorId}>
                                <SelectVendedor
                                    idSelecionado={values.vendedorId}
                                    setIdSelecionado={(id) => setValues({ ...values, vendedorId: id ?? "" })}
                                />
                            </FormGroupGeneric>

                            <FormGroupInput
                                type={FormGroupInputType.PORCENT}
                                label="Comissão"
                                required
                                value={values.comissao}
                                setValue={(comissao) => setValues({ ...values, comissao })}
                                error={errors.comissao}
                            />
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
}

export const yupEsquemaVendedorComissao: yup.ObjectSchema<tNovoVendedorComissao> = yup.object({
    vendedorId: yup.string().required("Selecione um vendedor."),
    comissao: yup.number().required("Este campo é necessário."),
});
