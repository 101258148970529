import { ListGroup } from "react-bootstrap";
import FiltroSelect from "./FiltroSelect";
import { useCallback } from "react";
import useFiltros, { FilterKey } from "../../hooks/useFiltros";

const NENHUM_FILTRO = "Todas as visitas";
const VISITA_PAGA = "Visitas pagas";
const VISITA_N_PAGA = "Visitas não pagas";

export type FiltroVisitaPagaProps = { notActive?: boolean };
export default function FiltroVisitaPaga(props: FiltroVisitaPagaProps) {
    //HOOKS
    const { setFilter, removeFilter, visitaPagaSelecionado } = useFiltros();

    //VARIAVEIS
    const retornaTextoFiltro = useCallback(() => {
        if (visitaPagaSelecionado === undefined) return NENHUM_FILTRO;
        else return visitaPagaSelecionado ? "Pagas" : "Não pagas";
    }, [visitaPagaSelecionado]);

    //EVENTOS
    const handleOnSelecionarFiltro = useCallback(
        (estado: boolean | null) => {
            if (estado === null) return removeFilter(FilterKey.VISITA_PAGA);
            setFilter(FilterKey.VISITA_PAGA, estado ? "1" : "0");
        },
        [setFilter, removeFilter]
    );

    return (
        <FiltroSelect
            texto={retornaTextoFiltro()}
            titulo="Estado Visita"
            active={visitaPagaSelecionado !== undefined && !props.notActive}
        >
            <ListGroup className="small">
                <ListGroup.Item
                    className={`my-item-filtro ${visitaPagaSelecionado === undefined ? "bg-light" : ""}`}
                    onClick={() => handleOnSelecionarFiltro(null)}
                >
                    {NENHUM_FILTRO}
                </ListGroup.Item>
                <ListGroup.Item
                    className={`my-item-filtro ${visitaPagaSelecionado === false ? "bg-light" : ""}`}
                    onClick={() => handleOnSelecionarFiltro(false)}
                >
                    <div className="d-flex align-items-center justify-content-between gap-2">
                        {VISITA_N_PAGA} <div className="p-2 bg-warning rounded-circle" />
                    </div>
                </ListGroup.Item>
                <ListGroup.Item
                    className={`my-item-filtro ${visitaPagaSelecionado === true ? "bg-light" : ""}`}
                    onClick={() => handleOnSelecionarFiltro(true)}
                >
                    <div className="d-flex align-items-center justify-content-between gap-2">
                        {VISITA_PAGA} <div className="p-2 bg-success rounded-circle" />
                    </div>
                </ListGroup.Item>
            </ListGroup>
        </FiltroSelect>
    );
}
