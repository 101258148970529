import {
    Button,
    Col,
    ListGroup,
    Offcanvas,
    OffcanvasProps,
    Overlay,
    Placeholder,
    Popover,
    Row,
    Stack,
} from "react-bootstrap";
import BotaoFechar from "../botoes/BotaoFechar";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { tDistribuicaoComissao } from "../../interfaces";
import Botao from "../botoes/Botao";
import useDistribuicaoComissao from "../../hooks/useDistribuicaoComissao";
import OffcanvasDistribuicaoComissao from "./OffcanvasDistribuicaoComissao";
import { ContextUser } from "../../contexts/ContextUser";

interface IOffcanvasDistribuicoesComissaoProps extends OffcanvasProps {
    onHide: () => void;
}

export default function OffcanvasDistribuicoesComissao({
    onHide,
    show,
    ...rest
}: IOffcanvasDistribuicoesComissaoProps) {
    //CONTEXTOS
    const {
        podeVisualizarDistribuicaoComissao,
        usuario,
        podeEditarDistribuicaoComissao,
        podeDeletarDistribuicaoComissao,
        podeAdicionarDistribuicaoComissao,
    } = useContext(ContextUser);

    //HOOKS
    const { estaBuscando, buscar, cancelar, buscaComErro } = useDistribuicaoComissao();

    //ESTADOS
    const [distribuicoes, setDistribuicoes] = useState<tDistribuicaoComissao[]>([]);
    const [showOffcanvasAddDistribuicao, setShowOffcanvasAddDistribuicao] = useState(false);

    const [distribuicaoEditando, setDistribuicaoEditando] = useState<tDistribuicaoComissao | null>(null);
    const [distribuicaoDeletando, setDistribuicaoDeletando] = useState<tDistribuicaoComissao | null>(null);

    //EVENTOS
    const buscarDistribuicoes = useCallback(() => {
        if (podeVisualizarDistribuicaoComissao({}))
            buscar()
                .then((distribuicoes) => setDistribuicoes(distribuicoes))
                .catch((err) => console.error(err));
        else
            buscar({ where: { vendedorId: usuario?.vendedorUser?.id } })
                .then((distribuicoes) => setDistribuicoes(distribuicoes))
                .catch((err) => console.error(err));
    }, [buscar, usuario, podeVisualizarDistribuicaoComissao]);

    useEffect(() => {
        if (!show) return;
        buscarDistribuicoes();
        return () => cancelar();
    }, [buscarDistribuicoes, show, cancelar]);

    const handleOnClickAddDistribuicao = useCallback(() => setShowOffcanvasAddDistribuicao(true), []);

    return (
        <>
            <Offcanvas show={show} {...rest} data-test="offcanvas">
                <Offcanvas.Header className="border-bottom bg-light bg-opacity-25">
                    <Row>
                        <Col sm="12" className="position-absolute d-flex w-100 justify-content-end top-0 mt-2">
                            <BotaoFechar darkTheme onClick={onHide} />
                        </Col>

                        <Col sm="12">
                            <h4 className="mb-0">Distribuições de comissões</h4>
                        </Col>
                    </Row>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Row>
                        {!estaBuscando &&
                            (podeDeletarDistribuicaoComissao({}) || podeEditarDistribuicaoComissao({})) && (
                                <small className="text-secondary-dark lh-1 mb-4">
                                    Clique nas distribuições para ver as opções.
                                </small>
                            )}

                        {!estaBuscando && !buscaComErro && distribuicoes.length < 1 && (
                            <Col sm="12" className="text-center mb-4">
                                <span className="text-secondary-dark fs-5">
                                    Nenhuma distribuição de comissão encontrada.
                                </span>
                            </Col>
                        )}

                        {estaBuscando || buscaComErro ? (
                            <Col sm="12" className="mb-4">
                                {[1, 2, 3].map((index) => (
                                    <Row key={index}>
                                        <Col sm="12">
                                            <ComponenteDistribuicao />
                                        </Col>
                                    </Row>
                                ))}
                            </Col>
                        ) : (
                            distribuicoes.map((distribuicao) => (
                                <Col sm="12" key={distribuicao.id}>
                                    <ComponenteDistribuicao
                                        distribuicao={distribuicao}
                                        onDeleteDistribuicao={setDistribuicaoDeletando}
                                        onEditDistribuicao={setDistribuicaoEditando}
                                    />
                                </Col>
                            ))
                        )}

                        {!estaBuscando && podeAdicionarDistribuicaoComissao({}) && (
                            <Col sm="12" className="sticky-bottom text-end mt-2">
                                <Button
                                    className="rounded-pill"
                                    variant="primary"
                                    onClick={handleOnClickAddDistribuicao}
                                >
                                    Nova distribuição
                                </Button>
                            </Col>
                        )}
                    </Row>
                </Offcanvas.Body>
            </Offcanvas>

            <OffcanvasDistribuicaoComissao
                tipo="criar"
                mostrarForm={showOffcanvasAddDistribuicao}
                onCancel={() => setShowOffcanvasAddDistribuicao(false)}
                onTerminou={() => {
                    setShowOffcanvasAddDistribuicao(false);
                    buscarDistribuicoes();
                }}
            />

            <OffcanvasDistribuicaoComissao
                tipo="editar"
                mostrarForm={distribuicaoEditando !== null}
                dados={distribuicaoEditando}
                onCancel={() => setDistribuicaoEditando(null)}
                onTerminou={() => {
                    setDistribuicaoEditando(null);
                    buscarDistribuicoes();
                }}
            />

            <OffcanvasDistribuicaoComissao
                tipo="deletar"
                mostrarForm={distribuicaoDeletando !== null}
                dados={distribuicaoDeletando}
                onCancel={() => setDistribuicaoDeletando(null)}
                onTerminou={() => {
                    setDistribuicaoDeletando(null);
                    buscarDistribuicoes();
                }}
            />
        </>
    );
}

function ComponenteDistribuicao(props: {
    distribuicao?: tDistribuicaoComissao;
    onDeleteDistribuicao?: (distribuicao: tDistribuicaoComissao) => void | Promise<void>;
    onEditDistribuicao?: (distribuicao: tDistribuicaoComissao) => void | Promise<void>;
}) {
    //CONTEXTOS
    const { podeEditarDistribuicaoComissao, podeDeletarDistribuicaoComissao } = useContext(ContextUser);

    //ESTADOS
    const [showMenu, setShowMenu] = useState(false);

    //VARIAVEIS
    const { distribuicao, onDeleteDistribuicao, onEditDistribuicao } = props;
    const refDiv = useRef<HTMLDivElement>(null);

    //EVENTOS
    const handleOnHideMenu = useCallback(() => setShowMenu(false), []);
    const handleOnShowMenu = useCallback(() => setShowMenu(true), []);

    const handleOnEditDistribuicao = useCallback(async () => {
        if (distribuicao && onEditDistribuicao) onEditDistribuicao(distribuicao);
        setShowMenu(false);
    }, [onEditDistribuicao, distribuicao]);

    const handleOnDeleteDistribuicao = useCallback(async () => {
        if (distribuicao && onDeleteDistribuicao) onDeleteDistribuicao(distribuicao);
        setShowMenu(false);
    }, [onDeleteDistribuicao, distribuicao]);

    return (
        <>
            <Stack
                className="my-distribuicao-comissao d-flex w-100 justify-content-center gap-2 mb-2 rounded p-2 border"
                onClick={
                    podeDeletarDistribuicaoComissao({}) || podeEditarDistribuicaoComissao({})
                        ? handleOnShowMenu
                        : undefined
                }
                ref={refDiv}
                role={podeDeletarDistribuicaoComissao({}) || podeEditarDistribuicaoComissao({}) ? "button" : ""}
            >
                {props.distribuicao ? (
                    props.distribuicao.vendedoresComissao.map((vendedorComissao) => (
                        <Stack key={vendedorComissao.id} className="w-100">
                            <span className="lh-1 fw-bold">{vendedorComissao.vendedor.user.name}</span>
                            <div className="d-flex w-100 justify-content-between text-monospace">
                                <span>Comissão:</span>
                                <span>{vendedorComissao.comissao.toFixed(2)} %</span>
                            </div>
                        </Stack>
                    ))
                ) : (
                    <Placeholder animation="glow" className="opacity-25">
                        <Placeholder sm="12" className="h-100 rounded p-5" />
                    </Placeholder>
                )}
            </Stack>
            <Overlay
                placement="bottom"
                flip
                target={refDiv.current}
                rootClose
                onHide={handleOnHideMenu}
                show={showMenu && !!props.distribuicao}
            >
                <Popover className="rounded p-1 bg-white shadow">
                    <ListGroup className="rounded">
                        {props.distribuicao && (
                            <>
                                <Botao size="sm" onClick={handleOnEditDistribuicao}>
                                    <span className="text-truncate">Editar distribuição</span>
                                </Botao>

                                <Botao size="sm" onClick={handleOnDeleteDistribuicao}>
                                    <span className="text-truncate">Deletar distribuição</span>
                                </Botao>
                            </>
                        )}
                    </ListGroup>
                </Popover>
            </Overlay>
        </>
    );
}
