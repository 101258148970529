import { useCallback, useContext, useRef, useState } from "react";
import useComissao from "../../hooks/useComissao";
import { tVendedor } from "../../interfaces";
import { Col, Row, Spinner, Stack } from "react-bootstrap";
import { ContextAlerta } from "../../contexts/ContextAlert";
import utils from "../../utils";
import BotaoAssincrono from "../botoes/BotaoAssincrono";
import Dropdown from "./dropdown";
import { ContextUser } from "../../contexts/ContextUser";
import useFiltros from "../../hooks/useFiltros";

//TYPES
export type tVendedorComissaoTotal = {
    vendedor: tVendedor;
    comissaoTotal: number;
    comissoesIds: string[];
    paga?: boolean;
};

export interface DropdownComissoesTotaisProps {
    onEditarVarias?: (comissoesIds: string[], comissaoPaga: boolean) => Promise<void>;
    onEditou?: () => any;
}

export default function DropdownComissoesTotais({ onEditarVarias, onEditou }: DropdownComissoesTotaisProps) {
    //CONTEXTOS
    const { setPerigo, setConfirmar, setProcessando, setAviso } = useContext(ContextAlerta);
    const { podeEditarComissao } = useContext(ContextUser);

    //HOOKS
    const { buscarTotalComissaoPagar, estaBuscandoTotalPagar, buscaTotalPagarComErro } = useComissao();
    const { dataInicialSelecionada, dataFinalSelecionada, estadoVendaSelecionado, vendedorIdSelecionado } =
        useFiltros();

    //ESTADOS
    const [vendedoresComissaoTotal, setVendedoresComissaoTotal] = useState<tVendedorComissaoTotal[]>([]);
    const [fecharMenuAoClicarFora, setFecharMenuAoClicarFora] = useState(true);

    //REFS
    const divRef = useRef<HTMLDivElement>(null);

    //VARIABLES
    const vendedorId = vendedorIdSelecionado ?? undefined;

    const retornaDatasFiltradasFormatadas = useCallback(() => {
        const inicio = dataInicialSelecionada?.toLocaleDateString("pt-Br", { month: "2-digit", year: "numeric" });
        const fim = dataFinalSelecionada?.toLocaleDateString("pt-Br", { month: "2-digit", year: "numeric" });

        return `${inicio} - ${fim}`;
    }, [dataInicialSelecionada, dataFinalSelecionada]);

    //EVENTOS
    const buscarComissoesTotaisPagar = useCallback(async () => {
        try {
            if (!dataFinalSelecionada || !dataInicialSelecionada)
                return setAviso("Deve ser definido um período para realizar a busca.");

            const totalComissoesPagar = await buscarTotalComissaoPagar({
                where: {
                    vendedorId,
                    dataInicio: dataInicialSelecionada,
                    dataFim: dataFinalSelecionada,
                    estadoVenda: estadoVendaSelecionado,
                },
            });

            setVendedoresComissaoTotal(totalComissoesPagar);
        } catch (err) {
            if (utils.blErroCancelamento(err)) return;
            setPerigo(utils.retornaMensagemErro(err));
            console.error(err);
        }
    }, [
        estadoVendaSelecionado,
        vendedorId,
        dataInicialSelecionada,
        dataFinalSelecionada,
        setPerigo,
        setAviso,
        buscarTotalComissaoPagar,
    ]);

    const handleOnPagar = useCallback(
        async (vendedoresComissao: tVendedorComissaoTotal[]) => {
            //Evita que o menu seja fechado quando o usário confirmar a ação
            setFecharMenuAoClicarFora(false);

            const confirmou = await setConfirmar(
                "Após marcar como pagas estas comissões, não se poderá reverter este estado."
            );

            setFecharMenuAoClicarFora(true);
            if (!confirmou) return;

            const comissoesId = vendedoresComissao.flatMap((vc) => vc.comissoesIds);
            try {
                setProcessando(true);
                if (onEditarVarias) await onEditarVarias(comissoesId, true);
                if (onEditou) onEditou();

                //Atualizando os vendedoresComissoes que foram pagos
                setVendedoresComissaoTotal((current) =>
                    current.map((vc) =>
                        vendedoresComissao.some(({ vendedor }) => vendedor.id === vc.vendedor.id)
                            ? { ...vc, paga: true }
                            : vc
                    )
                );
            } catch (err) {
                console.error(err);
            } finally {
                setProcessando(false);
            }
        },
        [onEditarVarias, onEditou, setConfirmar, setProcessando]
    );

    return (
        <>
            <Dropdown.Root onOpenMenu={() => buscarComissoesTotaisPagar()}>
                <Dropdown.Item ref={divRef} className="text-primary">
                    <span>
                        Comissões totais <strong> a pagar </strong>
                    </span>
                </Dropdown.Item>
                <Dropdown.Menu rootClose={fecharMenuAoClicarFora} target={divRef.current} placement="bottom-start">
                    <Row>
                        {buscaTotalPagarComErro && !estaBuscandoTotalPagar && (
                            <Col sm="12" className="text-secondary-dark text-center">
                                <span>
                                    Houve um erro ao buscar os dados.{" "}
                                    <span onClick={buscarComissoesTotaisPagar} role="button" className="text-primary">
                                        Tentar novamente.
                                    </span>
                                </span>
                            </Col>
                        )}
                        {estaBuscandoTotalPagar && (
                            <Col sm="12">
                                <Stack className="d-flex w-100 align-items-center text-primary">
                                    <Spinner size="sm" />
                                    <span> Aguarde... </span>
                                </Stack>
                            </Col>
                        )}
                        {vendedoresComissaoTotal.length < 1 && !estaBuscandoTotalPagar && !buscaTotalPagarComErro && (
                            <Col sm="12" className="text-center text-secondary-dark">
                                Nenhuma comissão a pagar
                            </Col>
                        )}
                        {vendedoresComissaoTotal.length > 0 && !estaBuscandoTotalPagar && !buscaTotalPagarComErro && (
                            <>
                                <Col sm="12" className="lh-1">
                                    <Stack className="bg-info bg-opacity-25 rounded p-2 border border-info text-info">
                                        <small className="mb-2">Comissões filtradas por:</small>
                                        <small className="fw-bold mb-1">
                                            Data: {retornaDatasFiltradasFormatadas()}
                                        </small>
                                        <small className="fw-bold">
                                            Vendas:{" "}
                                            {estadoVendaSelecionado
                                                ? utils.returnTextoEstadoVenda(estadoVendaSelecionado)
                                                : "Todos os estados."}
                                        </small>
                                    </Stack>
                                </Col>

                                {vendedoresComissaoTotal.map((vc) => (
                                    <Col sm="12" key={vc.vendedor.id}>
                                        <Stack className="bg-secondary bg-opacity-25 mt-2 p-2 rounded">
                                            <h6 className="text-truncate">{vc.vendedor.user.name}</h6>
                                            <div className="d-flex w-100 justify-content-between align-items-center">
                                                <span>{utils.retornaValorMonetario(vc.comissaoTotal, "BRL")}</span>
                                                {onEditarVarias && podeEditarComissao({ comissaoPaga: true }) && (
                                                    <BotaoAssincrono
                                                        className="rounded-pill"
                                                        variante="outline-primary"
                                                        size="sm"
                                                        disabled={vc.paga}
                                                        onClick={() => handleOnPagar([vc])}
                                                    >
                                                        {vc.paga ? "Pago" : "Pagar"}
                                                    </BotaoAssincrono>
                                                )}
                                            </div>
                                        </Stack>
                                    </Col>
                                ))}

                                {podeEditarComissao({ comissaoPaga: true }) && (
                                    <Col sm="12" className="text-end mt-2">
                                        <BotaoAssincrono
                                            className="rounded-pill"
                                            size="sm"
                                            onClick={() => handleOnPagar(vendedoresComissaoTotal)}
                                        >
                                            Pagar todas
                                        </BotaoAssincrono>
                                    </Col>
                                )}
                            </>
                        )}
                    </Row>
                </Dropdown.Menu>
            </Dropdown.Root>
        </>
    );
}
