import { Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { tComissao } from "../../interfaces";
import utils from "../../utils";
import { Tabela } from "./Tabela";
import { useCallback, useContext, useState } from "react";
import { ContextUser } from "../../contexts/ContextUser";
import ModalAuditoriaComissao from "../modals/ModalAuditoriaComissao";
import DropdownComissoesTotais, { DropdownComissoesTotaisProps } from "../dropdowns/DropdownComissoesTotais";
import DropdownRelatoriosComissao from "../dropdowns/DropdownRelatoriosComissao";
import { tHeaderTable } from "../dropdowns/DropdownCustomizacaoTabela";
import { returnCustomHeaders } from "./Tabela/TabelaRoot";
import InputSearch from "../inputs/InputSearch";
import "./styles.css";

const CUSTOMIZATION_NAME = "customizacoes-comissao";
const DEFAULT_HEADERS: tHeaderTable[] = [
    { name: "Data Boleto", show: true, value: "data" },
    { name: "Cliente", show: true, value: "cliente" },
    { name: "Nota Fiscal", show: true, value: "codigo_nota" },
    { name: "Vendedor", show: true, value: "vendedor" },
    { name: "Comissão", show: true, value: "comissao" },
    { name: "Margem Base Faturada", show: true, value: "margem_base" },
];

interface ITabelaComissoesProps {
    comissoes: tComissao[];
    onEdit: (comissao: tComissao) => Promise<void>;
    vendedorId?: string;
    propsDropdownInfoComissao: DropdownComissoesTotaisProps;
    buscar?: (pesquisa?: string) => Promise<any> | any;
}

export default function TabelaComissoes({ buscar, ...props }: ITabelaComissoesProps) {
    //CONTEXTOS
    const { podeEditarComissao, podeVisualizarAuditoria } = useContext(ContextUser);

    //ESTADOS
    const [pesquisa, setPesquisa] = useState<undefined | string>(undefined);

    const [comissaoAuditando, setComissaoAuditando] = useState<tComissao | null>(null);
    const [customHeaders, setCustomHeaders] = useState<tHeaderTable[]>(
        returnCustomHeaders(CUSTOMIZATION_NAME, DEFAULT_HEADERS)
    );

    //EVENTOS
    const handleOnClickOpenAuditoria = useCallback((comissao: tComissao) => setComissaoAuditando(comissao), []);
    const handleOnHideAuditoria = useCallback(() => setComissaoAuditando(null), []);

    const handleOnChangePesquisa = useCallback(
        async (novaPesquisa?: string) => {
            setPesquisa(novaPesquisa);
            try {
                if (buscar) await buscar(novaPesquisa);
            } catch (err) {
                console.error(err);
            }
        },
        [buscar]
    );
    return (
        <Tabela.Root customizationsName={CUSTOMIZATION_NAME} headers={customHeaders} setHeaders={setCustomHeaders}>
            <Tabela.ComponentTop>
                {buscar && (
                    <Col sm="auto" md="4" xl="3">
                        <InputSearch
                            placeholder="Núm. crm, núm. pedido, nota fiscal, nome cliente..."
                            pesquisa={pesquisa}
                            onChange={handleOnChangePesquisa}
                        />
                    </Col>
                )}
                <Col sm="auto">
                    <DropdownComissoesTotais {...props.propsDropdownInfoComissao} />
                </Col>
                <Col sm="auto">
                    <DropdownRelatoriosComissao />
                </Col>
            </Tabela.ComponentTop>

            <Tabela.Table>
                <Tabela.Header>
                    <tr>
                        {customHeaders
                            .filter((header) => header.show)
                            .map((header) => (
                                <th key={header.value}>{header.name}</th>
                            ))}
                    </tr>
                </Tabela.Header>
                <Tabela.Body
                    campoUnico="id"
                    dados={props.comissoes}
                    onClickCustomButton={podeEditarComissao({}) ? props.onEdit : undefined}
                    customButton={useCustomButton}
                    disabledCustom={(comissao: tComissao) => comissao.comissaoPaga}
                    onClickAuditoria={
                        podeVisualizarAuditoria({ tabela: "COMISSAO" }) ? handleOnClickOpenAuditoria : undefined
                    }
                >
                    {(comissao: tComissao) => {
                        const valorComissao = (comissao.comissao * comissao.valorMargemBrutaBase) / 100;

                        return (
                            <>
                                {customHeaders
                                    .filter((header) => header.show)
                                    .map((header, index) => {
                                        return (
                                            <td className="position-relative" key={header.value}>
                                                {index === 0 && (
                                                    <OverlayTrigger
                                                        placement="bottom-start"
                                                        overlay={
                                                            <Tooltip>
                                                                {comissao.comissaoPaga ? "Pago" : "Não pago"}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <div
                                                            className={`d-flex position-absolute h-100 start-0 top-0 p-1 pe-0 bg-${
                                                                comissao.comissaoPaga ? "success" : "warning"
                                                            }`}
                                                        />
                                                    </OverlayTrigger>
                                                )}
                                                {header.value === DEFAULT_HEADERS[0].value
                                                    ? comissao.dataComissao.toLocaleDateString("pt-Br", {
                                                          day: "2-digit",
                                                          month: "2-digit",
                                                          year: "numeric",
                                                      })
                                                    : header.value === DEFAULT_HEADERS[1].value
                                                    ? comissao.venda.cliente.name
                                                    : header.value === DEFAULT_HEADERS[2].value
                                                    ? comissao.venda.codigoNota
                                                    : header.value === DEFAULT_HEADERS[3].value
                                                    ? comissao.vendedor.user.name
                                                    : header.value === DEFAULT_HEADERS[4].value
                                                    ? utils.retornaValorMonetario(valorComissao, "BRL")
                                                    : utils.retornaValorMonetario(comissao.valorMargemBrutaBase, "BRL")}
                                            </td>
                                        );
                                    })}
                            </>
                        );
                    }}
                </Tabela.Body>
            </Tabela.Table>

            <ModalAuditoriaComissao
                show={comissaoAuditando !== null}
                comissao={comissaoAuditando}
                onHide={handleOnHideAuditoria}
            />
        </Tabela.Root>
    );
}

//Funções auxiliares
function useCustomButton(props: tComissao) {
    const tooltip = props.comissaoPaga ? "Marcar como não paga" : "Marcar como paga";
    const children = props.comissaoPaga ? (
        <i className="bi bi-slash-circle-fill text-danger" />
    ) : (
        <i className="bi bi-check-circle-fill text-success" />
    );
    return { tooltip, children };
}
