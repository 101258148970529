import { tFornecedor } from "../../interfaces";
import { Tabela } from "./Tabela";
import utils from "../../utils";
import "./styles.css";
import { useCallback, useContext, useState } from "react";
import { ContextUser } from "../../contexts/ContextUser";
import ModalAuditoriaFornecedor from "../modals/ModalAuditoriaFornecedor";
import { Col } from "react-bootstrap";
import InputSearch from "../inputs/InputSearch";

interface ITabelaFornecedorProps {
    fornecedores: tFornecedor[];
    onEdit: (fornecedor: tFornecedor) => void;
    onDelete: (fornecedor: tFornecedor) => void;
    buscar?: (pesquisa?: string) => Promise<any> | any;
}

export default function TabelaFornecedor({ buscar, ...props }: ITabelaFornecedorProps) {
    //CONTEXTOS
    const { podeEditarFornecedor, podeDeletarFornecedor, podeVisualizarAuditoria } = useContext(ContextUser);

    //ESTADOS
    const [fornecedorAuditando, setFornecedorAuditando] = useState<tFornecedor | null>(null);
    const [pesquisa, setPesquisa] = useState<undefined | string>(undefined);

    //EVENTOS
    const handleOnClickOpenAuditoria = useCallback((fornecedor: tFornecedor) => setFornecedorAuditando(fornecedor), []);
    const handleOnHideAuditoria = useCallback(() => setFornecedorAuditando(null), []);
    const handleOnChangePesquisa = useCallback(
        async (novaPesquisa?: string) => {
            setPesquisa(novaPesquisa);
            try {
                if (buscar) await buscar(novaPesquisa);
            } catch (err) {
                console.error(err);
            }
        },
        [buscar]
    );

    return (
        <>
            <Tabela.ComponentTop>
                {buscar && (
                    <Col sm="auto" md="4" xl="3">
                        <InputSearch
                            placeholder="Nome do fornecedor, cnpj, e-mail..."
                            pesquisa={pesquisa}
                            onChange={handleOnChangePesquisa}
                        />
                    </Col>
                )}
            </Tabela.ComponentTop>
            <Tabela.Table>
                <Tabela.Header>
                    <tr>
                        <th>Nome do fornecedor</th>
                        <th>CNPJ</th>
                        <th>E-mail do responsável</th>
                    </tr>
                </Tabela.Header>
                <Tabela.Body
                    dados={props.fornecedores}
                    campoUnico="id"
                    campoReferencia="name"
                    onEdit={podeEditarFornecedor({}) ? props.onEdit : undefined}
                    onDelete={podeDeletarFornecedor({}) ? props.onDelete : undefined}
                    onClickAuditoria={
                        podeVisualizarAuditoria({ tabela: "FORNECEDOR" }) ? handleOnClickOpenAuditoria : undefined
                    }
                >
                    {(fornecedor: tFornecedor) => (
                        <>
                            <td>{fornecedor.name}</td>
                            <td>{utils.retornaCNPJFormatado(fornecedor.cnpj)}</td>
                            <td>{fornecedor.email}</td>
                        </>
                    )}
                </Tabela.Body>
            </Tabela.Table>

            <ModalAuditoriaFornecedor
                fornecedor={fornecedorAuditando}
                show={fornecedorAuditando !== null}
                onHide={handleOnHideAuditoria}
            />
        </>
    );
}
