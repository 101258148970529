import CardDashboard from "./CardDashboard";
import { TotalValueDashboardCardProps } from "./types";
import utils from "../../utils";
import { DashboardMetric } from "../../interfaces";

export default function TotalValueDashboardCard(props: TotalValueDashboardCardProps) {
    const textValorTotal =
        props.metrica === DashboardMetric.VISITA
            ? props.valoTotalAtual
            : utils.retornaValorMonetario(props.valoTotalAtual, "BRL");

    const porcentValorAtualVsAnterior =
        ((props.valoTotalAtual - props.valorTotalAnterior) * 100) / props.valorTotalAnterior;

    return (
        <CardDashboard
            label={props.metrica}
            valor={textValorTotal}
            icon={props.metrica === DashboardMetric.VISITA ? "geo-fill" : "coin"}
            porcent={{
                valor: porcentValorAtualVsAnterior,
                miniLabel: "vs. ano anterior",
            }}
        />
    );
}
