import { Form, Row } from "react-bootstrap";
import { Formik, FormikHelpers } from "formik";
import { useCallback, useContext } from "react";
import * as yup from "yup";
import SelectCategoria from "../selects/SelectCategoria";
import SelectFabricante from "../selects/SelectFabricante";
import utils from "../../utils";
import { ContextAlerta } from "../../contexts/ContextAlert";
import { PartialEntity, tProduto } from "../../interfaces";
import SelectTipoProdutoServico from "../selects/SelectTipoProdutoServico";
import FormGroupInput, { FormGroupInputType } from "../formGroups/FormGroupInput";
import FormGroupGeneric from "../formGroups/FormGroupGeneric";

//TYPES
export type tNovoProduto = PartialEntity<
    tProduto,
    "categoriaId" | "fabricanteId" | "name" | "partNumber" | "tipoProdutoServicoId"
>;

interface IFormProdutoProps {
    formId?: string;
    onSubmit: (produto: tNovoProduto) => void | Promise<void>;
    valoresIniciais: tNovoProduto;
}

//FORM
export default function FormProduto(props: IFormProdutoProps) {
    //CONTEXTOS
    const { setPerigo } = useContext(ContextAlerta);

    //VARIAVEIS
    const { onSubmit, valoresIniciais, formId } = props;

    //EVENTOS
    const onSubmitFormik = useCallback(
        async (produto: tNovoProduto, helpers: FormikHelpers<tNovoProduto>) => {
            try {
                await onSubmit(produto);
                helpers.setSubmitting(false);
                helpers.resetForm();
            } catch (err) {
                if (utils.blErroCancelamento(err)) return;
                setPerigo(utils.retornaMensagemErro(err));
                console.error(err);
            }
        },
        [onSubmit, setPerigo]
    );

    return (
        <Formik
            validateOnChange={false}
            validationSchema={yupEsquemaProduto}
            onSubmit={onSubmitFormik}
            initialValues={valoresIniciais}
        >
            {({ handleSubmit, values, errors, setValues }) => {
                return (
                    <Form id={formId} onSubmit={handleSubmit}>
                        <Row className="gap-3">
                            <FormGroupGeneric label="Fabricante" error={errors.fabricanteId}>
                                <SelectFabricante
                                    setIdSelecionado={(id) => setValues({ ...values, fabricanteId: id ?? "" })}
                                    idSelecionado={values.fabricanteId}
                                />
                            </FormGroupGeneric>
                            <FormGroupInput
                                label="PartNumber do produto"
                                required
                                type={FormGroupInputType.TEXT}
                                value={values.partNumber}
                                setValue={(partNumber) => setValues({ ...values, partNumber })}
                                error={errors.partNumber}
                            />
                            <FormGroupInput
                                label="Nome do produto"
                                required
                                type={FormGroupInputType.TEXT}
                                value={values.name}
                                setValue={(name) => setValues({ ...values, name })}
                                error={errors.name}
                            />
                            <FormGroupGeneric label="Categoria do produto" error={errors.categoriaId}>
                                <SelectCategoria
                                    setIdSelecionado={(id) => setValues({ ...values, categoriaId: id ?? "" })}
                                    idSelecionado={values.categoriaId}
                                />
                            </FormGroupGeneric>
                            <FormGroupGeneric label="Tipo de Produto/Serviço" error={errors.tipoProdutoServicoId}>
                                <SelectTipoProdutoServico
                                    setIdSelecionado={(id) => setValues({ ...values, tipoProdutoServicoId: id ?? "" })}
                                    idSelecionado={values.tipoProdutoServicoId}
                                />
                            </FormGroupGeneric>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
}

export const yupEsquemaProduto: yup.ObjectSchema<tNovoProduto> = yup.object({
    name: yup.string().required("Campo necessário.").max(256, "Deve ter no máximo 256 carecteres."),
    partNumber: yup
        .string()
        .defined()
        .required("Campo necessário.")
        .min(1, "Deve ter no mínimo 1 caracteres.")
        .max(50, "Deve ter no máximo 50 carecteres."),
    categoriaId: yup.string().required("Selecione uma categoria."),
    tipoProdutoServicoId: yup.string().required("Selecione um tipo."),

    fabricanteId: yup.string().required("Selecione um fabricante."),
});

/*
    

    //COLOCAR NO ESQUEMA


    //TABELA PRODUTO
    const returnFornecedores = useCallback((produto: tProduto): string => {
        if (produto.fornecedores.length < 1) return "--- nenhum ---";
        return produto.fornecedores.map(({ name: nome }) => nome)?.join(", ");
    }, []);
*/
