import { useCallback, useContext, useEffect, useMemo } from "react";
import { EstadoVenda } from "../interfaces";
import { useSearchParams } from "react-router-dom";
import { ContextUser } from "../contexts/ContextUser";
import utils from "../utils";

export enum FilterKey {
    ANO = "ano",
    MES_INICIAL = "mesInicial",
    MES_FINAL = "mesFinal",
    CATEGORIA = "categoria",
    COMISSAO_PAGA = "comissaoPaga",
    ESTADO_VENDA = "estadoVenda",
    SEGMENTO = "segmento",
    TIPO_PROD_SERV = "tipoProdServ",
    VENDEDOR = "vendedor",
    VISITA_PAGA = "visitaPaga",
}

export default function useFiltros() {
    //HOOKS
    const { usuario } = useContext(ContextUser);
    const [searchParams, setSearchParams] = useSearchParams();

    //VARIABLES
    const vendedorId = usuario?.vendedorUser?.id;
    const userIsVendedor = usuario?.role === "VENDEDOR";

    const _mesInicialSelecionado = searchParams.get(FilterKey.MES_INICIAL);
    const _mesFinalSelecionado = searchParams.get(FilterKey.MES_FINAL);
    const _estadoVendaSelecionado = searchParams.get(FilterKey.ESTADO_VENDA) ?? undefined;
    const _comissaoPaga = searchParams.get(FilterKey.COMISSAO_PAGA) ?? undefined;
    const _visitaPaga = searchParams.get(FilterKey.VISITA_PAGA) ?? undefined;

    const comissaoPagaSelecionado = _comissaoPaga === "1" ? true : _comissaoPaga === "0" ? false : undefined;
    const visitaPagaSelecionado = _visitaPaga === "1" ? true : _visitaPaga === "0" ? false : undefined;
    const estadoVendaSelecionado = Object.values(EstadoVenda).find((estado) => _estadoVendaSelecionado === estado);
    const mesInicialSelecionado = Number(_mesInicialSelecionado ?? 0);
    const mesFinalSelecionado = Number(_mesFinalSelecionado ?? 11);
    const anoSelecionado = searchParams.get(FilterKey.ANO) ?? undefined;
    const categoriaIdSelecioando = searchParams.get(FilterKey.CATEGORIA) ?? undefined;
    const segmentoIdSelecioando = searchParams.get(FilterKey.SEGMENTO) ?? undefined;
    const tipoProdServIdSelecionado = searchParams.get(FilterKey.TIPO_PROD_SERV) ?? undefined;
    const vendedorIdSelecionado = searchParams.get(FilterKey.VENDEDOR) ?? undefined;
    const { dataInicial: dataInicialSelecionada, dataFinal: dataFinalSelecionada } = useMemo(() => {
        return utils.datasPorMesesAno(_mesInicialSelecionado, _mesFinalSelecionado, anoSelecionado);
    }, [_mesInicialSelecionado, _mesFinalSelecionado, anoSelecionado]);

    const getFilter = useCallback(
        (type: FilterKey) => {
            return searchParams.get(type);
        },
        [searchParams]
    );

    const removeFilter = useCallback(
        (type: FilterKey) => {
            const currentParams = searchParams;
            currentParams.delete(type);
            setSearchParams(currentParams);
        },
        [setSearchParams, searchParams]
    );

    const setFilter = useCallback(
        (type: FilterKey, value: string) => {
            const currentParams = searchParams;
            removeFilter(type);
            currentParams.append(type, value);
            setSearchParams(currentParams, { replace: true });
        },
        [setSearchParams, searchParams, removeFilter]
    );

    //EVENTOS
    useEffect(() => {
        const dataAtual = new Date();
        const anoAtual = dataAtual.getFullYear().toString();

        const filterKeys = Object.values(FilterKey);
        const params = filterKeys.reduce((acc, filterKey) => {
            const filterValue = getFilter(filterKey);

            switch (filterKey) {
                case FilterKey.ANO:
                    acc[filterKey] = filterValue ?? anoAtual;
                    break;
                case FilterKey.MES_INICIAL:
                    acc[filterKey] = filterValue ?? "0";
                    break;
                case FilterKey.MES_FINAL:
                    acc[filterKey] = filterValue ?? "11";
                    break;
                case FilterKey.VENDEDOR:
                    if (userIsVendedor && vendedorId) acc[filterKey] = filterValue ?? vendedorId;
                    else if (filterValue) acc[filterKey] = filterValue;
                    break;
                default:
                    if (filterValue) acc[filterKey] = filterValue;
                    break;
            }
            return acc;
        }, {} as Record<FilterKey, string>);

        setSearchParams(params);
    }, [getFilter, setSearchParams, vendedorId, userIsVendedor]);

    return {
        setFilter,
        removeFilter,
        dataInicialSelecionada,
        dataFinalSelecionada,
        mesInicialSelecionado,
        mesFinalSelecionado,
        anoSelecionado,
        categoriaIdSelecioando,
        comissaoPagaSelecionado,
        estadoVendaSelecionado,
        segmentoIdSelecioando,
        tipoProdServIdSelecionado,
        vendedorIdSelecionado,
        visitaPagaSelecionado,
    };
}
