import { Form, Row } from "react-bootstrap";
import { Formik, FormikHelpers } from "formik";
import { useCallback, useContext } from "react";
import { PartialEntity, tCliente } from "../../interfaces";
import * as yup from "yup";
import { ContextAlerta } from "../../contexts/ContextAlert";
import utils from "../../utils";
import SelectSegmento from "../selects/SelectSegmento";
import FormGroupInput, { FormGroupInputType } from "../formGroups/FormGroupInput";
import FormGroupGeneric from "../formGroups/FormGroupGeneric";

//TYPES
export type tNovoCliente = PartialEntity<tCliente, "name" | "email" | "segmentoId" | "telefone" | "cnpj">;

export interface IFormClienteProps {
    formId?: string;
    onSubmit: (cliente: tNovoCliente) => void | Promise<void>;
    valoresIniciais: tNovoCliente;
}

//FORM
export default function FormCliente(props: IFormClienteProps) {
    //CONTEXTOS
    const { setPerigo } = useContext(ContextAlerta);

    //VARIAVEIS
    const { onSubmit, valoresIniciais, formId } = props;

    const onSubmitFormik = useCallback(
        async (cliente: tNovoCliente, helpers: FormikHelpers<tNovoCliente>) => {
            try {
                await onSubmit(cliente);
                helpers.setSubmitting(false);
                helpers.resetForm();
            } catch (err) {
                if (utils.blErroCancelamento(err)) return;
                setPerigo(utils.retornaMensagemErro(err));
                console.error(err);
            }
        },
        [onSubmit, setPerigo]
    );

    return (
        <Formik
            data-test="form-cliente"
            validateOnChange={false}
            validationSchema={yupEsquemaCliente}
            onSubmit={onSubmitFormik}
            initialValues={valoresIniciais}
        >
            {({ handleSubmit, values, errors, setValues }) => {
                return (
                    <Form id={formId} onSubmit={handleSubmit}>
                        <Row className="row-gap-1">
                            <FormGroupInput
                                label="Nome do cliente"
                                type={FormGroupInputType.TEXT}
                                value={values.name}
                                required
                                setValue={(name) => setValues({ ...values, name })}
                                error={errors.name}
                            />
                            <FormGroupInput
                                label="CNPJ do cliente"
                                type={FormGroupInputType.TEXT}
                                value={values.cnpj}
                                mask="00.000.000/0000-00"
                                required
                                setValue={(cnpj) => setValues({ ...values, cnpj })}
                                error={errors.cnpj}
                            />
                            <FormGroupInput
                                label="E-mail"
                                type={FormGroupInputType.TEXT}
                                value={values.email}
                                required
                                setValue={(email) => setValues({ ...values, email })}
                                error={errors.email}
                            />
                            <FormGroupInput
                                label="Telefone para contato"
                                type={FormGroupInputType.TEXT}
                                mask={["0000-0000", "(00) 00000-0000", "+00 (00) 00000-0000", "(00) 0000-0000"]}
                                value={values.telefone}
                                required
                                setValue={(telefone) => setValues({ ...values, telefone })}
                                error={errors.telefone}
                            />
                            <FormGroupGeneric label="Segmento" error={errors.segmentoId}>
                                <SelectSegmento
                                    idSelecionado={values.segmentoId}
                                    setIdSelecionado={(segmentoId) =>
                                        setValues({ ...values, segmentoId: segmentoId ?? "" })
                                    }
                                />
                            </FormGroupGeneric>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
}

export const yupEsquemaCliente: yup.ObjectSchema<tNovoCliente> = yup.object({
    name: yup.string().required("Campo necessário.").max(256, "Deve ter no máximo 256 carecteres."),
    cnpj: yup
        .string()
        .required("Campo necessário.")
        .min(14, "Um CNPJ válido deve ter 14 dígitos.")
        .max(14, "Um CNPJ válido deve ter 14 dígitos."),
    email: yup
        .string()
        .email("Insira um e-mail válido.")
        .required("Campo necessário.")
        .max(256, "Deve ter no máximo 256 caracteres."),
    telefone: yup.string().required("Campo necessário."),
    segmentoId: yup.string().required("Campo necessário."),
});
