import { useCallback } from "react";
import { PartialEntity, tMeta } from "../../interfaces";
import { OffcanvasNovoDado } from "./OffcanvasNovoDado";
import { OffcanvasEditarDado } from "./OffcanvasEditarDado.tsx";
import { OffcanvasDeletarDado } from "./OffcanvasDeletarDado.tsx";
import FormMeta, { tNovaMeta } from "../forms/FormMeta";
import useMeta from "../../hooks/useMeta";
import utils from "../../utils";

export type IOffcanvasMetaProps = {
    mostrarForm: boolean;
    onTerminou?: () => void;
    onCancel?: () => void;
} & (
    | { tipo: "criar"; dados: PartialEntity<tNovaMeta, "vendedorId"> }
    | { tipo: "editar"; dados: null | (tNovaMeta & PartialEntity<tMeta, "id">) }
    | { tipo: "deletar"; dados: null | tMeta }
);

const FORM = "form-meta";

export default function OffcanvasMeta(props: IOffcanvasMetaProps) {
    //HOOKS
    const { criar, editar, deletar, cancelar, estaCriando, estaEditando, estaDeletando } = useMeta();

    //VARAIVEIS
    const { onTerminou, onCancel, dados, tipo } = props;

    //EVENTS
    const handleOnCancel = useCallback(() => {
        cancelar();
        if (onCancel) onCancel();
    }, [onCancel, cancelar]);

    const handleOnCriar = useCallback(
        async (metas: tNovaMeta[]) => {
            try {
                await criar(metas);
                if (onTerminou) onTerminou();
            } catch (err) {
                throw err;
            }
        },
        [criar, onTerminou]
    );

    const handleOnEditar = useCallback(
        async (meta: tNovaMeta & PartialEntity<tMeta, "id">) => {
            try {
                await editar(meta);
                if (onTerminou) onTerminou();
            } catch (err) {
                throw err;
            }
        },
        [editar, onTerminou]
    );

    const handleOnDeletar = useCallback(
        async (id: string) => {
            try {
                await deletar(id);
                if (onTerminou) onTerminou();
            } catch (err) {
                throw err;
            }
        },
        [deletar, onTerminou]
    );

    return props.tipo === "criar" ? (
        <OffcanvasNovoDado.Root show={props.mostrarForm}>
            <OffcanvasNovoDado.Header>
                <h4>Nova Meta</h4>
            </OffcanvasNovoDado.Header>
            <OffcanvasNovoDado.Body
                cancelButtonProps={{ onClick: handleOnCancel }}
                acceptButtonProps={{ carregando: estaCriando, type: "submit", form: FORM }}
            >
                <FormMeta
                    options={{ aceitarMetasMultiplas: true }}
                    formId={FORM}
                    valoresIniciais={{
                        dataInicioMeta: utils.retornaDataAtual({ hora: 0, minutos: 0, segundos: 0 }),
                        dataFimMeta: utils.retornaDataAtual({ hora: 23, minutos: 59, segundos: 59 }),
                        vendedorId: dados?.vendedorId ?? "",
                        tiposMeta: [],
                    }}
                    onSubmit={handleOnCriar}
                />
            </OffcanvasNovoDado.Body>
        </OffcanvasNovoDado.Root>
    ) : tipo === "editar" ? (
        <OffcanvasEditarDado.Root show={props.mostrarForm}>
            <OffcanvasEditarDado.Header>
                <h4>Editar Meta</h4>
            </OffcanvasEditarDado.Header>
            <OffcanvasEditarDado.Body
                cancelButtonProps={{ onClick: handleOnCancel }}
                acceptButtonProps={{ form: FORM, carregando: estaEditando, type: "submit" }}
            >
                {dados && (
                    <FormMeta
                        formId={FORM}
                        valoresIniciais={{
                            dataInicioMeta: dados.dataInicioMeta,
                            dataFimMeta: dados.dataFimMeta,
                            vendedorId: dados.vendedorId,
                            tiposMeta: dados.tiposMeta.map((meta) => ({
                                tipoMeta: meta.tipoMeta,
                                valor: meta.valor,
                            })),
                        }}
                        onSubmit={([meta]) => handleOnEditar({ id: dados.id, ...meta })}
                    />
                )}
            </OffcanvasEditarDado.Body>
        </OffcanvasEditarDado.Root>
    ) : tipo === "deletar" ? (
        <OffcanvasDeletarDado.Root show={props.mostrarForm}>
            <OffcanvasDeletarDado.Header>
                <h4>Deletar Meta</h4>
            </OffcanvasDeletarDado.Header>
            {dados && (
                <OffcanvasDeletarDado.Body
                    acceptButtonProps={{ onClick: () => handleOnDeletar(dados.id), carregando: estaDeletando }}
                    cancelButtonProps={{ onClick: handleOnCancel }}
                >
                    <p>
                        Você está prestes a deletar permanentemente do sistema a meta do vendedor no periodo{" "}
                        <strong>{utils.retornaPeriodoMeta(dados)}</strong>. Clique no botão{" "}
                        <strong className="text-danger">Deletar</strong> logo abaixo para prosseguir.
                    </p>
                </OffcanvasDeletarDado.Body>
            )}
        </OffcanvasDeletarDado.Root>
    ) : undefined;
}
