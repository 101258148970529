import { useCallback, useContext, useState } from "react";
import { tVendedor } from "../../interfaces";
import { routes } from "../../router";
import MyLink from "../links/MyLink";
import { Tabela } from "./Tabela";
import "./styles.css";
import { ContextUser } from "../../contexts/ContextUser";
import ModalAuditoriaVendedor from "../modals/ModalAuditoriaVendedor";
import InputSearch from "../inputs/InputSearch";
import { Col } from "react-bootstrap";

interface ITabelaVendedorProps {
    vendedores: tVendedor[];
    onEdit: (vendedor: tVendedor) => void;
    onDelete: (vendedor: tVendedor) => void;
    buscar?: (value?: string) => void;
}

export default function TabelaVendedor({ buscar, ...props }: ITabelaVendedorProps) {
    //CONTEXTOS
    const { podeEditarVendedor, podeDeletarVendedor, podeVisualizarAuditoria } = useContext(ContextUser);

    //ESTADOS
    const [pesquisa, setPesquisa] = useState<undefined | string>(undefined);
    const [vendedorAuditando, setVendedorAuditando] = useState<tVendedor | null>(null);

    //EVENTOS
    const handleOnClickOpenAuditoria = useCallback((vendedor: tVendedor) => setVendedorAuditando(vendedor), []);
    const handleOnHideAuditoria = useCallback(() => setVendedorAuditando(null), []);

    const handleOnChangePesquisa = useCallback(
        async (novaPesquisa?: string) => {
            try {
                setPesquisa(novaPesquisa);
                if (buscar) buscar(novaPesquisa);
            } catch (err) {
                console.error(err);
            }
        },
        [buscar]
    );

    return (
        <>
            {buscar && (
                <Tabela.ComponentTop>
                    <Col sm="6" md="5" xl="3">
                        <InputSearch
                            placeholder="Nome do vendedor, e-mail..."
                            pesquisa={pesquisa}
                            onChange={handleOnChangePesquisa}
                        />
                    </Col>
                </Tabela.ComponentTop>
            )}
            <Tabela.Table>
                <Tabela.Header>
                    <tr>
                        <th>Nome do vendedor</th>
                        <th>E-mail</th>
                    </tr>
                </Tabela.Header>
                <Tabela.Body
                    dados={props.vendedores}
                    campoUnico="id"
                    onEdit={podeEditarVendedor({}) ? props.onEdit : undefined}
                    onDelete={podeDeletarVendedor({}) ? props.onDelete : undefined}
                    onClickAuditoria={
                        podeVisualizarAuditoria({ tabela: "VENDEDOR" }) ? handleOnClickOpenAuditoria : undefined
                    }
                >
                    {(vendedor: tVendedor) => {
                        const rotaVendedor = routes.vendedor.replace(":id", vendedor.id);
                        return (
                            <>
                                <td>
                                    <MyLink to={rotaVendedor}>{vendedor.user.name}</MyLink>
                                </td>
                                <td>{vendedor.user.email}</td>
                            </>
                        );
                    }}
                </Tabela.Body>
            </Tabela.Table>
            <ModalAuditoriaVendedor
                show={vendedorAuditando !== null}
                vendedor={vendedorAuditando}
                onHide={handleOnHideAuditoria}
            />
        </>
    );
}
