import axios, { AxiosError, AxiosResponse } from "axios";
import {
    tEntityOptional,
    tCategoria,
    tCliente,
    tFabricante,
    tFornecedor,
    tInfoUser,
    tMeta,
    tProduto,
    tSegmento,
    tServico,
    tTipoProdutoServico,
    tUser,
    tVenda,
    tVendedor,
    PartialEntity,
    tMetaComValoresAtingidos,
    tComissao,
    tDashMetaAtingida,
    tDashRankClientePorVenda,
    tDashRankVendedorPorFaturamento,
    tDashRankCategoriaPorVenda,
    tDashEvolucaoFaturamento,
    tDashVendaPorTiposProdServ,
    tDashEvolucaoTicketMedio,
    tAuditoria,
    tFormaPagamento,
    tDistribuicaoComissao,
    EstadoVenda,
    tVisita,
    tContrato,
    tDashValoresTotais,
    tDashEvolucoes,
    tDashtMargemContribuicao,
    DashboardMetricKey,
} from "../interfaces";
import { tNovoCliente } from "../components/forms/FormCliente";
import { tNovoFornecedor } from "../components/forms/FormFornecedor";
import { tNovoVendedor } from "../components/forms/FormVendedor";
import { tNovoProduto } from "../components/forms/FormProduto";
import { tNovoServico } from "../components/forms/FormServico";
import { tNovaCategoria } from "../components/forms/FormCategoria";
import { tNovoFabricante } from "../components/forms/FormFabricante";
import { tNovaVenda } from "../components/forms/FormVenda";
import { tNovoUser } from "../components/forms/FormAdmin";
import { tNovaMeta } from "../components/forms/FormMeta";
import { tNovoSegmento } from "../components/forms/FormSegmento";
import { tNovoTipoProdutoServico } from "../components/forms/FormTipoProdutoServico";
import { tNovaFormaPagamento } from "../components/forms/FormFormaPagamento";
import { tImportarClienteDto } from "../components/modals/ModalImportarClientes";
import { tNovaVisita } from "../components/forms/FormVisita";
import { tNovaDistribuicaoComissao } from "../components/forms/FormDistribuicaoComissao";
import { tNovoContrato } from "../components/forms/FormContrato";
import { tVendedorComissaoTotal } from "../components/dropdowns/DropdownComissoesTotais";
import { tVendedorGastoTotal } from "../components/dropdowns/DropdownGastoTotal";

//TYPES
export type tTokenResponse = {
    tokens?: {
        access: {
            token: string;
            expires: Date;
        };
        refresh: {
            token: string;
            expires: Date;
        };
    };
};
export type tCredenciais = { email: string; password: string };
export type tQueryParams<WhereEntity, SortEntity> = {
    sortBy?: keyof SortEntity;
    sortType?: "asc" | "desc";
    limit?: number;
    page?: number;
    where?: tEntityOptional<WhereEntity>;
};

//TYPES PARAMS
export type tQueryParamsVendedor = tQueryParams<
    PartialEntity<tVendedor, "id"> & { campoPesquisa?: string },
    PartialEntity<tVendedor, "createdAt">
>;
export type tQueryParamsFornecedor = tQueryParams<
    PartialEntity<tFornecedor, "name"> & { campoPesquisa?: string },
    PartialEntity<tFornecedor, "name" | "createdAt">
>;
export type tQueryParamsCliente = tQueryParams<
    PartialEntity<tCliente, "name" | "segmentoId"> & { campoPesquisa?: string },
    PartialEntity<tCliente, "name" | "createdAt">
>;
export type tQueryParamsProduto = tQueryParams<
    PartialEntity<tProduto, "name" | "tipoProdutoServicoId" | "categoriaId"> & { campoPesquisa?: string },
    PartialEntity<tProduto, "name" | "createdAt">
>;
export type tQueryParamsServico = tQueryParams<
    PartialEntity<tServico, "name" | "tipoProdutoServicoId"> & { campoPesquisa?: string },
    PartialEntity<tServico, "name" | "createdAt">
>;
export type tQueryParamsCategoria = tQueryParams<
    PartialEntity<tCategoria, "name">,
    PartialEntity<tCategoria, "name" | "createdAt">
>;
export type tQueryParamsContrato = tQueryParams<
    PartialEntity<tContrato, "name">,
    PartialEntity<tContrato, "name" | "createdAt">
>;
export type tQueryParamsSegmento = tQueryParams<
    PartialEntity<tSegmento, "name">,
    PartialEntity<tSegmento, "name" | "createdAt">
>;
export type tQueryParamsTipoProdServ = tQueryParams<
    PartialEntity<tTipoProdutoServico, "name">,
    PartialEntity<tTipoProdutoServico, "name" | "createdAt">
>;
export type tQueryParamsFabricante = tQueryParams<
    PartialEntity<tFabricante, "name">,
    PartialEntity<tFabricante, "name" | "createdAt">
>;
export type tQueryParamsVenda = tQueryParams<
    PartialEntity<tVenda, "estadoVenda"> & {
        vendedorId?: string;
        dataInicio: Date;
        dataFim: Date;
        campoPesquisa?: string;
    },
    PartialEntity<tVenda, "dataFaturamento" | "dataVenda">
>;
export type tQueryParamsUser = tQueryParams<PartialEntity<tUser, "role">, PartialEntity<tUser, "name">>;
export type tQueryParamsMeta = tQueryParams<PartialEntity<tMeta, "vendedorId">, PartialEntity<tMeta, "dataInicioMeta">>;
export type tQueryParamsComissao = tQueryParams<
    PartialEntity<tComissao, "vendedorId" | "comissaoPaga"> & {
        dataInicio: Date;
        dataFim: Date;
        estadoVenda: EstadoVenda;
    },
    PartialEntity<tComissao, "dataComissao">
>;
export type tQueryParamsComissaoRelatorio = tQueryParams<
    PartialEntity<tComissao, "vendedorId"> & {
        dataInicio: Date;
        dataFim: Date;
        estadoVenda?: EstadoVenda;
    },
    {}
>;
export type tQueryParamsVisitaRelatorio = tQueryParams<
    PartialEntity<tVisita, "vendedorId"> & {
        dataInicio: Date;
        dataFim: Date;
    },
    {}
>;
export type tQueryParamsDashboards = {
    dataInicio: Date;
    dataFim: Date;
    vendedorId?: string;
    estadoVenda?: EstadoVenda;
};
export type tQueryParamsDashboardsWithMetrica = tQueryParamsDashboards & {
    metrica?: DashboardMetricKey;
};

export type tQueryParamsAuditoria = tQueryParams<
    PartialEntity<tAuditoria, "tabela" | "dadoId" | "acao">,
    PartialEntity<tAuditoria, "data">
>;
export type tQueryParamsFormaPagamento = tQueryParams<
    PartialEntity<tFormaPagamento, "name">,
    PartialEntity<tFormaPagamento, "name" | "createdAt">
>;
export type tQueryParamsDistribuicaoComissao = tQueryParams<
    { vendedorId?: string },
    PartialEntity<tDistribuicaoComissao, "createdAt">
>;
export type tQueryParamsVisita = tQueryParams<
    PartialEntity<tVisita, "vendedorId" | "visitaPaga"> & { dataInicio: Date; dataFim: Date },
    PartialEntity<tVisita, "dataVisita">
>;
export type tQueryParamsTotalPagar = tQueryParams<
    { vendedorId?: string; dataInicio: Date; dataFim: Date; estadoVenda?: EstadoVenda },
    {}
>;

//LOCAL_STORAGE
//Salvando tokens no localstorage
const ACCESS_TOKEN_KEY = "_ACCESS_TOKEN";
const REFRESH_TOKEN_KEY = "_REFRESH_TOKEN";

const getAccessToken = () => localStorage.getItem(ACCESS_TOKEN_KEY);
const setAccessToken = (newAccessToken: string) => localStorage.setItem(ACCESS_TOKEN_KEY, newAccessToken);

const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN_KEY);
const setRefreshToken = (newRefreshToken: string) => localStorage.setItem(REFRESH_TOKEN_KEY, newRefreshToken);
const cleanRefreshToken = () => localStorage.removeItem(REFRESH_TOKEN_KEY);

//INSTANCIA DO AXIOS PARA A API
const API_VERSION = "/v1";
const instancia = axios.create({ baseURL: process.env.REACT_APP_URL_API + API_VERSION, timeout: 20000 });

//FUCOES AUXILIARES
//Estas funcoes e variaveis auxiliam o axios a realizar tarefas especificas para o contexto deste sistema.

//Verifica strings em formato de datas
const formatoDeData = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[+-]\d{2}:\d{2})?$/;
function ehUmaData(value: any): boolean {
    return value && typeof value === "string" && formatoDeData.test(value);
}

//Lida com as datas vindas em formato de string da API e as transforma em data para o resto da aplição.
function handleDatas(dados: any) {
    if (dados === null || dados === undefined || typeof dados !== "object") return dados;

    for (const key of Object.keys(dados)) {
        const valor = dados[key];
        if (ehUmaData(valor)) dados[key] = new Date(valor);
        else if (typeof valor === "object") handleDatas(valor);
    }
}

//Salva os token vindos da api no localstorage
function handleTokens(body: tTokenResponse) {
    if (body.tokens !== undefined) {
        setAccessToken(body.tokens.access.token);
        setRefreshToken(body.tokens.refresh.token);
    }
}

//INTERCEPTORES
//Isto intercepta tanto requisições realizadas à API quanto respostas da API para modificar ou lidar com alguns parametros.

//Adicionando interceptor de requisições.
instancia.interceptors.request.use(
    function (config) {
        // Adicionando token de acesso
        config.headers.Authorization = `Bearer ${getAccessToken()}`;
        return config;
    },
    function (error) {
        //Não esquecer de rejeitar o error para o resto da aplicação.
        return Promise.reject(error);
    }
);

//Adicionando interceptor de respostas
instancia.interceptors.response.use(
    function (response) {
        // Convertendo string dates em object dates
        handleDatas(response.data);
        // Salvando tokens de acesso e de refresh
        handleTokens(response.data);
        return response;
    },
    function (error) {
        if (error?.response?.status === 401) {
            //Atualizar tokens de acesso se possivel ou redirecionar para login
            const fnRedirecionaParaLogin = () => {
                if (window.location.pathname !== "/login") window.location.href = window.location.origin + "/login";
            };

            const refreshToken = getRefreshToken();
            if (!refreshToken) fnRedirecionaParaLogin();
            else
                api.atualizarTokenAcesso(refreshToken)
                    .then(() => window.location.reload()) //Não esquecer de atualizar o front-end.
                    .catch((err) => {
                        cleanRefreshToken();
                        fnRedirecionaParaLogin();
                        console.error(err);
                    });
        }
        //Não esquecer de rejeitar o erro para o resto da aplicação.
        return Promise.reject(error);
    }
);

//ROTAS
const api = {
    //AUTH
    login: (credentials: tCredenciais) => instancia.post(`/auth/login`, credentials),
    logout: () => instancia.post("/auth/logout", { accessToken: getAccessToken(), refreshToken: getRefreshToken() }),

    mudarSenhaAtual: (password: string, signal?: AbortSignal) =>
        instancia.post(`/auth/reset-current-password`, { password }, { signal, params: { token: getAccessToken() } }),

    mudarSenhaPerdida: (body: { password: string; token: string }, signal?: AbortSignal) =>
        instancia.post(
            `/auth/reset-forgot-password`,
            { password: body.password },
            { signal, params: { token: body.token } }
        ),

    recuperarSenha: (body: { email: string; redirect: string }, signal?: AbortSignal) =>
        instancia.post(`/auth/forgot-password`, body, { signal, params: { token: getAccessToken() } }),

    atualizarTokenAcesso: (refreshToken: string, signal?: AbortSignal) =>
        instancia.post(`/auth/refresh-tokens`, { refreshToken }, { signal }),

    //INFO USUARIO
    getInfoUsuario: (signal?: AbortSignal): Promise<AxiosResponse<tInfoUser, AxiosError>> =>
        instancia.get(`/user-info`, { signal }),

    //CLIENTES
    importarClientes: (clientes: tImportarClienteDto[], signal?: AbortSignal): Promise<void> =>
        instancia.post(`/clientes/importar`, { data: clientes }, { signal }),

    insertClientes: (clientes: tNovoCliente[], signal?: AbortSignal): Promise<void> =>
        instancia.post(`/clientes`, { data: clientes }, { signal }),

    editarCliente: async ({ id, ...data }: tNovoCliente & { id: string }, signal?: AbortSignal): Promise<void> =>
        instancia.patch(`/clientes/${id}`, { data }, { signal }),

    deletarCliente: async (id: string, signal?: AbortSignal): Promise<void> =>
        instancia.delete(`/clientes/${id}`, { signal }),

    getCliente: async (id: string, signal?: AbortSignal): Promise<AxiosResponse<tCliente, AxiosError>> =>
        instancia.get(`/clientes/${id}`, { signal }),

    listaClientes: async (
        params?: tQueryParamsCliente,
        signal?: AbortSignal
    ): Promise<AxiosResponse<tCliente[], AxiosError>> => instancia.get(`/clientes`, { params, signal }),

    //FORNECEDORES
    insertFornecedores: async (fornecedores: tNovoFornecedor[], signal?: AbortSignal): Promise<void> =>
        instancia.post(`/fornecedores`, { data: fornecedores }, { signal }),

    editarFornecedor: async ({ id, ...data }: tNovoFornecedor & { id: string }, signal?: AbortSignal): Promise<void> =>
        instancia.patch(`/fornecedores/${id}`, { data }, { signal }),

    deletarFornecedor: async (id: string, signal?: AbortSignal): Promise<void> =>
        instancia.delete(`/fornecedores/${id}`, { signal }),

    getFornecedor: async (id: string, signal?: AbortSignal): Promise<AxiosResponse<tFornecedor, AxiosError>> =>
        instancia.get(`/fornecedores/${id}`, { signal }),

    listaFornecedores: async (
        params?: tQueryParamsFornecedor,
        signal?: AbortSignal
    ): Promise<AxiosResponse<tFornecedor[], AxiosError>> => instancia.get(`/fornecedores`, { params, signal }),

    //VENDEDORES
    insertVendedores: async (data: tNovoVendedor[], signal?: AbortSignal): Promise<void> =>
        instancia.post(`/vendedores`, { data }, { signal }),

    editarVendedor: async ({ id, ...data }: tNovoVendedor & { id: string }, signal?: AbortSignal): Promise<void> =>
        instancia.patch(`/vendedores/${id}`, { data }, { signal }),

    deletarVendedor: async (id: string, signal?: AbortSignal): Promise<void> =>
        instancia.delete(`/vendedores/${id}`, { signal }),

    getVendedor: async (id: string, signal?: AbortSignal): Promise<AxiosResponse<tVendedor, AxiosError>> =>
        instancia.get(`/vendedores/${id}`, { signal }),

    listaVendedores: async (
        params?: tQueryParamsVendedor,
        signal?: AbortSignal
    ): Promise<AxiosResponse<tVendedor[], AxiosError>> => instancia.get(`/vendedores`, { params, signal }),

    //PRODUTOS
    insertProdutos: async (produtos: tNovoProduto[], signal?: AbortSignal): Promise<void> =>
        instancia.post(`/produtos`, { data: produtos }, { signal }),

    editarProduto: async ({ id, ...data }: tNovoProduto & { id: string }, signal?: AbortSignal): Promise<void> =>
        instancia.patch(`/produtos/${id}`, { data }, { signal }),

    deletarProduto: async (id: string, signal?: AbortSignal): Promise<void> =>
        instancia.delete(`/produtos/${id}`, { signal }),

    getProduto: async (id: string, signal?: AbortSignal): Promise<AxiosResponse<tProduto, AxiosError>> =>
        instancia.get(`/produtos/${id}`, { signal }),

    listaProdutos: async (
        params?: tQueryParamsProduto,
        signal?: AbortSignal
    ): Promise<AxiosResponse<tProduto[], AxiosError>> => instancia.get(`/produtos`, { params, signal }),

    //SERVICOS
    insertServicos: async (servicos: tNovoServico[], signal?: AbortSignal): Promise<void> =>
        instancia.post(`/servicos`, { data: servicos }, { signal }),

    editarServico: async ({ id, ...data }: tNovoServico & { id: string }, signal?: AbortSignal): Promise<void> =>
        instancia.patch(`/servicos/${id}`, { data }, { signal }),

    deletarServico: async (id: string, signal?: AbortSignal): Promise<void> =>
        instancia.delete(`/servicos/${id}`, { signal }),

    getServico: async (id: string, signal?: AbortSignal): Promise<AxiosResponse<tServico, AxiosError>> =>
        instancia.get(`/servicos/${id}`, { signal }),

    listaServicos: async (
        params?: tQueryParamsServico,
        signal?: AbortSignal
    ): Promise<AxiosResponse<tServico[], AxiosError>> => instancia.get(`/servicos`, { params, signal }),

    //CATEGORIAS-PRODUTOS
    insertCategorias: async (categorias: tNovaCategoria[], signal?: AbortSignal): Promise<void> =>
        instancia.post(`/categorias`, { data: categorias }, { signal }),

    editarCategoria: async ({ id, ...data }: tNovaCategoria & { id: string }, signal?: AbortSignal): Promise<void> =>
        instancia.patch(`/categorias/${id}`, { data }, { signal }),

    deletarCategoria: async (id: string, signal?: AbortSignal): Promise<void> =>
        instancia.delete(`/categorias/${id}`, { signal }),

    getCategoria: async (id: string, signal?: AbortSignal): Promise<AxiosResponse<tCategoria, AxiosError>> =>
        instancia.get(`/categorias/${id}`, { signal }),

    listaCategorias: async (
        params?: tQueryParamsCategoria,
        signal?: AbortSignal
    ): Promise<AxiosResponse<tCategoria[], AxiosError>> => instancia.get(`/categorias`, { params, signal }),

    //CONTRATO
    insertContratos: async (contratos: tNovoContrato[], signal?: AbortSignal): Promise<void> =>
        instancia.post(`/contratos`, { data: contratos }, { signal }),

    editarContrato: async ({ id, ...data }: tNovoContrato & { id: string }, signal?: AbortSignal): Promise<void> =>
        instancia.patch(`/contratos/${id}`, { data }, { signal }),

    deletarContrato: async (id: string, signal?: AbortSignal): Promise<void> =>
        instancia.delete(`/contratos/${id}`, { signal }),

    getContrato: async (id: string, signal?: AbortSignal): Promise<AxiosResponse<tContrato, AxiosError>> =>
        instancia.get(`/contratos/${id}`, { signal }),

    listaContratos: async (
        params?: tQueryParamsContrato,
        signal?: AbortSignal
    ): Promise<AxiosResponse<tContrato[], AxiosError>> => instancia.get(`/contratos`, { params, signal }),

    //FORMA PAGAMENTO
    insertFormaPagamento: async (categorias: tNovaFormaPagamento[], signal?: AbortSignal): Promise<void> =>
        instancia.post(`/formas-pagamento`, { data: categorias }, { signal }),

    editarFormaPagamento: async (
        { id, ...data }: tNovaFormaPagamento & { id: string },
        signal?: AbortSignal
    ): Promise<void> => instancia.patch(`/formas-pagamento/${id}`, { data }, { signal }),

    deletarFormaPagamento: async (id: string, signal?: AbortSignal): Promise<void> =>
        instancia.delete(`/formas-pagamento/${id}`, { signal }),

    getFormaPagamento: async (id: string, signal?: AbortSignal): Promise<AxiosResponse<tFormaPagamento, AxiosError>> =>
        instancia.get(`/formas-pagamento/${id}`, { signal }),

    listaFormasPagamento: async (
        params?: tQueryParamsFormaPagamento,
        signal?: AbortSignal
    ): Promise<AxiosResponse<tFormaPagamento[], AxiosError>> => instancia.get(`/formas-pagamento`, { params, signal }),

    //SEGMENTOS-CLIENTES
    insertSegmentos: async (segmentos: tNovoSegmento[], signal?: AbortSignal): Promise<void> =>
        instancia.post(`/segmentos`, { data: segmentos }, { signal }),

    editarSegmento: async ({ id, ...data }: tNovoSegmento & { id: string }, signal?: AbortSignal): Promise<void> =>
        instancia.patch(`/segmentos/${id}`, { data }, { signal }),

    deletarSegmento: async (id: string, signal?: AbortSignal): Promise<void> =>
        instancia.delete(`/segmentos/${id}`, { signal }),

    getSegmento: async (id: string, signal?: AbortSignal): Promise<AxiosResponse<tSegmento, AxiosError>> =>
        instancia.get(`/segmentos/${id}`, { signal }),

    listaSegmentos: async (
        params?: tQueryParamsSegmento,
        signal?: AbortSignal
    ): Promise<AxiosResponse<tSegmento[], AxiosError>> => instancia.get(`/segmentos`, { params, signal }),

    //TIPOS-PRODUTOS-SERVICOS
    insertTipoProdutoServico: async (tiposProdServ: tNovoTipoProdutoServico[], signal?: AbortSignal): Promise<void> =>
        instancia.post(`/tipo-produto-servico`, { data: tiposProdServ }, { signal }),

    editarTipoProdutoServico: async (
        { id, ...data }: tNovoTipoProdutoServico & { id: string },
        signal?: AbortSignal
    ): Promise<void> => instancia.patch(`/tipo-produto-servico/${id}`, { data }, { signal }),

    deletarTipoProdutoServico: async (id: string, signal?: AbortSignal): Promise<void> =>
        instancia.delete(`/tipo-produto-servico/${id}`, { signal }),

    getTipoProdutoServico: async (
        id: string,
        signal?: AbortSignal
    ): Promise<AxiosResponse<tTipoProdutoServico, AxiosError>> =>
        instancia.get(`/tipo-produto-servico/${id}`, { signal }),

    lsitaTiposProdutoServico: async (
        params?: tQueryParamsTipoProdServ,
        signal?: AbortSignal
    ): Promise<AxiosResponse<tTipoProdutoServico[], AxiosError>> =>
        instancia.get(`/tipo-produto-servico`, { params, signal }),

    //FABRICANTES-PRODUTOS
    insertFabricantes: async (fabricantes: tNovoFabricante[], signal?: AbortSignal): Promise<void> =>
        instancia.post(`/fabricantes`, { data: fabricantes }, { signal }),

    editarFabricante: async ({ id, ...data }: tNovoFabricante & { id: string }, signal?: AbortSignal) =>
        instancia.patch(`/fabricantes/${id}`, { data }, { signal }),

    deletarFabricante: async (id: string, signal?: AbortSignal): Promise<void> =>
        instancia.delete(`/fabricantes/${id}`, { signal }),

    getFabricante: async (id: string, signal?: AbortSignal): Promise<AxiosResponse<tFabricante, AxiosError>> =>
        instancia.get(`/fabricantes/${id}`, { signal }),

    listaFabricantes: async (
        params?: tQueryParamsFabricante,
        signal?: AbortSignal
    ): Promise<AxiosResponse<tFabricante[], AxiosError>> => instancia.get(`/fabricantes`, { params, signal }),

    //DISTRIBUIÇÔES
    insertDistribuicaoComissao: async (
        distribuicoes: tNovaDistribuicaoComissao[],
        signal?: AbortSignal
    ): Promise<void> => instancia.post(`/distribuicoes-comissao`, { data: distribuicoes }, { signal }),

    editarDistribuicaoComissao: async (
        { id, ...data }: tNovaDistribuicaoComissao & { id: string },
        signal?: AbortSignal
    ): Promise<void> => instancia.patch(`/distribuicoes-comissao/${id}`, { data }, { signal }),

    deletarDistribuicaoComissao: async (id: string, signal?: AbortSignal): Promise<void> =>
        instancia.delete(`/distribuicoes-comissao/${id}`, { signal }),

    getDistribuicaoComissao: async (
        id: string,
        signal?: AbortSignal
    ): Promise<AxiosResponse<tDistribuicaoComissao, AxiosError>> =>
        instancia.get(`/distribuicoes-comissao/${id}`, { signal }),

    listaDistribuicoesComissao: async (
        params?: tQueryParamsDistribuicaoComissao,
        signal?: AbortSignal
    ): Promise<AxiosResponse<tDistribuicaoComissao[], AxiosError>> =>
        instancia.get(`/distribuicoes-comissao`, { params, signal }),

    //VENDAS
    insertVendas: async (
        vendas: tNovaVenda[],
        params?: { multiplasVendas?: boolean },
        signal?: AbortSignal
    ): Promise<void> => instancia.post(`/vendas`, { data: vendas }, { params, signal }),

    editarVenda: async ({ id, ...data }: tNovaVenda & { id: string }, signal?: AbortSignal): Promise<void> =>
        instancia.patch(`/vendas/${id}`, { data }, { signal }),

    deletarVenda: async (id: string, params?: { vendedorId?: string }, signal?: AbortSignal): Promise<void> =>
        instancia.delete(`/vendas/${id}`, { signal, params }),

    getVenda: async (id: string, signal?: AbortSignal): Promise<AxiosResponse<tVenda, AxiosError>> =>
        instancia.get(`/vendas/${id}`, { signal }),

    listaVendas: async (
        params?: tQueryParamsVenda,
        signal?: AbortSignal
    ): Promise<AxiosResponse<tVenda[], AxiosError>> => instancia.get(`/vendas`, { params, signal }),

    //USER
    insertUsers: async (users: tNovoUser[], signal?: AbortSignal): Promise<void> =>
        instancia.post(`/users`, { data: users }, { signal }),

    editarUser: async (
        { id, ...data }: tNovoUser & { id: string; bloqueado: boolean },
        signal?: AbortSignal
    ): Promise<void> => instancia.patch(`/users/${id}`, { data }, { signal }),

    deletarUser: async (id: string, signal?: AbortSignal): Promise<void> =>
        instancia.delete(`/users/${id}`, { signal }),

    getUser: async (id: string, signal?: AbortSignal): Promise<AxiosResponse<tUser, AxiosError>> =>
        instancia.get(`/users/${id}`, { signal }),

    listaUsers: async (params?: tQueryParamsUser, signal?: AbortSignal): Promise<AxiosResponse<tUser[], AxiosError>> =>
        instancia.get(`/users`, { params, signal }),

    //METAS
    insertMetas: async (metas: tNovaMeta[], signal?: AbortSignal): Promise<void> =>
        instancia.post(`/metas`, { data: metas }, { signal }),

    editarMeta: async ({ id, ...data }: tNovaMeta & { id: string }, signal?: AbortSignal): Promise<void> =>
        instancia.patch(`/metas/${id}`, { data }, { signal }),

    deletarMeta: async (id: string, signal?: AbortSignal): Promise<void> =>
        instancia.delete(`/metas/${id}`, { signal }),

    getMeta: async (id: string, signal?: AbortSignal): Promise<AxiosResponse<tMetaComValoresAtingidos, AxiosError>> =>
        instancia.get(`/metas/${id}`, { signal }),

    listaMetas: async (
        params?: tQueryParamsMeta,
        signal?: AbortSignal
    ): Promise<AxiosResponse<tMetaComValoresAtingidos[], AxiosError>> => instancia.get(`/metas`, { params, signal }),

    //COMISSOES
    getComissao: async (id: string, signal?: AbortSignal): Promise<AxiosResponse<tComissao, AxiosError>> =>
        instancia.get(`/comissoes/${id}`, { signal }),

    listaComissoes: async (
        params?: tQueryParamsComissao,
        signal?: AbortSignal
    ): Promise<AxiosResponse<tComissao[], AxiosError>> => instancia.get(`/comissoes`, { params, signal }),

    editarComissao: async (
        { id, ...data }: PartialEntity<tComissao, "id" | "comissaoPaga">,
        signal?: AbortSignal
    ): Promise<void> => instancia.patch(`/comissoes/${id}`, { data }, { signal }),

    editarVariasComissoes: async (comissoesId: string[], comissaoPaga: boolean, signal?: AbortSignal): Promise<void> =>
        instancia.patch("/comissoes", { data: { comissoesId, comissaoPaga } }, { signal }),

    buscarRelatoriosComissao: async (
        params?: tQueryParamsComissaoRelatorio,
        signal?: AbortSignal
    ): Promise<AxiosResponse<Blob, AxiosError>> =>
        instancia.get(`/relatorios/comissoes`, { params, signal, responseType: "arraybuffer" }),

    totalComissaoPagar: async (
        params: tQueryParamsTotalPagar,
        signal?: AbortSignal
    ): Promise<AxiosResponse<tVendedorComissaoTotal[], AxiosError>> =>
        instancia.get(`/comissoes/total-pagar`, { signal, params }),

    //AUDITORIA
    listaAuditorias: async (
        params?: tQueryParamsAuditoria,
        signal?: AbortSignal
    ): Promise<AxiosResponse<tAuditoria[], AxiosError>> => instancia.get(`/auditorias`, { params, signal }),

    //DASHBOARDS
    getDashValoresTotais: async (
        params: tQueryParamsDashboardsWithMetrica,
        signal?: AbortSignal
    ): Promise<AxiosResponse<tDashValoresTotais, AxiosError>> =>
        instancia.get(`/dashboards/valores-totais`, { params, signal }),

    getDashEvoluces: async (
        params: tQueryParamsDashboardsWithMetrica,
        signal?: AbortSignal
    ): Promise<AxiosResponse<tDashEvolucoes, AxiosError>> => instancia.get(`/dashboards/evolucoes`, { params, signal }),

    getDashMetaAtingida: async (
        params: tQueryParamsDashboardsWithMetrica,
        signal?: AbortSignal
    ): Promise<AxiosResponse<tDashMetaAtingida, AxiosError>> =>
        instancia.get(`/dashboards/meta-atingida`, { params, signal }),

    getDashMargemContribuicao: async (
        params: tQueryParamsDashboards,
        signal?: AbortSignal
    ): Promise<AxiosResponse<tDashtMargemContribuicao, AxiosError>> =>
        instancia.get(`/dashboards/margem-contribuicao`, { params, signal }),

    getDashRankClientes: async (
        params: tQueryParamsDashboardsWithMetrica,
        signal?: AbortSignal
    ): Promise<AxiosResponse<tDashRankClientePorVenda, AxiosError>> =>
        instancia.get(`/dashboards/rank-clientes`, { params, signal }),

    getDashRankVendedores: async (
        params: tQueryParamsDashboardsWithMetrica,
        signal?: AbortSignal
    ): Promise<AxiosResponse<tDashRankVendedorPorFaturamento[], AxiosError>> =>
        instancia.get(`/dashboards/rank-vendedores`, { params, signal }),

    getDashRankCategorias: async (
        params: tQueryParamsDashboardsWithMetrica,
        signal?: AbortSignal
    ): Promise<AxiosResponse<tDashRankCategoriaPorVenda, AxiosError>> =>
        instancia.get(`/dashboards/rank-categorias`, { params, signal }),

    getDashEvolucaoFaturamento: async (
        params: tQueryParamsDashboardsWithMetrica,
        signal?: AbortSignal
    ): Promise<AxiosResponse<tDashEvolucaoFaturamento, AxiosError>> =>
        instancia.get(`/dashboards/evolucao-faturamento`, { params, signal }),

    getDashEvolucaoTicketMedio: async (
        params: tQueryParamsDashboardsWithMetrica,
        signal?: AbortSignal
    ): Promise<AxiosResponse<tDashEvolucaoTicketMedio, AxiosError>> =>
        instancia.get(`/dashboards/evolucao-ticket-medio`, { params, signal }),

    getDashVendasPorTipoProdServ: async (
        params: tQueryParamsDashboardsWithMetrica,
        signal?: AbortSignal
    ): Promise<AxiosResponse<tDashVendaPorTiposProdServ, AxiosError>> =>
        instancia.get(`/dashboards/tipos-produto-servico`, { params, signal }),

    //VISITAS
    insertVisitas: async (visitas: tNovaVisita[], signal?: AbortSignal): Promise<void> =>
        instancia.post(`/visitas`, { data: visitas }, { signal }),

    editarVisita: async ({ id, ...data }: tNovaVisita & { id: string }, signal?: AbortSignal): Promise<void> =>
        instancia.patch(`/visitas/${id}`, { data }, { signal }),

    editarVariasVisitas: async (visitasId: string[], visitaPaga: boolean, signal?: AbortSignal): Promise<void> =>
        instancia.patch("/visitas", { data: { visitasId, visitaPaga } }, { signal }),

    deletarVisita: async (id: string, params?: { vendedorId?: string }, signal?: AbortSignal): Promise<void> =>
        instancia.delete(`/visitas/${id}`, { signal, params }),

    getVisita: async (id: string, signal?: AbortSignal): Promise<AxiosResponse<tVisita, AxiosError>> =>
        instancia.get(`/visitas/${id}`, { signal }),

    listaVisitas: async (
        params?: tQueryParamsVisita,
        signal?: AbortSignal
    ): Promise<AxiosResponse<tVisita[], AxiosError>> => instancia.get(`/visitas`, { params, signal }),

    buscarRelatoriosVisita: async (
        params?: tQueryParamsVisitaRelatorio,
        signal?: AbortSignal
    ): Promise<AxiosResponse<Blob, AxiosError>> =>
        instancia.get(`/relatorios/visitas`, { params, signal, responseType: "arraybuffer" }),

    totalVisitaPagar: async (
        params: tQueryParamsTotalPagar,
        signal?: AbortSignal
    ): Promise<AxiosResponse<tVendedorGastoTotal[], AxiosError>> =>
        instancia.get(`/visitas/total-pagar`, { signal, params }),

    //API VERSION
    getApiVersion: async (): Promise<AxiosResponse<string, AxiosError>> => instancia.get("/version"),
};

export default api;
