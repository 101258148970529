import { Form, Row } from "react-bootstrap";
import { Formik, FormikHelpers } from "formik";
import { useCallback, useContext } from "react";
import * as yup from "yup";
import utils from "../../utils";
import { ContextAlerta } from "../../contexts/ContextAlert";
import { PartialEntity, tGasto, TipoGasto } from "../../interfaces";
import FormGroupInput, { FormGroupInputType } from "../formGroups/FormGroupInput";
import FormGroupSelect from "../formGroups/FormGroupSelect";

//TYPES
export type tNovoGasto = PartialEntity<tGasto, "nota" | "tipoGasto" | "valor">;

export interface FormVisitaGastoProps {
    formId?: string;
    onSubmit: (gasto: tNovoGasto) => void | Promise<void>;
    valoresIniciais: tNovoGasto;
}

//FORM
export default function FormVisitaGasto({ formId, onSubmit, valoresIniciais }: FormVisitaGastoProps) {
    //CONTEXTOS
    const { setPerigo } = useContext(ContextAlerta);

    //EVENTOS
    const onSubmitFormik = useCallback(
        async (gasto: tNovoGasto, helpers: FormikHelpers<tNovoGasto>) => {
            try {
                await onSubmit(gasto);
                helpers.setSubmitting(false);
                helpers.resetForm();
            } catch (err) {
                if (utils.blErroCancelamento(err)) return;
                setPerigo(utils.retornaMensagemErro(err));
                console.error(err);
            }
        },
        [onSubmit, setPerigo]
    );

    return (
        <Formik
            validateOnChange={false}
            validationSchema={yupEsquemaVisitaGasto}
            onSubmit={onSubmitFormik}
            initialValues={valoresIniciais}
        >
            {({ handleSubmit, values, errors, setValues }) => {
                return (
                    <Form id={formId} onSubmit={handleSubmit}>
                        <Row className="gap-1">
                            <FormGroupSelect
                                label="Tipo Gasto"
                                value={values.tipoGasto}
                                setValue={(tipoGasto) => setValues({ ...values, tipoGasto })}
                                options={utils.returnTiposGasto()}
                                optionTextFn={utils.returnTextTipoGasto}
                                error={errors.tipoGasto}
                            />

                            <FormGroupInput
                                label="Nota fiscal"
                                required
                                type={FormGroupInputType.TEXT}
                                value={values.nota}
                                setValue={(nota) => setValues({ ...values, nota })}
                                error={errors.nota}
                            />

                            <FormGroupInput
                                label="Valor do gasto"
                                required
                                type={FormGroupInputType.MONETARY}
                                value={values.valor}
                                tipoMoeda="BRL"
                                setValue={(valor) => setValues({ ...values, valor })}
                                error={errors.valor}
                            />
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
}

export const yupEsquemaVisitaGasto: yup.ObjectSchema<tNovoGasto> = yup.object({
    tipoGasto: yup.mixed<TipoGasto>().oneOf(utils.returnTiposGasto()).required("Este campo é necessário."),
    nota: yup.string().required("Este campo é necessário."),
    valor: yup.number().required("Esta campo é necessário.").notOneOf([0], "O valor não pode ser zero."),
});
